import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Container, Grid, TextField } from "@mui/material";
import crossIcon from "../../../assets/images/crossIcon.svg";
import { Button } from "../../Button";
import { AlertMessage, Spinner } from "../../../components";
import Dropdown from "../Dropdown";
import { getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { ORGANIZATION_ROLES, US_STATES } from "../../../constants";
import { validateName, validatePhoneNumber, validateRequiredField, validateZipcode } from "../Helper";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";
import { RequiredSymbol } from "../Helper";

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    display:"flex",
    justifyContent:'left',
    marginY: "24px",
}

const helperTextStyles = {
    sx: {
      '&.Mui-error': {
        color: '#E22929',
        fontFamily: 'Aeonik Regular',
        marginLeft: '0',
        fontSize: '14px',
        letterSpacing: '-0.28px'
      },
    },
}

const FormLabel = ({ label, isRequired }) => {
    return (
            <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
                {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
            </Typography>
    )
}

export const OnboardingContactInformation = ({ tabIndex, onTabChange = () => {}, customerSegmentClasses }) => {
    const defaultInputFields = {
        firstName: '',
        lastName: '',
        orgLegalName: '',
        dbaName: '',
        role: '',
        attention: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
        zip4: '',
        customerSegment: '',
    };

    const defaultPhoneNumber = {
        phoneNumber: '',
        extension: '',
        type: 'work',
    };

    const textFieldStyles = (name) => {
        return {
            "& input": {
                fontFamily: 'Aeonik Regular',
                padding: "12px",
                height: '24px',
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
                '&.Mui-focused fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
            },
        }
    }

    const [inputFields, setInputFields] = useState(defaultInputFields);
    const [errors, setErrors] = useState({ phoneNumbers: [{ phoneNumber: '', extension: '', type: '' }] });
    const [phoneNumbers, setPhoneNumbers] = useState([defaultPhoneNumber]);
    const dispatch = useDispatch();
    const requiredFields = ['firstName', 'lastName', 'dbaName', 'role', 'line1', 'city', 'state', 'zipcode'];
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';
    const isTaxPhase3Enabled = process.env.IS_TAX_PHASE_3_ENABLED === 'true';

    useEffect(() => {
        const checkIsFormValid = () => {
            const requiredFieldsFilled = requiredFields.every((field) => inputFields[field].trim().length !== 0);
    
            const atLeastOnePhoneNumber = phoneNumbers.some(phone => phone.phoneNumber.trim().length > 0);
            const phoneNumbersValid = phoneNumbers.every((phone, index) => {
                const phoneErrors = errors.phoneNumbers?.[index] || {};
                return !phone.phoneNumber.trim() || phoneErrors.phoneNumber === "";
            });
    
            const nonPhoneNumberErrors = { ...errors };
            delete nonPhoneNumberErrors.phoneNumbers;
            const noErrors = Object.values(nonPhoneNumberErrors).every(errorMsg => errorMsg === "");
    
            const noPhoneNumberErrors = errors?.phoneNumbers?.every(phoneError => {
                return Object?.values(phoneError)?.every(errorMsg => errorMsg === "");
            });
    
            return requiredFieldsFilled && atLeastOnePhoneNumber && phoneNumbersValid && noErrors && noPhoneNumberErrors;
        };
        checkIsFormValid();
    
    }, [inputFields, phoneNumbers, errors]);

    const orgRoles = Object.entries(ORGANIZATION_ROLES).map(([key, value]) => {
        return({
            "value": value,
            "label": key,
        });
    });

    const USStates = US_STATES.map((state) => {
        return({
            "value": state,
            "label": state,
        })
    });

    const updateUserRequestBody = {
        userId: userId,
        updateType: "organization",
        firstName: inputFields?.firstName,
        lastName: inputFields?.lastName,
        phones: phoneNumbers,
        organization: {
            orgType: "contact_info",
            legalName: inputFields?.orgLegalName,
            dbaName: inputFields?.dbaName,
            role: inputFields?.role,
            address: 
                {
                    addressType: "sold_to",
                    attention: inputFields?.attention,
                    contactPhone: phoneNumbers.length > 0 ? phoneNumbers[0] : '',
                    line1: inputFields?.line1,
                    line2: inputFields?.line2,
                    city: inputFields?.city,
                    state: inputFields?.state,
                    zip: inputFields?.zipcode,
                    zipExtension: inputFields?.zip4
                }
            ,
            customerSegment: inputFields?.customerSegment,
        },
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};
        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateInputFields(fieldName, inputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        phoneNumbers.forEach((phone, index) => {
            const phoneErrorMsg = validateInputFields('phoneNumber', phone.phoneNumber);
            if (phoneErrorMsg) {
                if (!validationErrors.phoneNumbers) {
                    validationErrors.phoneNumbers = [];
                }
                validationErrors.phoneNumbers[index] = { ...validationErrors.phoneNumbers[index], phoneNumber: phoneErrorMsg };
            }
        });

        if (Object.keys(validationErrors).length > 0 || (validationErrors.phoneNumbers && validationErrors.phoneNumbers.length > 0)) {
            setErrors(validationErrors);
            return;
        }

        try {
            setIsLoading(true);
            const response = await updateUserDetailsApi(updateUserRequestBody);
            if (response?.status === 200) {
                dispatch(setUserStatus(response?.data?.userStatus));
                setInputFields(defaultInputFields);
                setPhoneNumbers([defaultPhoneNumber]);
                onTabChange(tabIndex + 1);
                setIsLoading(false);
                dispatch(getUserByEmail({ emailAddress: response?.data?.email }))
                .unwrap()
                .then((res) => {
                    if (res) {
                        localStorage.setItem("currentUser", JSON.stringify(res));
                    }
                });
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message;
            setErrorMsg(errMsg || generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRole = (event) => {
        const { value } = event.target;
        const updatedInputs = { ...inputFields, role: value };
        setInputFields(updatedInputs);

        const newErrors = { ...errors, role: "" }
        setErrors(newErrors);
    };
    
    const handleStateSelection = (event) => {
        const { value } = event.target;
        const updatedInputs = { ...inputFields, state: value };
        setInputFields(updatedInputs);

        const newErrors = { ...errors, state: "" }
        setErrors(newErrors);
    };

    const handlePhoneChange = (index, value, name) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index][name] = value;
        setPhoneNumbers(newPhoneNumbers);

        const errorMsg = validateInputFields(name, value);
        const newPhoneErrors = [...errors.phoneNumbers];
        newPhoneErrors[index] = { ...newPhoneErrors[index], [name]: errorMsg };
        setErrors({ ...errors, phoneNumbers: newPhoneErrors });
    };

    const handleAddPhoneNumber = () => {
        setPhoneNumbers([...phoneNumbers, {...defaultPhoneNumber}]);
        setErrors({ ...errors, phoneNumbers: [...errors.phoneNumbers, { phoneNumber: '', extension: '', type: '' }] });
    };

    const handleRemovePhoneNumber = (index) => {
        const updatedPhoneNumbers = [...phoneNumbers.slice(0, index), ...phoneNumbers.slice(index + 1)];
        const updatedPhoneErrors = [...errors.phoneNumbers.slice(0, index), ...errors.phoneNumbers.slice(index + 1)];
        setPhoneNumbers(updatedPhoneNumbers);
        setErrors({ ...errors, phoneNumbers: updatedPhoneErrors });
    };

    const handleCustomerSegment = (event) => {
        const { value } = event.target;
        const updatedInputs = { ...inputFields, customerSegment: value };
        setInputFields(updatedInputs);

        const newErrors = { ...errors, customerSegment: "" }
        setErrors(newErrors);
    };

    const handleBlur = (event, index) => {
        const { name, value } = event.target;
        const errorMsg = validateInputFields(name, value);
        const newErrors = { ...errors };
  
        if (index !== undefined) {
            newErrors.phoneNumbers[index] = { ...newErrors.phoneNumbers[index], [name]: errorMsg };
        } else {
            newErrors[name] = errorMsg;
        }
        
        setErrors(newErrors);
    };
    
    const fieldValidations = {
        firstName: validateName,
        lastName: validateName,
        dbaName: validateRequiredField,
        role: validateRequiredField,
        line1: validateRequiredField,
        city: validateRequiredField,
        state: validateRequiredField,
        zipcode: validateZipcode,
        customerSegment: validateRequiredField,
    };
    
    const validateInputFields = (field, value, phoneNumbers = []) => {
        if (field === 'phoneNumber') {
            return validatePhoneNumber(value, phoneNumbers);
        }
        if (field === 'customerSegment' && !isTaxPhase3Enabled) {
            return "";
        }

        const validationFunction = fieldValidations[field];
        return validationFunction ? validationFunction(value) : "";
    };

    const createTextField = (key, label, placeholder, value, onChange, onBlur, isRequired, error, index=null) => {
        const errorKey = index !== null ? `${key}${index}` : key;

        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={errorKey}
                    name={errorKey}
                    data-testid={errorKey}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    error={Boolean(error) || Boolean(errors[errorKey])}
                    helperText={error || errors[errorKey]}
                    sx={textFieldStyles(errorKey)}
                    FormHelperTextProps={helperTextStyles}
                />
            </>
        )
    };

    const PhoneNumberForm = (index) => {
        const phoneNumber = phoneNumbers[index];
        const showTitles = index === 0;
        
        // If more than 1 phone number, shrink to fit container
        const phoneNumberGridSize = phoneNumbers.length > 1 ? 5 : 6;
        return (
            <Grid key={index} container columnSpacing={2} justifyContent="start" alignItems="flex-start">
                <Grid item xs={12} sm={phoneNumberGridSize}>
                    {createTextField("phoneNumber", showTitles ? "Your phone number" : "", "123-456-7890", phoneNumber.phoneNumber, (e) => handlePhoneChange(index, e.target.value, 'phoneNumber'),
                    handleBlur, showTitles, errors.phoneNumbers?.[index]?.phoneNumber, index)}
                </Grid>
                <Grid item xs={12} sm={phoneNumberGridSize}>
                    {createTextField("extension", showTitles ? "Ext" : "", "123", phoneNumber.extension, (e) => handlePhoneChange(index, e.target.value, 'extension'), handleBlur, false)}
                </Grid>
                {phoneNumbers.length > 1 && index !== 0 && (
                    <Grid item xs={12} sm={1} paddingTop='15px' sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img width='30px' alt="crossIcon" src={crossIcon} onClick={() => handleRemovePhoneNumber(index)} data-testid='crossicon' style={{ cursor: 'pointer' }} />
                    </Grid>
                )}
            </Grid>
        );
    };
    
    const ContactInfoForm = (
        <>
        <Spinner processing={isLoading} />
        <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
        <form onSubmit={handleSubmit}>
            <Container key='onboardingContactInfo' data-testid='onboardingContactInfo'>
                <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2}>
                    <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Contact Information</Typography>
                    <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A"><RequiredSymbol>*</RequiredSymbol> Required field</Typography>
                </Box>
                <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" style={{ marginBottom: '8px' }}>
                    <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                        {createTextField("firstName", "Your first name", "Jonathan", inputFields.firstName, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                        {createTextField("lastName", "Your last name", "Doe", inputFields.lastName,  handleChange, handleBlur, true)}
                    </Grid>
                </Grid>

                {phoneNumbers.map((phoneNumber, index) => PhoneNumberForm(index))}
                <Button
                    id="addPhoneNumberLink"
                    sx={{ textTransform: "none" }}
                    buttonType="link"
                    data-testid='addPhoneNumberBtn'
                    onClick={handleAddPhoneNumber}
                >
                    Add another phone number
                </Button>

                <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'}>
                    <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Your organization</Typography>
                </Box>
                <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="center">
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("orgLegalName", "Official organization name (legal)", "e.g., Townsville Health Partners, LLC, Pacifico Companies Inc., etc", 
                            inputFields.orgLegalName, handleChange, handleBlur, false)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("dbaName", `“Doing business as” name`, "e.g., Townsville Pharmacies, Pacific Health Partners, etc", 
                            inputFields.dbaName, handleChange, handleBlur, true)}
                    </Grid>
                    {isTaxPhase3Enabled && <Grid item xs={12} paddingBottom={'1px'} gap={'24px'} data-testid='customerSegment'>
                        <Dropdown
                            dataTestId={"customerSegmentDropdown"}
                            name={"customerSegment"}
                            label={"Organization type"}
                            options={customerSegmentClasses}
                            value={inputFields.customerSegment}
                            onChangeHandler={handleCustomerSegment}
                            isRequired={true}
                            errors={errors}
                        />
                    </Grid>}
                    <Grid item xs={12} paddingBottom={'1px'} gap={'24px'} data-testid='role'>
                        <Dropdown
                            dataTestId={"roleDropdown"}
                            name={"role"}
                            label={"Role"}
                            options={orgRoles}
                            value={inputFields.role}
                            onChangeHandler={handleRole}
                            isRequired={true}
                            errors={errors}
                        />
                    </Grid>
                </Grid>

                <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2}>
                    <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Your organization's legal address</Typography>
                    <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">
                        Enter the address your organization uses for legal and contract documentation. This address can be used as your “Sold to” address and is financially responsible for orders. 
                    </Typography>
                </Box>

                {/* TO-DO: Move this into own address form component */}
                <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" style={{ marginBottom: '8px' }}>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("attention", "Attention", "e.g., Attn Billing Dept", inputFields.attention, handleChange, handleBlur, false)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("line1", "Address Line 1", "e.g., 123 Main St", inputFields.line1, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} paddingBottom={'1px'}>
                        {createTextField("line2", "Address Line 2", "e.g., Dock 5, Ste 301", inputFields.line2, handleChange, handleBlur, false)}
                    </Grid>
                    <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                        {createTextField("city", "City", "City", inputFields.city, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} sm={4} paddingBottom={'1px'} data-testid='state'>
                        <Dropdown
                            dataTestId={"stateDropdownSelection"}
                            name={"state"}
                            label={"State"}
                            options={USStates}
                            value={inputFields.state}
                            onChangeHandler={handleStateSelection}
                            isRequired={true}
                            errors={errors}
                        />
                     </Grid>
                    <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                        {createTextField("zipcode", "ZIP Code", "12345", inputFields.zipcode, handleChange, handleBlur, true)}
                    </Grid>
                    <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                        {createTextField("zip4", "ZIP +4", "0123", inputFields.zip4, handleChange, handleBlur, false)}
                    </Grid>
                </Grid>

                <Grid sx={buttonsContainerSxStyles}>
                    <Button 
                        type="submit"
                        id="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-primary"
                        data-testid='submitBtn'
                    >
                        Continue
                    </Button>
                </Grid>
            </Container>
        </form>
        </>
    )

    return (
        <Box maxWidth={'700px'}>{ContactInfoForm}</Box>
    )
}