import React, {useEffect, useRef, useState} from 'react';
import { styled, Typography} from "@mui/material";
import { AlertMessage, Spinner , HistoricOrderHistoryList, OrderHistoryList, ReservationHistoryList} from '../components';
import {useDispatch, useSelector} from "react-redux";
import { loadOrderHistoryBannerComponent} from "../features/contentful/contentfulThunkApi";
import { useUserInfoContext } from '../contexts';

const BannerContainer = styled('div')(
    ({ aboveFoldBGImage, theme: { palette: { primary }, breakpoints }}) => `
    background-color: ${primary.main};
    background-image: url(${aboveFoldBGImage});
    background-size: cover;
    height: 200px;
    ${[breakpoints.down('sm')]} {
        background-image: unset;
    }
    `
);

const OrderHistoryPage = () => {
  const dispatch = useDispatch();
  const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
  const isEmployee = () => localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined";
  const canOrder2023Products = useSelector((state) => state.globalStates?.canOrder2023Products);
  const userData = useSelector((store) => store?.commerce?.userData);
  const { userInfo } = useUserInfoContext();
  const oktaID = canOrder2023Products ? userInfo?.uid : userData?.authenticatedId;
  const userId = userData?.userId;
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);
  const { orderHistoryBannerComponent } = useSelector((store) => store?.contentful);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (orderHistoryBannerComponent && Object.keys(orderHistoryBannerComponent).length === 0)
            dispatch(loadOrderHistoryBannerComponent());
    }, [orderHistoryBannerComponent, dispatch]);


   const errorMsgHandler = (errorMsg) => {
      let errMsg = errorMsg ? errorMsg : generalApiError;
      setErrorMsg(errMsg);
      alertRef.current?.openAlert(errorMsg);
   }
   
  return (
    <>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
      <Spinner processing={processing} />
      <BannerContainer aboveFoldBGImage={orderHistoryBannerComponent?.backgroundImage?.fields?.file?.url}>
        <Typography variant={'h1'} color={'neutral.white'} py={'40px'} px={{xs:'32px', lg:'100px'}}>{orderHistoryBannerComponent?.headline}</Typography>
      </BannerContainer>
      {!isEmployee() && isNewOrderingEnabled  && <OrderHistoryList userId={userId} handleErrorMsg={errorMsgHandler} setLoading={setProcessing}/> }
      { !isEmployee() && <ReservationHistoryList userId={oktaID} handleErrorMsg={errorMsgHandler}/> }
      { canOrder2023Products && <HistoricOrderHistoryList userId={oktaID} handleErrorMsg={errorMsgHandler} setLoading={setProcessing}/> }
    </>
  );
};

export default OrderHistoryPage;
