import { useSelector } from 'react-redux'
import { useOktaAuth } from '@okta/okta-react'
import { Box, Typography } from '@mui/material'
import { Button } from '../../components'
import { extractState } from '../../utils/Helper'
import MapIcon from '../../assets/images/mapIcon.svg'
import { useModal } from '../../hooks/useModal'

const AddressBlock = ({shipToAddress, setOpenAddressModal, deleteAddressRow, handleAddNewAddress}) => {
  const isOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
  const { authState } = useOktaAuth() || '';
  const isAuthenticated = authState?.isAuthenticated;
  const locale = useSelector((state) => state.globalStates.locale);
  const { handleOpenModal } = useModal();

  const openLoginModal = (redirectPath) => {
    handleOpenModal(redirectPath);
  }

  const onAddAddress = () => {
    if (!isAuthenticated) {
      openLoginModal(`/${locale}/reservation`)
    }
    else {
      handleAddNewAddress();
    }
  }

  return (
    <Box 
      display="flex" 
      flexDirection="column"
      gap="10px"
      paddingTop="25px"
      alignItems="flex-start"
    >
      {(shipToAddress && isAuthenticated) ?
          <>
            <Box display="flex" gap="10px">
              <img src={MapIcon} alt="map icon" width="24" height="22" />
              <div>
                <Typography fontWeight="700" fontFamily="Aeonik Regular">{isOnboardingEnabled? shipToAddress?.dba : shipToAddress?.companyName}</Typography>
                <Typography fontWeight="400" fontFamily="Aeonik Regular">{shipToAddress?.line1}</Typography>
                <Typography fontWeight="400" fontFamily="Aeonik Regular">{shipToAddress?.line2}</Typography>
                <Typography fontWeight="400" fontFamily="Aeonik Regular">
                  {isOnboardingEnabled? `${shipToAddress?.city}, ${shipToAddress?.state}, ${shipToAddress?.zip}` : `${shipToAddress?.town}, ${extractState(shipToAddress?.region)}, ${shipToAddress?.postalCode}`}
                </Typography>
              </div>
            </Box>
            <Button data-testid="chooseAddressBttn" buttonType='link' sx={{textTransform: "none", padding: "0 34px"}} onClick={() => {setOpenAddressModal(true)}}>Change</Button>
            <Button data-testid="deleteAddresRow" buttonType='link' sx={{textTransform: "none", padding: "0 34px", color:"#E31737"}} onClick={deleteAddressRow}>Delete this row</Button>
          </> :
          <Button
              buttonType={'mds-secondary'}
              leftIcon={MapIcon}
              sx={{ padding: "16px 32px", textTransform: "none", }}
              onClick={onAddAddress}
              data-testid="addAddressBttn"
          >
            Add Shipping Address
          </Button>
      }
    </Box>
  )
}

export default AddressBlock
