import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import warningInfoIcon from '../../assets/images/warningInfoIcon.svg';
import infoIcon from '../../assets/images/infoIcon2.svg';
import syncIcon from '../../assets/images/Sync.svg';
import cancelIcon from '../../assets/images/crossIconRed.svg';
import  shippedIcon from "../../assets/images/shipmentIcon.svg"

const MessageBanner = ({ type, headerText, messageText, style, dataTestId, bannerContentComponent }) => {

    const getMessageContentStyles = (type) => {
        const getBorderLeftStyle=(type)=>{
            switch(type){
                case "info":
                    return '#079AE0'
                case "warning":
                    return '#FDB022'
                case "sync":
                    return '#FDB022'
                case "canceled":
                    return '#E22929'
                case "shipped":
                    return '#079AE0'
                default:
                    return '#079AE0'             
            }
        }

        const getBackground=(type)=>{
            switch(type){
                case "info":
                    return '#F2FAFD'
                case "warning":
                    return '#FFF9EC'
                case "sync":
                    return '#FFF9EC'
                case "canceled":
                    return '#FFF3F3'
                case "shipped":
                    return '#F2FAFD'
                default:
                    return '#F2FAFD'             
            }
        }

        return {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            gap: '18px',
            padding: '12px 16px',
            borderRadius: '0px 8px 8px 0px',
            borderLeft: `2px solid ${getBorderLeftStyle(type)}`,
            background: getBackground(type),
        }
    }
    
    const defaultStyles = getMessageContentStyles(type);
    const combinedStyles = { ...defaultStyles, ...style };

    const iconTypes = {
        info: infoIcon,
        warning: warningInfoIcon,
        sync: syncIcon,
        canceled: cancelIcon,
        shipped: shippedIcon
    };
    
    const getIconType = (type) => iconTypes[type];

    return (
        <Box sx={combinedStyles}>
            <img src={getIconType(type)} alt={`${type} icon`} width="32" height="32" />
            <Box data-testid={dataTestId} display="flex" flexDirection="column" alignItems="flex-start" gap="10px" flex="1 0 0">
                {headerText && 
                    <Typography data-testid="bannerHeader" fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">
                        {headerText}
                    </Typography>
                }
                {messageText && 
                    <Typography data-testid="bannerMessage" fontFamily="Aeonik Regular" textAlign="left">
                        {messageText}
                    </Typography>
                }
                {bannerContentComponent} 
            </Box>
        </Box>
    )
}

MessageBanner.propTypes = {
    type: PropTypes.oneOf(['info', 'warning', 'sync','canceled','shipped']).isRequired,
    headerText: PropTypes.string,
    iconSrc: PropTypes.string,
    messageText: PropTypes.string,
    style: PropTypes.object,
    bannerContentComponent: PropTypes.node
};

export default MessageBanner