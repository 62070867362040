import { Box, Typography } from "@mui/material";
import React from "react";

const overlay = {
  margin:"10px auto",
  position: 'absolute',
  width:{xs:'124px', md: '154px', lg:'194px'},
  height:{xs:'124px',md: '154px', lg:'194px'},
  borderRadius: '50%',
  opacity: 0,
  transition: 'opacity .5s'
}

const onRoundCardHoverStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  opacity: '1',
}

export const OrderProductTile = ({index, ageGroup, description, iconImage, hoverImage, productData}) => {
  return (
    <Box data-testid="HeadingTestProductTile" width={{xs:'170px', lg:'209px'}} height={{xs:'170px', lg:'209px'}} borderRadius={'50%'} border={'8px solid'} borderColor={'primary.blue'} sx={{'&:hover': {boxShadow: "0px 3px 6px #00000029"}}} {...productData}>
      <Box width={{xs:'144px', md: '154px', lg:'194px' }} height={{xs:'144px', md: '154px', lg:'194px'}} borderRadius={'50%'} display="grid" justifyContent={'center'} alignItems={'center'}
        sx={{
          '&:hover .overlay': { 'backgroundImage':`url(${hoverImage})`, ...onRoundCardHoverStyle, zIndex: '99999'}, //image on opacity BG
          '&:hover .colorOpacity': { 'backgroundColor':'primary.blue', ...onRoundCardHoverStyle, opacity: '0.1', zIndex: '999'}, //to show opacity background color
          '&:hover .colorSolid': { 'backgroundColor':'neutral.white', ...onRoundCardHoverStyle, zIndex: '0'}  // to hide the text on hever
        }}
        id={`roundCard-${index}`}
        >
        <Box data-testid="BoxTestTile" display="grid" justifyContent={'center'} color={'primary.main'}>
          <Box component={'img'}
            sx={{ margin:"10px auto"}}
            src={iconImage}
            alt={'product' + index}
            height={{xs: 70, md: 50, lg: 70}}
            width={{xs: 45, md: 35, lg: 45}} />
          <Typography variant={'h4'} textAlign={'center'}>{ageGroup}</Typography>
          <Typography variant={'p2'} textAlign={'center'} px={'20px'}>{description}</Typography>
        </Box>
        <Box className="overlay" display={{xs:"none",sm:"grid"}} justifyContent={'center'} sx={{...overlay, backgroundSize: '150px'}}/>
        <Box className="colorOpacity" display={{xs:"none",sm:"grid"}} justifyContent={'center'} sx={{...overlay}}/>
        <Box className="colorSolid" display={{xs:"none",sm:"grid"}} justifyContent={'center'} sx={{...overlay}}/>
      </Box>   
    </Box>
  );
};
