import commerceEmpAxiosInstance from "../sevices/commerceEmpAxiosInstance";

export const searchCustomerApi = async (values) => {
  try {         
    const res = await commerceEmpAxiosInstance({
      url: `/getCustomer?email=${values.email}&hin=${values.hin}&dea=${values.dea}`,
      method: "GET",
      params: {
        empEmail: values.employeeEmail
      }
    });
    return res;
  } catch (error) { 
    throw error;
  }
};

export const fetchEmpUserApi = async (email) => {
  try {         
    const res = await commerceEmpAxiosInstance({
      url: `/modernaEmployee/${email}/?fields=DEFAULT`,
      method: "GET",
      params: {
        empEmail: email
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};