import copy from 'fast-copy';
import { toPlainObject, freezeSys } from 'contentful-sdk-core';

/**
 * @memberof Entities
 * @typedef Tag
 * @prop {Entities.Sys} sys - Standard system metadata with additional entry specific properties
 * @prop {string} name - Tag name
 * @prop {function(): Object} toPlainObject() - Returns this tag as a plain JS object
 */

/**
 * @private
 * @param {Object} data - Raw tag data
 * @return {Tag} Wrapped tag data
 */
export function wrapTag(data) {
  return freezeSys(toPlainObject(copy(data)));
}

/**
 * @memberof Entities
 * @typedef TagCollection
 * @prop {number} total
 * @prop {number} skip
 * @prop {number} limit
 * @prop {Array<Entities.Tag>} items
 * @prop {function(): Object} toPlainObject() - Returns this Tag collection as a plain JS object
 */

/**
 * @private
 * @param {Object} data - Raw tag collection data
 * @return {TagCollection} Wrapped tag collection data
 */
export function wrapTagCollection(data) {
  return freezeSys(toPlainObject(copy(data)));
}