import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { ModalComponent } from "./index";
import { LoginForm } from "../LoginForm/LoginForm";
import { Button } from "../Button";
import warningInfoIcon from '../../assets/images/warningInfoIcon.svg';

const MessageContentStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '12px 16px',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: '2px solid #FDB022',
    background: '#FFF9EC',
};

export const LoginModal = ({isLoginModalOpen, handleCloseModal, postLoginRedirectPath, handleOpenCreateAccountModal}) => {
    const theme = useTheme();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const signUpUrl = `/${locale}/sign-up`;
    const isRegistrationEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
    const isRegistrationOnHold = process.env.IS_REGISTRATION_ON_HOLD === 'true';

    const handleCreateAccount = () => {
        if (isRegistrationEnabled) {
            handleOpenCreateAccountModal()
        } else {
            history.push(signUpUrl)
            handleCloseModal()
        }
    }

    const closeLoginModal = () => {
        handleCloseModal();
    };

    const loginFormContent = (
        <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'24px'}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}
        >
            <LoginForm postLoginRedirectPath={postLoginRedirectPath} />
            <Box
                component={'div'}
                sx={{
                    border:"1px solid",
                    borderColor:'neutral.gray6',
                    height:'330px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '1px',
                        margin: '24px 0',
                    },
                }}
            />
            <Box
                width={'302px'}
                height='320px'
                gap={'24px'}
                display='flex'
                flexDirection={'column'}
                alignItems={'flex-start'}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                    },
                }}
            >
                <Typography 
                    fontSize={'24px'} 
                    fontFamily={'Aeonik Regular'}
                    fontWeight={700}
                    lineHeight={'30px'}
                >
                    Create an account
                </Typography>
                {!isRegistrationOnHold ? <Typography 
                    fontSize={'16px'} 
                    fontFamily={'Aeonik Regular'}
                    fontWeight={400}
                    textAlign={'left'}
                    lineHeight={'22px'}
                >
                    To order, please create an account for Moderna Direct, even if you have an account on other Moderna websites.
                </Typography>
                :
                <Box sx={MessageContentStyles} >
				    <img src={warningInfoIcon} alt="info icon" width="32" height="32" />
                    <Box data-testid="messageContent" display="flex" flexDirection="column" alignItems="flex-start" gap='10px' flex="1 0 0">
                        <Typography fontFamily="Aeonik Bold" fontSize="14px" textAlign="left" fontWeight="700" data-testid='headerText'>
                            We are performing scheduled maintenance on our registration system, and new registrations are temporarily paused.
                        </Typography>
                        <Typography fontFamily="Aeonik Regular" fontSize="14px" textAlign="left" data-testid='message'>
                            Please call 1-866-MODERNA to place an order. Existing customers can continue to place orders as usual.
                        </Typography>
                    </Box>
			    </Box>
                }
                <Button 
                    sx={{height: '48px', textTransform: "none"}} 
                    buttonType= 'mds-primary' 
                    onClick={handleCreateAccount}
                    disabled={isRegistrationOnHold}
                >
                    Create a new account
                </Button>
            </Box>
        </Box>
    );


    return(
        <ModalComponent
            openModal={isLoginModalOpen}
            closeModalHandler={handleCloseModal}
            modalContent={loginFormContent}
            style={{ 
                width: '829px',
                padding: '24px',
                alignItems: 'flex-start',
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    width: '80%',
                    maxWidth: '100vw',
                    padding: '16px',
                    margin: '80px 0px 0px 0px'
                },
            }}
        />
    )
}