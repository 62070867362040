import { Box, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OrderProductTile } from "../OrderProductTile/index";
import { OrderProductTileV2 } from "../OrderProductTile/OrderProductTileV2";
import { getProductsApi } from "../../features/commerce/commerceThunkApi";
import { Spinner } from "../Spinner";
import { AlertMessage } from "../AlertMessage";
import { useUserInfoContext } from "../../contexts";
import { loadCategoryProductTilesComponent } from "../../features/contentful/contentfulThunkApi";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getAnalyticsProductDataAttributes } from "../../utils/Helper";

const productTileStyles = {
  overflowX: { xs: "scroll", sm: "unset" },
  overflowY: { xs: "hidden", sm: "unset" },
  msOverflowStyle: "none",
  "::-webkit-scrollbar": {
    display: "none",
  }
}
const CustomBold = styled("b")`
  font-family: "Aeonik Bold", "Arial";
`;

export const OrderProducts = ({ isHomePage = false }) => {
  const localeISOCode = useSelector(
    (state) => state.globalStates.localeISOCode
  );
  const { globalErrorMessage = [] } = useSelector(
    (store) => store?.globalMessages
  );
  const generalApiErrorData =
    globalErrorMessage &&
    globalErrorMessage.length &&
    globalErrorMessage.filter(
      (data) => data?.fields?.code === "GENERAL_API_ERROR"
    );
  const generalApiError =
    generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const { products } = useSelector((store) => store?.commerce);
  const { categoryProductTilesComponent: staticContent } = useSelector(
    (store) => store?.contentful
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [processing, setProcessing] = useState(false);
  const alertRef = useRef(null);
  const dispatch = useDispatch();
  const { userInfo } = useUserInfoContext();
  const { uid: userId } = userInfo || {};
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>,
    },
  };

  useEffect(() => {
    if (staticContent && Object.keys(staticContent).length === 0)
      dispatch(loadCategoryProductTilesComponent());
  }, [staticContent, dispatch]);

  useEffect(() => {
    setProcessing(true);
    dispatch(
      getProductsApi({
        catalogVersion:
          localeISOCode && localeISOCode === "US"
            ? `modernaProductCatalog`
            : `modernaProductCatalog-${localeISOCode}`,
        userId,
        isAuthenticationRequired: !isHomePage,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        setProcessing(false);
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert();
        setProcessing(false);
      });
  }, [dispatch, localeISOCode, generalApiError, userId]);

  // if (loading) {
  //   return <Spinner processing={loading} />
  // }

  const getProductYear = (product) => {
    const regexPattern = /\b\d{4}-\d{4}\b/;
    return product?.name.match(regexPattern);
  };

  const getImageUrl = (imagesData, imageType, format) => {
    if (!imagesData || imagesData.length === 0) return false;
    const imageFilteredData = imagesData.filter(
      (data) => data?.imageType === imageType && data?.format === format
    );
    return imageFilteredData[0]?.url;
  };

  const getProducts = (products, isHomePage, homePageDisplayedProductCodes) => {
    if (isHomePage) {
      return sortFilterProductsByCode(products, homePageDisplayedProductCodes);
    }
    return products;
  }

  // For sorted display on the home page
  const homePageDisplayedProductCodes = ['70291', '70289', '70588'];

  const sortFilterProductsByCode = (products, productCodes) => {
    if (!products || !Array.isArray(products)) {
      return [];
    }
    const filteredProducts = products.filter(product => productCodes.includes(product.code));

    filteredProducts.sort((a, b) => productCodes.indexOf(a.code) - productCodes.indexOf(b.code));

    return filteredProducts;
  };

  return (
    <>
      <AlertMessage
        variant={"filled"}
        type={"error"}
        message={errorMsg}
        sx={{ top: 120 }}
        ref={alertRef}
      />
      <Spinner processing={processing} />
      {products && (
        <Box>
          {!isHomePage && <Typography variant={"h3"} textAlign={'center'} fontFamily="Aeonik Regular">{documentToReactComponents(staticContent?.message, options)}</Typography>}
          <Box
            display={{sm: "flex", xs: "grid" }}
            gap={isHomePage ? 0 : 6}
            alignItems={"center"}
            justifyContent={"center"}
            my={isHomePage ? 0 : 3}
            marginTop={isHomePage ? {xs:"50px", lg: '0px'} : ""}
            marginBottom={isHomePage ? "50px" : ""}
            sx={{
            gridTemplateColumns: { xs: "1fr 1fr", lg: `repeat(${products?.products?.length}, 1fr)`},
            gridTemplateRows: "auto",
            "& > *": {// Apply styles to all direct children
              width: "auto",
            },
            "@media (max-width: 600px)": {
              gridTemplateColumns: "1fr 1fr",
              rowGap: '15px'
            },
          }}
          >
            { getProducts(products?.products, isHomePage, homePageDisplayedProductCodes)?.map((item, index) => {
            return(
              <Box
                key={`OrderProductBox-${item?.code}`}
                display={{ sm: "flex", xs: "grid" }}
                alignItems={"center"}
                gap={isHomePage ? "20px" : 1}
                justifyContent={{ xs: "start", sm: "center" }}
                sx={[
                  productTileStyles,
                  {
                    "@media (max-width: 600px)": {
                      gridTemplateColumns: {
                        xs: "1fr 1fr",
                        lg: "repeat(auto-fit, minmax(100px, 1fr))",
                      },
                      overflowX: "clip",
                      overflowY: "clip",
                      columnGap: '0px',
                    },
                  },
                ]}
              >
                {isHomePage ? (
                  <OrderProductTileV2
                    key={`OrderProductTileV2-${item?.code}`}
                    index={index}
                    productCode={item.code}
                    ageGroup={item.ageGroup}
                    description={item.description}
                    iconImage={getImageUrl(item?.images, "PLP", "Hover")}
                    yearGroup={getProductYear(item)}
                  />
                ) : (
                  <Link
                    to={`/product/${item?.code}`}
                    key={`OrderProductTile-${item?.code}`}
                  >
                    <OrderProductTile
                      index={index}
                      ageGroup={item.ageGroup}
                      description={item.description}
                      iconImage={getImageUrl(item?.images, "PLP", "VialSyringe")}
                      hoverImage={getImageUrl(item?.images, "PLP", "Hover")}
                      productData={getAnalyticsProductDataAttributes(item)}
                    />
                  </Link>
                )}
              </Box>
            );})}
        </Box>
      </Box>  
      )}
    </>
  );
};
