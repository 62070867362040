import authAxiosInstance from "../../sevices/authAxiosInstance";

export const createPaymentMethodApi = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const updatePaymentMethodApi = async (payload, paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const deletePaymentMethodApi = async (paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "DELETE",
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const listPaymentMethodsApi = async (payload = {}) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/list`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const describePaymentMethodApi = async (paymentMethodId) => {
    try {
        const res = await authAxiosInstance({
            url: `paymentsservice/payment-method/${paymentMethodId}`,
            method: "GET",
        });
        return res;
    } catch (error) {
        throw error;
    }
}
