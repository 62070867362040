import { Box, Typography, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { getSAPUserByEmail, getUserByEmail, updateUserDetailsApi} from "../../utils/UserService/UserApis";
import { useDispatch, useSelector } from "react-redux";
import { setIsOnboardingSuccessful } from "../../features/globalStates/globalStatesSlice";
import { useHistory } from "react-router-dom";
import { validateMedicalIdentification } from "./Helper";
import { AlertMessage, Button, Spinner } from "../../components";
import { setUserStatus } from "../../features/globalStates/globalStatesSlice";

const typographyTextStyle = {
    fontFamily: 'Aeonik Regular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
};

const medicalIdentificationText = {
    validationInfo: "We use organizational HINs, DEA Numbers, and NPIs to validate your account. Enter multiple identifiers for best results.",
    requirementNote: " At least one HIN or DEA number is required.",
    separationGuidance: "Separate multiple numbers with a space, tab, or comma.",
    hinLabelText: "Your organization’s HINs",
    deaLabelText: "Your organization’s DEA numbers",
    npiLabelText: "Your organization’s NPI numbers",
    hinInputPrompt: "Enter one or more HINs (Health Industry Numbers)",
    deaInputPrompt: "Enter one or more facility DEAs (US Drug Enforcement Agency numbers)",
    npiInputPrompt: "Enter one or more NPIs (National Provider Identifiers)"
}

const multilineTextBox = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        '& .MuiInputBase-input': {
            fontFamily: 'Aeonik Regular',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D1D2D5',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D1D2D5',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D1D2D5'
        }
    },
}
const MedicalIdentification = () => {
    const defaultFields = {
        HIN: '',
        DEA: '',
        NPI: ''
    }
    const [inputFields, setInputFields] = useState(defaultFields);
    const [errors, setErrors] = useState(defaultFields);
    const dispatch = useDispatch();
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const errMsgNoHINOrDEA = "Please input at least one HIN or DEA number"
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const tempError = { ...errors };
        if((name === "HIN" || name === "DEA") && value !== ''){
            Object.keys(tempError).forEach((key) => {
                if (tempError[key] === errMsgNoHINOrDEA) {
                    tempError[key] = "";
                }
            });
        }
        const errorMsg = validateMedicalIdentification(name, value);
        tempError[name] = errorMsg;
        setErrors(tempError);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const textInputComponent = (name, headerText, placeHolder, handleBlur) => {
        return (
            <Box data-testid={`${name}TextInput`} mb={2}>
                <Typography variant='p1' fontFamily='Aeonik Regular'>{headerText}</Typography>
                <Typography variant='p1' fontFamily='Aeonik Regular' fontWeight={400}> (not individual practitioners’)</Typography>
                <TextField
                    sx={multilineTextBox}
                    multiline
                    name={name}
                    rows={3}
                    placeholder={placeHolder}
                    error={!!errors[name]}
                    helperText={errors[name]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
            </Box>
        )
    }

    const formatValueList = (value) => {
        const valueList = value.split(/[ ,\n]+/).map(s => s.trim()).filter(Boolean);
        return valueList.join(',')
    }

    const getValueList = () => {
        return [
            {
                type: "HIN",
                valueList: formatValueList(inputFields?.HIN)
            },
            {
                type: "DEA",
                valueList: formatValueList(inputFields?.DEA)
            },
            {
                type: "NPI",
                valueList: formatValueList(inputFields?.NPI)
            }
        ]
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let isFormNotValid = false;

        let tempError = {...defaultFields}
        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateMedicalIdentification(fieldName, inputFields[fieldName]);
            if(errorMsg) isFormNotValid = true;
            tempError[fieldName] = errorMsg;
        });
        if(inputFields?.HIN === "" && inputFields?.DEA === ""){
            isFormNotValid = true;
            tempError = {HIN : errMsgNoHINOrDEA, DEA: errMsgNoHINOrDEA, NPI:""}
        }
        setErrors(tempError)

        const updateUserRequestBody =
            {
                "userId": userId,
                "updateType": "identification",
                "orgLicenses": getValueList()
            }
        if(!isFormNotValid){
            try {
                setIsLoading(true);
                const response = await updateUserDetailsApi(updateUserRequestBody);
                if (response?.status === 200) {
                    await dispatch(getUserByEmail({emailAddress: response?.data?.email})).unwrap();
                    await dispatch(getSAPUserByEmail({ emailAddress: response?.data?.email})).unwrap();
                    dispatch(setIsOnboardingSuccessful(true));
                    dispatch(setUserStatus(response?.data?.userStatus));
                    history.push(`/${locale}/onboarding/success`);
                    setInputFields(defaultFields);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message;
                setErrorMsg(errMsg || generalApiError);
                alertRef.current?.openAlert(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <Box flexDirection='column' width='100%' maxWidth='680px'>
                <Box mt={4}>
                    <Typography sx={typographyTextStyle}>{medicalIdentificationText?.validationInfo}</Typography>
                </Box>
                <Box mt={2}>
                    <Typography sx={typographyTextStyle}><span style={{ color: 'red' }}>*</span>{medicalIdentificationText?.requirementNote}</Typography>
                </Box>
                <Box mt={2} mb={4}>
                    <Typography sx={typographyTextStyle}>{medicalIdentificationText?.separationGuidance}</Typography>
                </Box>
                {textInputComponent('HIN', medicalIdentificationText?.hinLabelText, medicalIdentificationText?.hinInputPrompt, handleBlur, handleChange)}
                {textInputComponent('DEA',medicalIdentificationText?.deaLabelText, medicalIdentificationText?.deaInputPrompt, handleBlur, handleChange)}
                {textInputComponent('NPI', medicalIdentificationText?.npiLabelText, medicalIdentificationText?.npiInputPrompt, handleBlur, handleChange)}
                <Box mt={6}>
                    <Button
                        type="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        data-testid='submitMedicalIdentification'
                        buttonType="mds-primary"
                        onMouseDown={handleSubmit}
                    >
                        Continue
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default MedicalIdentification