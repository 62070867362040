import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Button, ExternalRouterPrompt } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { loadOrderEducationalCardContent } from "../../features/contentful/contentfulThunkApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { loadInterstitialComponentStaticContent } from "../../features/contentful/contentfulThunkApi";
import { isExternalURL } from "../../utils/Helper";
import {MARKS} from "@contentful/rich-text-types";

const Card = styled(Grid)(({ bgimageurl, theme: { palette: { neutral }, breakpoints } }) => `
    background-color: ${neutral.white};
    border: 1px solid ${neutral.gray2};
    border-radius: 16px;
    padding: 25px;
    display: flex;
    background-image: url(${bgimageurl});
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 45%;
    ${[breakpoints.down("sm")]} {
        background-image: unset;
        margin: 12px 0px;
    };
`);

const BoxContent = styled(Box)(({ theme: { breakpoints } }) => `
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 55%;
    padding-right: 20px;
    ${[breakpoints.down("sm")]} {
        max-width: 100%;
    };
`);

const CustomBold = styled('b')`
  font-family: 'Aeonik Bold', 'Arial';
`;

const OrderEducationalCards = () => {
    const dispatch = useDispatch();
    const [showNavigationAlert, setShowNavigationAlert] = useState(false);
    const [externalLink, setExternalLink] = useState("");
    const [externalLinkModalType, setExternalLinkModalType] = useState("extrenalLink");
    const { interstitialComponentContent: staticContent  } = useSelector((store) => store?.contentful);

    useEffect(() => {
        dispatch(loadOrderEducationalCardContent());
        if (staticContent && Object.keys(staticContent).length === 0) {
            dispatch(loadInterstitialComponentStaticContent())
        }
    }, [dispatch, staticContent]);

    const closeNavigationAlert = (type) => {
        setShowNavigationAlert(false)
    }

    const handleRedirect = (url) => {

        if (isExternalURL(url) && !localStorage.getItem("dontRemindMeExternal")) {
            setShowNavigationAlert(true);
            setExternalLink(url);
            setExternalLinkModalType("extrenalLink");
        } else {
            window.open(url, '_blank');
            setShowNavigationAlert(false);
            setExternalLink("");
        }
    }

    const { orderEducationalCardsContent: cardContent } = useSelector((store) => store?.contentful);
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <CustomBold>{text}</CustomBold>
        }
    };

    return (
        <>
            { externalLinkModalType === "extrenalLink" && showNavigationAlert && !localStorage.getItem('dontRemindMeExternal') && <ExternalRouterPrompt
                    isOpen={showNavigationAlert}
                    closeModalHandler={() => closeNavigationAlert("extrenalLink")}
                    externalLink={externalLink}
                    onOpenLink={setShowNavigationAlert}
                    type={"extrenalLink"}
                    heading={staticContent?.externalLink?.header}
                    headingStyle={{
                    fontFamily: "Aeonik Bold",
                    fontSize: "30px",
                    '@media screen and (max-width: 500px)': {
                        fontSize: '24px',
                        paddingBottom: "24px"
                    }
                    }}
                    content={staticContent?.externalLink?.subHeader}
                    checkBoxLabel={staticContent?.externalLink?.remindMeText}
                    buttonLabel={staticContent?.externalLink?.buttonLabel}
                />}
            <Box display={'grid'} alignItems={'center'} justifyContent={'center'}>
                <Typography data-testid="HeadingTest" variant={"h3"} color="primary.main" textAlign={"center"}>{cardContent?.headline}</Typography>
                <Typography data-testid="SubHeadingTest" variant={"p1"} color="primary.main" textAlign={"center"}>{cardContent?.subheadline}</Typography>
            </Box>
            <Grid container data-testid="GridCardTest" gap={1} gridTemplateColumns={{xs: "unset", sm:"auto auto"}} display={"grid"} justifyContent={"center"}>
                {cardContent && cardContent.cards && cardContent.cards.length > 0 && cardContent.cards.map((card, index) => (       
                    <Card item xs={12} key={index} id={"educationalCard" + index} data-testid={"educationalCard" + index} bgimageurl={card?.fields?.image?.fields?.file.url}>
                        <BoxContent>
                            <Typography variant={"h4"} color="primary.main" overflow="hidden" textOverflow={"ellipsis"} fontFamily="Aeonik Regular">{documentToReactComponents(card?.fields?.headlineRt, options)}</Typography>
                            <Typography variant={"p1"} color="primary.main" mt={1} mb={5} maxHeight="100px" overflow="hidden" textOverflow={"ellipsis"}>{documentToReactComponents(card?.fields?.message)}</Typography>  
                            <div>
                                <Button
                                    buttonType="secondary"
                                    icon={false}
                                    data-link-href={localStorage.getItem('dontRemindMeExternal') && card?.fields?.callToActionLink?.fields?.url}
                                    onClick={() => {setShowNavigationAlert(true); setExternalLink(card?.fields?.callToActionLink?.fields?.url); handleRedirect(card?.fields?.callToActionLink?.fields?.url);}}
                                    sx={{ mt: "10px", maxWidth: "200px"}}
                                    >
                                    {card?.fields?.callToActionLabel}
                                </Button>
                            </div>
                            
                        </BoxContent>
                    </Card>))
                }
            </Grid>
        </>
    )
}

export default OrderEducationalCards;