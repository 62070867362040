import { Box, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import warningInfoIcon from '../../assets/images/warningInfoIcon.svg';

const MessageContentStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '18px',
    padding: '12px 16px',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: '2px solid #FDB022',
    background: '#FFF9EC',
};

const styledLinkStyle = {
	color: '#1E2846',
	textDecoration: 'underline'
}

const NotOrderableAlert = ({ sourcePage }) => {
	const locale = useSelector((state) => state.globalStates.locale);
	const contactUsText = (
		<>
		  To directly order from Moderna, <Link style={styledLinkStyle} to={`/contact-us`}>contact us</Link>.
		</>
	);
	
	const reserveNowText = (
		<>
		  However, you <Link style={styledLinkStyle} to={`/reservation`}>can reserve Moderna (Covid-19 Vaccine, mRNA) 2024-2025 products.</Link>
		</>
	);
	
	const alertMessages = {
		Products: {
			headerText: '2023-2024 products are not currently available from your account.',
			line1: 'Please contact your wholesaler or distributor to order 2023-2024 products.',
			line2: reserveNowText,
		},
		ProductDisplay: {
			headerText: '2023-2024 products are not currently available.',
			line1: 'This product is not available for ordering from this account. Please contact your wholesaler or distributor to order this product.',
			line2: '',
		},
	};
	
	const alertMessage = (sourcePage) => {
		const { headerText, line1, line2 } = alertMessages[sourcePage] || alertMessages.ProductDisplay;
		return { headerText, line1, line2 };
	};

	return (
			<Box sx={MessageContentStyles} marginBottom={sourcePage !== 'Products' ? '20px' : 0}>
				<img src={warningInfoIcon} alt="info icon" width="32" height="32" />
				<Box data-testid="messageContent" display="flex" flexDirection="column" alignItems="flex-start" gap="10px" flex="1 0 0">
					
					<Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700" data-testid='headerText'>
						{alertMessage(sourcePage).headerText}
					</Typography>
					<Typography fontFamily="Aeonik Regular" textAlign="left" data-testid='message'>
						{alertMessage(sourcePage).line1} {contactUsText} <br /> {alertMessage(sourcePage).line2}
					</Typography>
				</Box>
			</Box>
		);
};

export default NotOrderableAlert;
