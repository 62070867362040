export const PaymentErrorCodes = {
    CREATE: {
        RESOURCE_QUOTA_EXCEEDED: 'RESOURCE_QUOTA_EXCEEDED_ERROR',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    LIST: {
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    UPDATE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    DESCRIBE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    },
    DELETE: {
        RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
        INVALID_REQUEST_PARAMETERS: 'INVALID_REQUEST_PARAMETERS',
    }
};
