import { useState, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box } from '@mui/material';
import PaymentMethodForm from '../components/Payments/PaymentMethodForm';
import { createPaymentMethodApi, listPaymentMethodsApi } from '../utils/PaymentsService/PaymentMethodApis';
import { getErrorMessage } from '../utils/getErrorMessage';
import { ALLOWED_CREDIT_CARD_BRANDS, PAYMENT_METHOD_STATUS, PAYMENT_PROVIDER } from '../constants';

const isPaymentsLiveModeEnabled = process.env.IS_PAYMENTS_LIVE_MODE_ENABLED === 'true';
const STRIPE_PUBLISHABLE_KEY = isPaymentsLiveModeEnabled ? process.env.STRIPE_PUBLISHABLE_LIVE_KEY : process.env.STRIPE_PUBLISHABLE_TEST_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, { betas: ["blocked_card_brands_beta_2"] });

const useAddPaymentMethod = (handleErrorMsg, setPaymentMethodAdded) => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [processing, setProcessing] = useState(false);
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const dispatch = useDispatch();

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
        }
    };

    const options = { 
        clientSecret,
        appearance,
        allowedCardBrands : ALLOWED_CREDIT_CARD_BRANDS
    }

    const StripeForm = () => {
        return (
            <Box paddingTop={4} paddingBottom={4} >
                <Elements options={options} stripe={stripePromise}>
                    <PaymentMethodForm
                        setShowPaymentForm={setShowPaymentForm}
                        paymentMethodId={paymentMethodId}
                        handleErrorMsg={handleErrorMsg}
                        setPaymentMethodAdded={setPaymentMethodAdded}
                    />
                </Elements>
            </Box>
        );
    }

    const onAddPaymentMethodClick = useCallback(async () => {
        try {
            setProcessing(true);
            const response = await listPaymentMethodsApi({ status: PAYMENT_METHOD_STATUS.PENDING });
            if (response.status === 200 && response?.data?.paymentMethodsList?.length) {
                const result = response.data.paymentMethodsList?.[0];
                setClientSecret(result?.paymentMethodLifecycleDetails?.clientSecret);
                setPaymentMethodId(result?.paymentMethodId);
                setShowPaymentForm(true);
            } else {
                try {
                    setProcessing(true);
                    const response = await createPaymentMethodApi({
                        paymentProvider: PAYMENT_PROVIDER,
                        idempotencyToken: uuidv4()
                    });
                    if (response?.status === 200) {
                        let client_secret = response?.data?.paymentMethodLifecycleDetails?.clientSecret;
                        setClientSecret(client_secret);
                        setPaymentMethodId(response?.data?.paymentMethodId);
                        setShowPaymentForm(true);
                    }
                } catch (error) {
                    setProcessing(false);
                    const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
                    handleErrorMsg(errorMessage);
                }
            }
        } catch (error) {
            setProcessing(false);
            const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
            handleErrorMsg(errorMessage);
        } finally {
            setProcessing(false);
        }
    }, [dispatch, handleErrorMsg]);

    const handleClosePaymentModal = () => {
        setShowPaymentForm(false);
        setProcessing(false);
    }

    return {
        showPaymentForm,
        processing,
        onAddPaymentMethodClick,
        handleClosePaymentModal,
        StripeForm
    };
};

export default useAddPaymentMethod;
