import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    useTheme,
    useMediaQuery,
    Box
  } from '@mui/material';
  
  import { Button } from '../Button';
  import { Link } from "react-router-dom";
  
  const CardComponent = ({
    title,
    description,
    imageUrl,
    imageAlt,
    imageStyle,
    actions,
    additionalText,
    sx,
    cardContentSx,
    children,
    factSheetContents,
    ...props
  }) => {
    const theme = useTheme();

  const cardStyles = {
    bgcolor: 'neutral.white',
    borderRadius: '16px',
    boxShadow: '2px 2px 2px #00000029, -2px 2px 2px #00000029, 0px 2px 2px #00000029',
    ...sx
  };

  // TO-DO: Make these custom css
  
    return (
      <Card {...props} sx={cardStyles}>
        <CardContent sx={{...(cardContentSx || {padding:'15px 15px 0px 33px'})}} > 
          {imageUrl && (
            <CardMedia
              component="img"
              image={imageUrl}
              alt={imageAlt}
              sx={imageStyle}

            />
          )}
          {title && (
            <Typography 
              gutterBottom component="div" 
              fontSize={{xs: '16px', lg: '24px'}} 
              paddingTop={'16px'}
              fontFamily={'Aeonik Regular'}
              fontWeight={700}
              lineHeight={'32px'}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography 
              color="text.secondary"
              fontSize={'24px'} 
              fontFamily={'Aeonik Regular'}
              fontWeight={400}
              lineHeight={'32px'}
              paddingTop={'16px'}
              >
              {description}
            </Typography>
          )}
          {actions && actions.length > 0 && (
          <CardActions sx={{padding:'16px 0px 0px 0px'}}>
            {actions.map((action, index) => (
              <Button size="small" key={index} {...action} buttonType='mds-secondary' sx={{textTransform: "none"}}>
                {action.label}
              </Button>
            ))}
          </CardActions>
        )}
        {factSheetContents && (
          <Box mt="16px">
            {factSheetContents
            .map((factSheetContent, i) => {
              return (
                <Link key={i} to={{ pathname: factSheetContent?.fields?.url }} target="_blank">
                  <Typography fontFamily="Aeonik Regular" fontSize="14px" color="#0379B2">{factSheetContent?.fields?.label}</Typography>
                </Link>
              )
            })
          }
          </Box>
        )}
        </CardContent>
        
        {additionalText && (
          <Typography
            fontSize={'14px'} 
            fontFamily={'Aeonik Regular'}
            fontWeight={400}
            lineHeight={'20px'}
            display={'flex'}
            justifyContent={'flex-end'}
            padding={'20px 30px'}
            >
            {additionalText}
          </Typography>)
        }
        {children}
      </Card>
    );
  };
  
  export default CardComponent;