import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import OrderEducationalCards from "../components/OrderEducationalCards";
import { OrderProducts } from "../components/OrderProducts";
import { OrderProductsBanner } from "../components/OrderProductsBanner";
import NotOrderableAlert from "../components/NotOrderableAlert/notOrderableAlert";
import infoIcon from '../assets/images/infoIcon2.svg';

const MessageContentStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-start',
	gap: '18px',
	padding: '30px 16px',
	borderRadius: '0px 8px 8px 0px',
	borderLeft: '2px solid #BFE6F6',
	background: '#BFE6F6',
	height: '100px'
};

const ProductsPage = () => {
	const locale = useSelector((state) => state.globalStates.locale);
	const isSiteOutageBannerEnabled = process.env.IS_SITE_OUTAGE_BANNER_ENABLED === 'true';
	const canOrder2023Products = useSelector(store => store?.globalStates?.canOrder2023Products);

	return (
		<>
		{isSiteOutageBannerEnabled && canOrder2023Products &&
		<Box alignContent={'flex-start'} height={'50'}>
			<Box sx={MessageContentStyles} marginBottom={'20px'}>
			<img src={infoIcon} alt="info_icon"width="36" height="36"/>
			<Typography fontFamily="Aeonik Regular" fontSize="24px" fontWeight="700" data-testid='headerText'>
				To view contract pricing, select  your group affiliation in <Link to={`/${locale}/my-profile`}>your profile</Link>
			</Typography>
			</Box>
		</Box>
		}
		<OrderProductsBanner />
		<Box display='grid' gap={5} sx={{
			m: {xs: '32px', lg:'60px 20%'}
		}}>
			{ !canOrder2023Products && <NotOrderableAlert sourcePage={'Products'}/> }
			<OrderProducts/>
			<OrderEducationalCards />
		</Box>
		</>
	);
};

export default ProductsPage;
