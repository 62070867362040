import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { CartComponent, ProductsComponent, ManageReservationBanner, AlertMessage } from "../components";
import { useUserInfoContext } from "../contexts";
import { useRef } from "react";
import { useSelector } from 'react-redux';

const alertCustomStyle = {
  top:"20px !important",
  marginBottom:"-20px !important",
  position:"relative !important",
  left: "0 !important"
};

const EditReservation = () => {
    window.scrollTo(0, 0);

    const history = useHistory();
    const [ showProductSelection, setShowProductSelection ] = useState(false);
    const { userInfo } = useUserInfoContext()
    const { uid : userId } = userInfo;
    const { originalCartId }  = useParams();
    const [cartId, setCartId] = useState(localStorage.getItem("newCartId"));
    const [loader, setLoader] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
 
    const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
    const resError = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_PREBOOKING_ID");
    const alertRef = useRef(null)
    const locale = useSelector((state) => state.globalStates.locale);
    
    useEffect(() => {
      if(!cartId){
        localStorage.setItem("INVALID_PREBOOKING_ID", resError && resError[0]?.fields?.message);
       history.push(`/${locale}/reservations/create`);
      }
    }, [cartId, history, resError, locale]);

    return (
        <div>
          {
            showProductSelection
             ? <ProductsComponent cartId={cartId} userId={userId} pageType={ "edit" } openProductSelection={setShowProductSelection} setErrorMsg={setErrorMsg}/>
             : <>
                <ManageReservationBanner/>
                {<AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={alertCustomStyle} ref={alertRef}/> }
                {<CartComponent cartId={ cartId } originalCartId={originalCartId} userId={ userId } pageType={ "edit" } openProductSelection={setShowProductSelection} setLoader={setLoader} setErrorMsg={setErrorMsg}/>}
              </>
          }
          
      
        </div>
    )
}

export default EditReservation
