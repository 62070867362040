import {createTheme} from "@mui/material/styles";
import {iconButtonClasses, tablePaginationClasses, typographyClasses} from "@mui/material";

export const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 768,
        lg: 1024,
        xl: 1850,
      },
    },
    palette: {
      primary: {
        main: '#1E2846',
        blue: '#2AACE2',
        blueFaded: '#BFE6F6',
        darkBlue: '#02608C',
        red: '#E31837',
        button: '#D1343E',
        redFaded: '#F08477'
      },
      secondary: {
        main: '#F2FAFD',
        graphical: '#1D9CD3',
        textLinks: "#177EAB",
        containers: '#225D84',
        blueFaded: '#E3F3FF',
        button: '#92242B'
      },
      neutral: {
        main: '#000000',
        gray1: '#767676',
        gray2: '#C0C0C0',
        gray3: '#334E5C',
        gray4: '#F8F8F8',
        gray5: '#F7F7F7',
        gray6: '#D1D1D1',
        gray7: '#545F71',
        white: '#ffffff',
        darkGrey: '#383D47'
      },
      status: {
        success: '#49B705',
        error: '#D1343E',
        warning: '#FFCE00',
      }
    },
    typography: {
      h1: {
        fontFamily: 'Aeonik Light',
        fontSize: '48px',
        '@media (max-width:900px)': {
          fontSize: '30px'
        },
      },
      h2: {
        fontSize: '34px',
        fontFamily: 'Aeonik Light',
        lineHeight: '51px',
        '@media (max-width:900px)': {
          fontSize: '24px',
          lineHeight: '36px'
        },
      },
      h3: {
        fontSize: '24px',
        fontFamily: 'Aeonik Bold',
        lineHeight: '36px',
        '@media (max-width:900px)': {
          fontSize: '20px',
          lineHeight: '28px'
        },
      },
      h4: {
        fontSize: '20px',
        fontFamily: 'Aeonik Bold',
        lineHeight: '28px',
        '@media (max-width:900px)': {
          fontSize: '16px',
          lineHeight: '24px'
        },
      },
      h5: {
        fontSize: '16px',
        fontFamily: 'Aeonik Bold',
        lineHeight: '21px',
        '@media (max-width:900px)': {
          fontSize: '14px',
          lineHeight: '21px'
        },
      },
      h6: {
        fontSize: '12px',
        fontFamily: 'Aeonik Bold',
        '@media (max-width:900px)': {
          fontSize: '11px'
        }
      },
      p1: {
        fontSize: '16px',
        fontFamily: 'Aeonik Regular',
        lineHeight: '24px',
        '@media (max-width:900px)': {
          fontSize: '16px',
          lineHeight: '21px'
        },
      },
      p2: {
        fontSize: '14px',
        fontFamily: 'Aeonik Regular',
        lineHeight: '21px',
        '@media (max-width:900px)': {
          fontSize: '14px',
          lineHeight: '21px'
        },
      },
      p3: {
        fontSize: '20px',
        fontFamily: 'Aeonik Regular',
        lineHeight: '28px',
        fontWeight: 500,
        '@media (max-width:900px)': {
          fontSize: '18px',
          lineHeight: '28px'
        },
      },
      p4: {
        fontSize: '13px',
        fontFamily: 'Aeonik Regular',
        lineHeight: 'regular',
        fontWeight: 700,
        '@media (max-width:900px)': {
          fontSize: '13px',
          lineHeight: '18px'
        },
      },
      link: {
        fontSize: '16px',
        fontFamily: 'Aeonik Regular',
        '@media (max-width:900px)': {
          fontSize: '16px'
        },
      },
      disclaimerH3: {
        fontSize: '13px',
        fontFamily: 'Aeonik Regular',
        lineHeight: "20px",
        '@media (max-width:900px)': {
          fontSize: '13px'
        },
      },
      disclaimerParagraph: {
        fontSize: '11px',
        fontFamily: 'Aeonik Regular',
        lineHeight: "20px",
        '@media (max-width:900px)': {
          fontSize: '10px'
        },
      },
    },
    components: {
      MuiPickersLayout: {
       styleOverrides: {
          contentWrapper: {
           backgroundColor: 'white',
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            justifyItems: "center"
          },
          labelContainer: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
            left: 0,
            right: 0
          }
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&:hover, &:focus': {
              backgroundColor: 'white'
            },
            '&.Mui-selected, &.Mui-selected:hover, &:focus.Mui-selected': {
              backgroundColor: '#2AACE2'
            },
            '&:not(.Mui-selected)': {
              borderColor: '#2AACE2'
            },
          }
        }
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            display: "inline-flex"
          },
          button: {
            'svg': {
              color: '#2AACE2',
            },
            '&.Mui-disabled': {
              opacity: 0.6
            }
          },
          spacer: {
            width: '220px'
          }
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: 0
          }
        },
        variants: [
          {
              props: { variant: 'dynamic' },
              style: {
                ":before": {
                    borderTop: "thin solid #c0c0c0"
                },
                ":after": {
                    borderTop: "thin solid #c0c0c0"
                },
              }
          }
        ]
    },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {color: "red"},
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {color: "red"},
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            '.MuiDataGrid-withBorderColor': {
              borderColor: 'transparent'
            }
          },
          cell: {
            margin: '10px 0',
            '&:focus, &.MuiDataGrid-withBorderColor, &.MuiDataGrid-withBorderColor:focus': {
              outline: 'none'
            },
            '&.nonSelectable': {
              pointerEvents: 'none'
            }
          },
          row: {
            '&.Mui-odd, &.Mui-odd:hover, &.Mui-odd.Mui-selected, &.Mui-odd.Mui-selected:hover': {
              backgroundColor: '#F2FAFD'
            },
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'transparent'
            }
          },
          columnHeader: {
            fontFamily: 'Aeonik Bold',
            fontSize: 12,
            color: '#1E2846',
            textTransform: 'uppercase',
            [`.${iconButtonClasses.root} svg`]: {
              color: '#1E2846'
            },
            '.MuiDataGrid-columnHeaderTitle':  {
              fontFamily: 'Aeonik Bold'
            },
            '&:focus, &:focus-within': {
              outline: 'none'
            }
          },
          columnHeaderCheckbox: {
            '.MuiDataGrid-columnHeaderTitleContainer': {
              justifyContent: 'start'
            }
          },
          cellCheckbox: {
            justifyContent: 'start'
          },
          columnHeaders: {
            '&.MuiDataGrid-withBorderColor': {
              borderColor: '#E0E0E0'
            }
          },
          columnSeparator: {
            display: 'none'
          },
          checkboxInput: {
            color: "#1D9CD3",
            padding: 0,
            '&:hover': {
              backgroundColor: 'unset'
            },
            '&.Mui-checked': {
              color: "#1D9CD3",
            }
          },
          footerContainer: {
            borderTop: 'none',
            justifyContent: 'start',
            marginTop: '1rem',
            [`.${tablePaginationClasses.root}`]: {
              flexGrow: 1,
              justifyContent: 'center',
              display: 'flex'
            },
          },
          selectedRowCount: {
            position: 'absolute'
          }
        }
      }
    },
    overrides: {
      MuiMenu: {
        list: {
          backgroundColor: '#002F5F',
        },
      },
      MuiMenuItem: {
        root: {
          fontFamily: typographyClasses.h5
        },
      },
    }
  }
);
