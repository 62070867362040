import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { CartComponent, ManageReservationBanner, AlertMessage } from "../components";
import { useUserInfoContext } from "../contexts";
import { editReservationApi } from "../utils/ReservationApis";
import { useHistory } from "react-router";
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const alertCustomStyle = {
  top:"20px !important",
  marginBottom:"-20px !important",
  position:"relative !important",
  left: "0 !important"
};
const ReservationDetails = () => {
    const history = useHistory();
    const { userInfo } = useUserInfoContext()
    const { uid : userId } = userInfo;
    const { cartId: urlCartId }  = useParams(); 
    const [cartId, setCartId] = useState(urlCartId);
    const [isSaved, setIsSaved] = useState(localStorage.getItem("isSaved"));
    const [errorMsg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(true);
    const locale = useSelector((state) => state.globalStates.locale);
    const alertRef = useRef(null)
    const successAlertRef = useRef(null)
    
    const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
   
    localStorage.removeItem("isSaved")
    const editReservation = async () => {
      try {
        const res = await editReservationApi(userId, cartId);
        if(res?.status === 200){
          const { data } = res;
          setCartId(data?.code);
          localStorage.setItem("newCartId", data?.code);
          history.push(`/${locale}/edit-reservations/${data?.originalCartId}`)
        }
      } catch (error) {
        setErrorMsg(generalApiError)
        alertRef.current?.openAlert()
      }
    }

    useEffect(()=>{
      if(isSaved) successAlertRef.current?.openAlert()
    },[isSaved])

    return (
        <div>
          <ManageReservationBanner/>
          <AlertMessage id="changesSavedMessage" type={"info"} message={"Changes saved"} color={"info"} sx={alertCustomStyle} ref={successAlertRef}/>
          <AlertMessage id="errorMessage" variant={"filled"} type={"error"} message={errorMsg} sx={alertCustomStyle} ref={alertRef}/>
          <CartComponent cartId={ cartId } userId={ userId } pageType={ "details" } editReservationHandler={editReservation} setErrorMsg={setErrorMsg} setLoader={setLoader}/>
        </div>
    )
}

export default ReservationDetails
