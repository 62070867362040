import { createAsyncThunk } from "@reduxjs/toolkit";
import { contentfulClient } from "../../sevices/useContentful";

const contentfulRes = async (contentKey, title) => {
    const query = {
        skip: 0,
        content_type: contentKey,
        'fields.title': title,
        include: 5,
        select: "fields"
    };
    return contentfulClient.getEntries(query).then(res => res)
}

const addNotFoundText= (res1, res2, res3) => {
    let notFoundText = {
        "fields": {
            "displayName": res1?.items[0]?.fields?.notFoundText,
            "displayValue": res1?.items[0]?.fields?.notFoundText
        }
    };

    let jobRoles = res2?.items;
    let jobTitles = res3?.items;

    jobRoles.unshift(notFoundText)
    jobTitles.unshift(notFoundText)

    const staticContent = {
        staticContent: res1.items[0]?.fields,
        staticJobRoles: jobRoles,
        staticJobTitles: jobTitles
    }

    return staticContent;
}

export const loadLocaleISOCodeOptions = createAsyncThunk(
    "contentful/country",
    async () => {
        const res = await contentfulRes("country");
        const localeISOCodes = res?.items?.map(item => {
            return item?.fields?.isocode;
        })
        return localeISOCodes;
    }
)

export const loadHomeStaticContent = createAsyncThunk(
    "contentful/home",
    async ({ title1, title2 }) => {
        const promises = [
            contentfulRes("homePageTemplate"),
            contentfulRes("signInComponent", title1),
            contentfulRes("cardCarouselComponent", title2)
        ]
        const [res1, res2, res3] = await Promise.all(promises);
        const staticContent = {
            aboveFoldBGImage: res1?.items[0]?.fields?.aboveFoldBackgroundImage,
            signInItems: res2?.items[0],
            benefitCardItems: res3.items[0]
        }
        return staticContent
    }
)

export const loadProfileStaticContent = createAsyncThunk(
    "contentful/profile",
    async () => {
        const promises = [
            contentfulRes("myProfileComponent"),
            contentfulRes("jobRole"),
            contentfulRes("jobTitle")
        ]
        const [res1, res2, res3] = await Promise.all(promises)
        
        const staticContent = addNotFoundText(res1, res2, res3)

        return staticContent
    }
)

export const loadUserRegistrationLicenseStaticContent = createAsyncThunk(
    "contentful/userRegistrationLicenseInfo",
    async ({ title }) => {
        const res = await contentfulRes("registrationLicensesComponent", title)
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadUserRegistrationAccountStaticContent = createAsyncThunk(
    "contentful/userRegistrationAccount",
    async ({ title }) => {
        const res = await contentfulRes("registrationAccountComponent", title)
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadUserRegistrationContactStaticContent = createAsyncThunk(
    "contentful/userRegistrationContact",
    async ({ title }) => {
        const promises = [
            contentfulRes("registrationContactComponent", title),
            contentfulRes("jobRole"),
            contentfulRes("jobTitle")
        ]
        const [res1, res2, res3] = await Promise.all(promises)

        const staticContent = addNotFoundText(res1, res2, res3)

        return staticContent
    }
)

export const loadUserRegistrationPasswordStaticContent = createAsyncThunk(
    "contentful/userRegistrationPassword",
    async () => {
        const res = await contentfulRes("registrationPasswordComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadUserRegistrationReviewStaticContent = createAsyncThunk(
    "contentful/userRegistrationReview",
    async () => {
        const res = await contentfulRes("registrationReviewComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadUserRegistrationFinalStepStaticContent = createAsyncThunk(
    "contentful/userRegistrationFinalStep",
    async ({ title }) => {
        const res = await contentfulRes("registrationConfirmationComponent", title)
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadLandingStaticContent = createAsyncThunk(
    "contentful/landing",
    async ({ title1, title2, title3, title4 }) => {
        const promises = [
            contentfulRes("prebookingCardComponent", title1),
            contentfulRes("heroBannerComponent", title2),
            contentfulRes("newsComponent", title3),
            contentfulRes("welcomeBannerComponent", title4),
            contentfulRes("orderingCardComponent")
        ]
        const [res1, res2, res3, res4, res5] = await Promise.all(promises)
        const staticContent = {
            prebookingCard: res1.items[0]?.fields,
            news: {
                banner: res3.items[0].fields,
                newsCards: res3?.items[0]?.fields.newsCards?.map( card => card.fields )
            },
            infographic: res2.items[0].fields,
            welcomeBanner: res4.items[0].fields,
            orderingCard: res5.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadManageReservationListStaticContent = createAsyncThunk(
    "contentful/manageReservationList",
    async ({ title }) => {
        const res = await contentfulRes("reservationListComponent", title)
        return res.items[0].fields
    }
)

export const loadManageReservationBannerStaticContent = createAsyncThunk(
    "contentful/manageReservationBanner",
    async () => {
        const res = await contentfulRes("reservationsBannerComponent")
        return res.items[0].fields
    }
)

export const loadNotAuthorizedPageContent = createAsyncThunk(
    "contentful/notAuthroziedPage",
    async () => {
        const res = await contentfulRes("globalErrorComponent")
        const notAuthorizedPageContent = res?.items?.filter(item => {
            return item?.fields?.errorCode === "403"
        })
        return notAuthorizedPageContent[0].fields;
    }
)

export const loadNotFoundPageContent = createAsyncThunk(
    "contentful/notFoundPage",
    async () => {
        const res = await contentfulRes("globalErrorComponent", "404 Page Not Found Error Component")
        return res.items[0].fields
    }
)

export const loadImpersonationLookupContent = createAsyncThunk(
    "contentful/impersonationLookup",
    async () => {
        const promises = [
            contentfulRes("customerImpersonationComponent"),
            contentfulRes("adminWelcomeComponent")
        ]
        const [res1, res2] = await Promise.all(promises)
        const staticContent = {
            customerImpersonation: res1.items[0]?.fields,
            welcomeBanner: res2.items[0].fields
        }
        return staticContent
    }
)

export const loadHeaderStaticContent = createAsyncThunk(
    "contentful/header",
    async ({ title }) => {
        const res = await contentfulRes("header", title)
        return res.items[0].fields
    }
)
const sortFooterData = (footerGroupData) => {
    const footerGroupsPageRefSubHeading = footerGroupData?.subHeading;
    const footerGroupsPageRefLabel = footerGroupData?.footerGroupLinks?.map(item => {
        return item?.fields?.label
    })
    const footerGroupsPageRefSlug = footerGroupData?.footerGroupLinks?.map(item => {
        const slug = item?.fields?.pageReference?.fields?.slug
        return slug? slug : item
    })
    return [footerGroupsPageRefSubHeading, footerGroupsPageRefLabel, footerGroupsPageRefSlug]
}

export const loadFooterStaticContent = createAsyncThunk(
    "contentful/footer",
    async ({ title}) => {
        const res = await contentfulRes("footer", title);
        const footerGroups = res?.items[0]?.fields?.footerGroups

        const footerGroupSortedData = footerGroups.map(value => {
            const [footerGroupsPageRefSubHeading, footerGroupsPageRefLabel, footerGroupsPageRefSlug] = sortFooterData(value?.fields)
            const footerObject = {
                    "subHeading": footerGroupsPageRefSubHeading,
                    "label": footerGroupsPageRefLabel,
                    "footerGroupLinks": footerGroupsPageRefSlug,
            }
            return footerObject
        })
        const staticContent = {
            copyrightMessage: res?.items[0]?.fields?.copyrightMessage,
            imageData: res?.items[0]?.fields?.logo?.fields?.file?.url,
            socialLinks: res?.items[0]?.fields?.socialLinks,
            footerGroups: footerGroupSortedData,
            footerLinks: res?.items[0]?.fields?.footerLinks,
            accreditationsLabel: res?.items[0]?.fields?.accreditationsLabel,
            accreditationsLogo: res?.items[0]?.fields?.accreditationsLogo?.fields?.file?.url,
            nabpAccreditedUrl: res?.items[0]?.fields?.nabpAccreditedUrl
            }
        return staticContent
    }
)

export const loadResetPasswordStaticContent = createAsyncThunk(
    "contentful/resetPassword",
    async () => {
        const res = await contentfulRes("requestResetPasswordComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadRecoveryLinkStaticContent = createAsyncThunk(
    "contentful/recoveryLink",
    async () => {
        const res = await contentfulRes("requestResetPasswordConfirmationComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadNewPasswordStaticContent = createAsyncThunk(
    "contentful/newPassword",
    async ({ title }) => {
        const res = await contentfulRes("resetPasswordComponent", title)
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadTokenExpiredStaticContent = createAsyncThunk(
    "contentful/tokenExpired",
    async () => {
        const res = await contentfulRes("resetPasswordLinkExpiredComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadResetSuccessStaticContent = createAsyncThunk(
    "contentful/resetSuccess",
    async ({ title }) => {
        const res = await contentfulRes("resetPasswordConfirmationComponent", title)
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)

export const loadSessionHeaderStaticContent = createAsyncThunk(
    "contentful/sessionHeader",
    async () => {
        const res = await contentfulRes("impersonationSessionHeaderComponent")
        const staticContent = {
            staticContent: res?.items[0]?.fields,
        }
        return staticContent
    }
)
export const loadProductsComponentStaticContent = createAsyncThunk(
    "contentful/productsComponent",
    async ({ title }) => {
        const res = await contentfulRes("productSelectionComponent", title)
        return res.items[0].fields
    }
)

export const loadProductDetailContentComponent = createAsyncThunk(
    "contentful/productDetailContentComponent",
    async() => {
        const res = await contentfulRes("productDetailContentComponent")
        return res?.items[0]?.fields;
    }
)

export const loadPreBookConfirmationStaticContent = createAsyncThunk(
    "contentful/preBookConfirmation",
    async ({ title } ) => {
        const res = await contentfulRes("reservationConfirmationComponent", title)
        return res.items[0].fields
    }
)

export const loadCartComponentStaticContent = createAsyncThunk(
    "contentful/reviewReservationCartComponent",
    async ({ pageType, title, title1 }) => {
        const promises = [
            contentfulRes("reservationViewComponent", title1),
            contentfulRes("reservationCartComponent", title),
        ]
        const [res1, res2] = await Promise.all(promises)
        const content = {
            staticContent: res1.items[0],
            cartComponentStaticContent: res2.items[0]
        }
        const cartContent = {
            staticContent: res2.items[0],
            cartComponentStaticContent: res2.items[0]
        }
        if (pageType === "details" || pageType === "edit")
            return content
        else
            return cartContent
    }
)

export const loadContactUsStaticContent = createAsyncThunk(
    "contentful/contactus",
    async ({ title1, title2, title3 }) => {
        const promises = [
            contentfulRes("contactUsCardComponent", title1),
            contentfulRes("contactUsCardComponent", title2),
            contentfulRes("contactUsCardComponent", title3)
        ]
        const [res1, res2, res3] = await Promise.all(promises)
        const staticContent = [
            res1.items[0].fields,
            res2.items[0].fields,
            res3.items[0].fields
        ]
        return staticContent
    }
)

export const loadFaqStaticContent = createAsyncThunk(
    "contentful/faq",
    async () => {
        const res = await contentfulRes("faqPage")
        const staticContent = {
            faqBannerData: res.items[0]?.fields?.banner,
            contactUsData: res.items[0]?.fields?.contactUsCtaComponent
        }
        return staticContent
    },
)

export const loadFaqLocaleContent = createAsyncThunk(
    "contentful/faqCategory",
    async ({ isocode }) => {
        const res = await contentfulRes("faqCategory")
        const staticContent = res.items?.filter(item => {
            return item?.fields?.country?.fields?.isocode === isocode;
        })
        return staticContent
    },
)

export const loadInterstitialComponentStaticContent = createAsyncThunk(
    "contentful/interstitialComponent",
    async () => {
        const res = await contentfulRes("interstitialComponent")
        const content = {
            externalLink: res.items[0]?.fields,
            socialLink: res.items[1]?.fields
        }
        return content
    }
)

export const loadCategoryHeroBannerComponentStaticContent = createAsyncThunk(
    "contentful/categoryHeroBannerComponent",
    async () => {
        const res = await contentfulRes("categoryHeroBannerComponent")    
        return res?.items[0]?.fields
    }
)
        
export const loadOrderEducationalCardContent = createAsyncThunk(
    "contentful/categoryEducationalCardsComponent",
    async () => {
        const res = await contentfulRes("categoryEducationalCardsComponent")
        return res.items[0].fields;
    }
)

export const loadContractAllocationComponent = createAsyncThunk(
    "contentful/contractAllocationComponent",
    async () => {
        const res = await contentfulRes("contractAllocationComponent")
        return res.items[0].fields
    }
)

export const loadOrderSummaryComponentContent = createAsyncThunk(
    "contentful/orderSummaryComponent",
    async () => {
        const res = await contentfulRes("orderSummaryComponent")
        return res.items[0].fields;
    }
)

export const loadCartComponentContent = createAsyncThunk(
    "contentful/cartComponent",
    async () => {
        const res = await contentfulRes("cartComponent")
        return res.items[0].fields;
    }
)

export const loadOrderConfirmationComponent = createAsyncThunk(
  "contentful/orderConfirmationComponent",
  async () => {
    const res = await contentfulRes("orderConfirmationComponent");
    return res.items[0].fields;
  }
);

export const loadOrderHistoryBannerComponent = createAsyncThunk(
  "contentful/orderHistoryBannerComponent",
  async () => {
    const res = await contentfulRes("orderHIstoryBannerComponent");
    return res.items[0].fields;
  }
)

export const loadOrderHistoryTableComponent = createAsyncThunk(
  "contentful/orderHistoryTableComponent",
  async () => {
    const res = await contentfulRes("orderHIstoryTableComponent");
    return res.items[0].fields;
  }
)

export const loadCheckoutPageComponentContent = createAsyncThunk(
    "contentful/checkoutComponent",
    async ()=> {
        const res = await contentfulRes("checkoutComponent");
        return res.items[0].fields;
    }
)

export const loadCategoryProductTilesComponent = createAsyncThunk(
    "contentful/categoryProductTilesComponent",
    async ()=> {
        const res = await contentfulRes("categoryProductTilesComponent");
        return res.items[0].fields;
    }
)

export const loadOrderDetailSummaryComponent = createAsyncThunk(
    "contentful/orderDetailSummaryComponent",
    async ()=> {
        const res = await contentfulRes("orderDetailSummaryComponent");
        return res.items[0].fields;
    }
)

export const loadOrderItemGridTableComponent = createAsyncThunk(
    "contentful/orderItemGridTableComponent",
    async ()=> {
        const res = await contentfulRes("orderItemGridTableComponent");
        return res.items[0].fields;
    }
)

export const loadAddressComponent = createAsyncThunk(
    "contentful/addressComponent",
    async (title = 'Address Component')=> {
        const res = await contentfulRes("addressComponent", title);
        return res.items[0].fields;
    }
)

export const loadDatesComponent = createAsyncThunk(
    "contentful/dates",
    async () => {
        const res = await contentfulRes("dates")
        return res.items[0].fields;
    }
)

export const loadReservationPage = createAsyncThunk(
    "contentful/reservationPage",
    async ()=> {
        const res = await contentfulRes("reservationPage");
        return res.items[0].fields;
    }
)

export const loadOrderPage = createAsyncThunk(
    "contentful/orderPage",
    async ()=> {
        const res = await contentfulRes("orderPage");
        return res.items[0].fields;
    }
)
export const loadHomePage = createAsyncThunk(
    "contentful/homePageContent",
    async ()=> {
        const res = await contentfulRes("homePageContent");
        return res.items[0].fields;
    }
)

export const loadConfirmationModalComponent = createAsyncThunk(
    "contentful/confirmationModalComponent",
    async ()=> {
        const res = await contentfulRes("confirmationModalComponent");
        return res.items[0].fields;
    }
)

export const loadGlobalBannerComponent = createAsyncThunk(
    "contentful/globalBanner",
    async ()=> {
        const res = await contentfulRes("globalBanner");
        return res.items[0].fields;
    }
)
