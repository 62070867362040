import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography, styled } from '@mui/material';
import { loadOrderSummaryComponentContent } from "../../features/contentful/contentfulThunkApi";
import CustomCard from "../CustomCardComponent/CustomCard";
import { formattedPrice } from '../../utils/Helper';

const TypographyTitle = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`);

const TypographyList = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`);

const TypographyText = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`);

const CancelledReservationSummaryCard = ({totalDoses, listPrice, totalPrice}) => {
  const dispatch = useDispatch();
  const { orderSummaryComponentContent } = useSelector(store => store.contentful);
  const isRealTimePricingEnabled = process.env.IS_REAL_TIME_PRICING_ENABLED === 'true';

  useEffect(() => {
        if (orderSummaryComponentContent && Object.keys(orderSummaryComponentContent).length === 0) {
            dispatch(loadOrderSummaryComponentContent())
        }
        }, [orderSummaryComponentContent]
  );

  const SummaryCardListItem = ({ label, content, contentAlign = 'left' }) => (
    <Box display='flex' justifyContent='space-between' alignItems='center' width='100%' mb='8px'>
      <TypographyList variant='h5'>{label}</TypographyList>
      <TypographyList variant='p1' textAlign={contentAlign}>{content}</TypographyList>
    </Box>
  );

  return (
    <Box width={'486px'} height={'500px'}>
        <CustomCard sx={{display:'flex', flexDirection: 'column', padding: '32px', overflow: 'scroll'}} >
            <TypographyTitle mb='24px' variant='h3' color="#E22929">Order cancelled</TypographyTitle>
            <SummaryCardListItem label='Total Doses' content={totalDoses} />
            <SummaryCardListItem label='List Price' content={listPrice} />
            <SummaryCardListItem label='Contract Price' content={isRealTimePricingEnabled ? totalPrice : 'TBD'} />
            <SummaryCardListItem label='Shipping' content='TBD' />
            <Divider orientation="horizontal" variant="middle" sx={{ marginBottom: 2, marginTop: 1 }} />
            <Box display='flex' justifyContent='space-between' alignItems={'center'} width='100%' mb='8px'>
                <TypographyTitle variant='h5'>Total</TypographyTitle>
                <TypographyTitle variant='p1' textAlign={'right'}>{formattedPrice(totalPrice)}</TypographyTitle>
            </Box>

            <TypographyText mt={2} mb={3}>Contract pricing will be available soon. We will notify you via email about pricing and other updates to your reservation.</TypographyText>
        </CustomCard>
    </Box>
  )
}

export default CancelledReservationSummaryCard