import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Box, Grid, TextField, Typography } from '@mui/material'
import { TypographyP1 } from '../../../assets/fonts/typographyTheme'
import { AlertMessage, Button, Spinner } from '../../../components'
import Dropdown from '../Dropdown';
import { FormLabel, RequiredSymbol, formatAddressOptions, helperTextStyles, validateDEA, validateHIN, validateNPI, validateRequiredField } from '../Helper';
import crossIcon from '../../../assets/images/crossIcon.svg'
import { MEDICAL_IDENTIFIERS } from '../../../constants';
import { getSAPUserByEmail, getUserByEmail, updateUserDetailsApi } from '../../../utils/UserService/UserApis';
import { setIsOnboardingSuccessful, setUserStatus } from '../../../features/globalStates/globalStatesSlice';

const MedicalIdentificationForm = () => {
    const defaultFormFields = {
        addressOrgId: '',
        medicalIdentifier: '',
        type: '',
    };

    const alertRef = useRef(null);
    const dispatch = useDispatch()
    const history = useHistory()
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const userData = useSelector((store) => store?.commerce?.userData);
    const userId = useSelector((store) => store?.commerce?.userData?.userId) || '';
    const locale = useSelector((state) => state.globalStates.locale);

    const [rowsData, setRowsData] = useState([defaultFormFields])
    const [errors, setErrors] = useState([{}])
    const [formLevelErrors, setFormLevelErrors] = useState({})
    const [errorMsg, setErrorMsg] = useState('')
    const [processing, setProcessing] = useState(false);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [userAddresses, setUserAddresses] = useState([])
    const requiredFields = ['addressOrgId', 'type', 'medicalIdentifier']
    const typeOptions = [
        {
            label: "HIN",
            value: "HIN",
        },
        {
            label: "DEA",
            value: "DEA"
        },
        {
            label: "NPI",
            value: "NPI"
        }
    ]

    useEffect(() => {
        setUserAddresses(formatAddressOptions(userData?.addresses))
    },[])

    const handleAddMedicalIdentifierRow = () => {
        setRowsData([...rowsData, defaultFormFields]);
        setErrors([...errors, {}])
    };

    const handleRemoveRow = (index) => {
        const newData = rowsData.filter((item, idx) => idx !== index);
        const newErrors = errors.filter((item, idx) => idx !== index);
        setRowsData(newData);
        setErrors(newErrors)
    };

    const updateRowData = (index, name, value) => {
        const newData = [...rowsData];
        newData[index][name] = value;
        setRowsData(newData);
    };

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();
        updateRowData(index, name, value === '' ? '' : trimmedValue);
    };

    const handleBlur = (event, index) => {
        const { name, value } = event.target;
        const newErrors = [...errors]
        newErrors[index][name] = validateField(name, value, index)
        setErrors(newErrors)
    };

    const handleAddressSelection = (event, index) => {
        const { value } = event.target
        updateRowData(index, 'addressOrgId', value)

        const newErrors = [...errors]
        newErrors[index]['addressOrgId'] = ""
        setErrors(newErrors)
    };
    
    const handleTypeSelection = (event, index) => {
        const { value } = event.target
        updateRowData(index, 'type', value)

        const newErrors = [...errors]
        newErrors[index]['type'] = ""

        if (rowsData[index]['medicalIdentifier']) {
            newErrors[index]['medicalIdentifier'] = validateField('medicalIdentifier', rowsData[index]['medicalIdentifier'], index)
        }

        if (value === MEDICAL_IDENTIFIERS.HIN || value === MEDICAL_IDENTIFIERS.DEA) {
            setFormLevelErrors({noHinOrDea: ''})
        }
    };

    const validateField = (name, value, index) => {
        let errorMsg = "";
        if (requiredFields.includes(name) && String(value).trim() === '') {
            errorMsg = validateRequiredField(value)
        } else if (name === "medicalIdentifier" && value) {
            let medicalIdentifierType = rowsData[index]['type']
            if (medicalIdentifierType === MEDICAL_IDENTIFIERS.DEA) {
                errorMsg = validateDEA(value)
            } else if (medicalIdentifierType === MEDICAL_IDENTIFIERS.NPI) {
                errorMsg = validateNPI(value)
            } else {
                errorMsg = validateHIN(value)
            }
        }
        return errorMsg;
    };

    const validateFormData = () => {
        let isFormValid = false;
        let allRowsValidOrEmpty = true;
        let hasHinOrDea = false

        const newErrors = rowsData.map((row, index) => {
            const rowErrors = {};
            let isRowEmpty = true; // Assume the row is empty until a non-empty field is found
            let isRowValid = true; // Assume the row is valid until an error is found
    
            Object.keys(row).forEach((fieldName) => {
                if (String(row[fieldName]).trim() !== '') {
                    isRowEmpty = false;
                }
                const errorMsg = validateField(fieldName, row[fieldName], index);
                if (errorMsg) {
                    rowErrors[fieldName] = errorMsg
                    isRowValid = false
                }
                // check if at least one of the rows must have type HIN or DEA
                if (fieldName === 'type') {
                    if (row[fieldName] === MEDICAL_IDENTIFIERS.HIN || row[fieldName] === MEDICAL_IDENTIFIERS.DEA) {
                        hasHinOrDea = true
                        setFormLevelErrors({noHinOrDea: ''})
                    }
                }
            })

            // Row must be either completely valid or completely empty to be valid
            if (!isRowEmpty && !isRowValid) {
                allRowsValidOrEmpty = false;
            }

            // Check if any row is completely filled and valid for submission
            if (isRowValid && !isRowEmpty && hasHinOrDea) {
                isFormValid = true;
            }
    
            return rowErrors;
        });

        if (isFormValid && allRowsValidOrEmpty) {
            return true
        } else {
            if (!hasHinOrDea) {
                setFormLevelErrors({noHinOrDea: 'You must enter at least one HIN or DEA.'})
            }
            setErrors(newErrors);
            return false
        }
    };

    const getOrgLicensesForPayload = (rowsData) => {
        return rowsData.filter(row =>
            row.medicalIdentifier.trim() !== '' || 
            row.addressOrgId !== '' ||
            row.type.trim() !== ''
        ).map(row => ({
            "value": row.medicalIdentifier,
            "organizationId": row.addressOrgId,
            "type": row.type,
        }));
    }

    const handleSubmit = async () => {
        const updateUserRequestBody = {
            "userId": userId,
            "updateType": "identification",
            "orgLicenses": getOrgLicensesForPayload(rowsData)
        };

        if (validateFormData()) {
            try {
                setProcessing(true);
                const response = await updateUserDetailsApi(updateUserRequestBody);
                if (response?.status === 200) {
                    await dispatch(getUserByEmail({emailAddress: response?.data?.email})).unwrap();
                    dispatch(getSAPUserByEmail({ emailAddress: response?.data?.email})).unwrap();
                    dispatch(setIsOnboardingSuccessful(true));
                    dispatch(setUserStatus(response?.data?.userStatus));
                    history.push(`/${locale}/onboarding/success`);
                    setRowsData([defaultFormFields]);
                    setFormLevelErrors({...formLevelErrors, noHinOrDea: ''});
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message;
                setErrorMsg(errMsg || generalApiError);
                alertRef.current?.openAlert(error);
            } finally {
                setProcessing(false);
            }
        }
    };

    const textFieldStyles = (name, index) => {
        return {
            "& input": {
                fontFamily: 'Aeonik Regular',
                padding: "12px",
                height: '24px',
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[index][name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
                '&.Mui-focused fieldset': {
                    border: errors[index][name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
            },
        }
    }

    const createTextField = (name, label, value, onChange, onBlur, isRequired, index) => {
        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(name, index)}
                    error={Boolean(errors[index][name])}
                    helperText={errors[index][name]}
                    FormHelperTextProps={helperTextStyles}
                    inputProps={{
                        'data-testid': `${name}-input`
                    }}
                />
            </>
        )
    }

    const FormRow = (index) => {
        const formRow = rowsData[index];
        const showLabels = index === 0;

        return (
            <Grid key={index} container data-testid='medicalIdentificationRow' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginTop={'1px'}>
                <Grid item xs={12} sm={6} paddingBottom={'1px'} marginTop={showLabels ? '' : '7px'}>
                    <Dropdown
                        dataTestId={"addressDropdownSelection"}
                        name={"addressOrgId"}
                        label={showLabels ? "Address" : ""}
                        options={userAddresses}
                        value={formRow.addressOrgId}
                        onChangeHandler={(e) => handleAddressSelection(e, index)}
                        isRequired={true}
                        errors={errors[index]}
                    />
                </Grid>
                <Grid item xs={12} sm={2.5} paddingBottom={'1px'} marginTop={showLabels ? '' : '7px'}>
                    <Dropdown
                        dataTestId={"identifierTypeDropdownSelection"}
                        name={"type"}
                        label={showLabels ? "Type" : ""}
                        options={typeOptions}
                        value={formRow.type}
                        onChangeHandler={(e) => handleTypeSelection(e, index)}
                        isRequired={true}
                        errors={errors[index]}
                    />
                </Grid>
                <Grid item xs={2.5} paddingBottom={'1px'}>
                    {createTextField(
                        'medicalIdentifier',showLabels ? 'Number' : '', formRow.medicalIdentifier, 
                        (e) => {handleChange(e, index)}, (e) => {handleBlur(e, index)}, showLabels && true, index
                    )}
                </Grid>
                <Grid item xs={1} marginTop={showLabels ? 5: 2}>
                    {rowsData.length > 1 && (
                        <img width='30px' alt="crossIcon" src={crossIcon} onClick={() => handleRemoveRow(index)} data-testid='crossicon' style={{ cursor: 'pointer' }} />
                    )}
                </Grid>
            </Grid>
        )

    }


    return (
        <>
            <Spinner processing={processing}/>
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <Box flexDirection='column' width='100%' maxWidth='680px'>
                <Box mt={4}>
                    <TypographyP1>
                        Enter the HIN, DEA, and NPI numbers most closely associated with your Contract Affiliations (GPO or PBG). 
                        We use these numbers to locate and validate your account and pricing.
                    </TypographyP1>
                </Box>
                <Box sx={{ mt: 2, pl: 2 }}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><TypographyP1>Do not enter numbers associated with individual practitioners.</TypographyP1></li>
                        <li><TypographyP1>Enter multiple numbers for best results.</TypographyP1></li>
                    </ul>
                </Box>
                <Box mt={2} mb={3}>
                    <TypographyP1><RequiredSymbol>*</RequiredSymbol> At least one HIN or DEA number is required</TypographyP1>
                </Box>
                {/* form section */}
                {rowsData.map((rowData, index) => FormRow(index))}
                <Box>
                    <Button
                        sx={{ textTransform: "none" }}
                        data-testid='addMedicalIdentificationButton'
                        buttonType="link"
                        onClick={handleAddMedicalIdentifierRow}
                    >
                        Add another HIN or DEA number
                    </Button>
                </Box>
                <Box mt={4}>
                    {formLevelErrors.noHinOrDea && <Typography textAlign={'left'} color={'#E22929'} fontFamily={'Aeonik Regular'}>{formLevelErrors.noHinOrDea}</Typography>}
                </Box>
                <Box mt={2}>
                    <Button
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        data-testid='submitMedicalIdentificationButton'
                        buttonType="mds-primary"
                        onClick={handleSubmit}
                    >
                        Continue
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default MedicalIdentificationForm