import * as space from './space';
import * as entry from './entry';
import * as asset from './asset';
import * as assetKey from './asset-key';
import * as contentType from './content-type';
import * as locale from './locale';
import * as tag from './tag';
export default {
  space,
  entry,
  asset,
  assetKey,
  contentType,
  locale,
  tag
};