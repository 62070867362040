import { forwardRef, useImperativeHandle, useState } from 'react';
import Alert from '@mui/material/Alert';
import { styled } from "@mui/material/styles";
import CheckIcon from "../../assets/images/alertCheckIcon.svg";
import alertIcon from "../../assets/images/alertIcon.svg";
import crossIconWhite from "../../assets/images/crossIconWhite.svg"
import Slide from '@mui/material/Slide';
import { Box, IconButton, Typography } from '@mui/material';


const CustomAlert = styled(Alert)({
  fontFamily: "Aeonik Bold",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "8px",
  justifyContent: "center",
  fontWeight: 600,
  left: "20%",
  width: "60%",
  zIndex: 999999,
  margin: "auto",
  position: "fixed",
  top: "10%",
  minHeight: "60px",
  padding: "10px 50px"
});

const CheckIconElement = styled('img')`
  display: flex;
  align-items: center;
  object-fit: contain;
  width: 40px;
`

export const AlertMessage = forwardRef(({ type, variant, message, color = "", ...props }, ref) => {
  const [open, setOpen] = useState(false);
  const timeout = message?.length <= 100 ? 5000 : message?.length * 50;

  const handleOpenAlert = () => {
    setOpen(true)
    setTimeout(() => {
      setOpen(false);
    }, timeout);
  }

  const handleCloseAlert = () => setOpen(false);
  
  useImperativeHandle(ref, () => ({
    openAlert: handleOpenAlert,
    closeAlert: handleCloseAlert
  }))

  return (
      <Slide direction="down" in={open}>
        <CustomAlert
          icon={type === "info" ? <CheckIconElement data-testid="child" src={CheckIcon} /> :
            <CheckIconElement data-testid="child" src={alertIcon} />}
          severity={type}
          variant={variant}
          color={color}
          {...props}
        >
          {props.customMessage ? props.customMessage :
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant={"h4"} color={'neutral.white'}>
                {message}
              </Typography>
              <IconButton id="crossIcon" onClick={handleCloseAlert} data-testid="closeBtn">
                <img alt='x' src={crossIconWhite} data-testid='crossIcon' height="30px" width="30px" />
              </IconButton>
            </Box>
          }
        </CustomAlert>
      </Slide>
  )
})
