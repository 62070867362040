import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../Button";
import { Container, Grid, Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { TextInput } from "../TextInput";
import { AlertMessage } from "../AlertMessage";
import { SelectDropdown } from "../SelectDropdown";
import { validateFields } from "../../utils/Helper";
import { COMPANY_NAME, FULL_NAME, LINE_1, LINE_2, CITY, STATE, ZIP_CODE, EMAIL, PHONE } from "../../constants";
import { loadAddressComponent } from "../../features/contentful/contentfulThunkApi";
import { createAddressApi } from "../../utils/AddressesApi";
import { fetchUserApi, getAddressesApi } from "../../features/commerce/commerceThunkApi";
import { ModalComponent } from "../ModalComponent";
import { commonAddressValidationErrorMessage } from "../../utils/commonUtils";
import { useUserInfoContext } from "../../contexts";

const RequiredSymbol = styled('span')({
    color: 'red',
  });

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  width: 100%;
  ${[breakpoints.down('sm')]} {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
`);

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    padding: {xs: '0', sm:'0'},
    gap: {xs: '0', sm:'10px'},
    display:"flex",
    justifyContent:'left',
    marginBottom:"20px",
    marginTop:"20px"
  }

  const defaultFields = {
    fullName: '',
    companyName: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
    phone: '',
}

const requiredFields = [FULL_NAME, LINE_1, CITY, STATE, ZIP_CODE]
const rowWidth = { LARGE: 12, MEDIUM: 6, SMALL: 4, XSMALL: 3}

const getValidationErrorMessage = (label) => {
    if (label.toLowerCase().includes('email')) return commonAddressValidationErrorMessage.Email;
    if (label.toLowerCase().includes('phone')) return commonAddressValidationErrorMessage.Phone;
    
    return commonAddressValidationErrorMessage[label] || `This field is required.`;
}

export const NewAddressForm = ({ closeModal, handleNewAddresses}) => {
    const [inputFields, setInputFields] = useState(defaultFields);

    const [errors, setErrors] = useState({
        fullName: false,
        line1: false,
        city: false,
        state: false,
        zipcode: false,
    });
    const dispatch = useDispatch();
    const { addressComponent: addressContent } = useSelector((store) => store?.contentful);
    const {deaLicense: dea, hin} = useSelector((store) => store?.commerce?.commerceUser?.data?.orgUnit);
    const locale = useSelector((state) => state.globalStates.locale);
    const country = locale.split('-')[1];

    const [errorMsg, setErrorMsg] = useState('');
    const [alertInfo, setAlertInfo] = useState({ visible: false, type: null, message: '' });
    const alertRef = useRef(null);

    const { userInfo } = useUserInfoContext() || '';
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

    useEffect(() => {
        if (addressContent && Object.keys(addressContent).length === 0)
          dispatch(loadAddressComponent("Address Component - Reservation"));
      }, [addressContent, dispatch]);

    const allStates = addressContent?.USStates?.map((state) => {
        return({
            "value": state,
            "label": state,
        })
    })

    const validate = (fieldType, value) => {
        const requireRegexCheck = [EMAIL, PHONE, ZIP_CODE];

        if (requiredFields.includes(fieldType) && !value) {
            return false;
        }

        if (requireRegexCheck.includes(fieldType) && value) {
            return validateFields(fieldType, value);
        }
        
        return true
    }
    
    const handleChange = (value, key) => {
        setInputFields({...inputFields, [key]: value });
        setErrors({...errors, [key]: !validate(key, value) });
    };

    const allErrorsResolved = Object.values(errors).every(v => v === false);
    const requiredFieldsFilled = requiredFields.every((key) => inputFields[key] !== '');

    const showAlert = (type, message) => {
        setAlertInfo({ visible: true, type, message });
        alertRef.current?.openAlert();
    };

    const handleSubmit = async () => {
        try {
            let addressBody = {
                hin: hin,
                deaLicense: dea,
                addresses: [
                    {
                        companyName: inputFields?.companyName,
                        contactName: `${inputFields?.fullName}`,
                        line1: inputFields?.line1,
                        line2: inputFields?.line2,
                        town: inputFields?.city,
                        region: {
                            isocode: `${country}-${inputFields?.state}`
                        },
                        postalCode: inputFields?.zipcode,
                        country: {
                            isocode: country
                        },
                        email: inputFields?.email,
                        phone: inputFields?.phone 
                    }
                ],  
            }
            const response = await createAddressApi(addressBody);
            if (response?.status === 201) {
                let userId = userInfo?.uid;
                handleNewAddresses && handleNewAddresses(response.data?.addresses)
                showAlert('success', "Address was added successfully.");
                setInputFields(defaultFields);
                dispatch(getAddressesApi({ userId }))
                .unwrap()
                .then((addressResponse) => {
                    if (addressResponse?.data) {
                        closeModal && closeModal();
                    }
                })
                .then(
                    dispatch(fetchUserApi({userId: userId}))
                    .unwrap()
                    .then((user) => {
                        if (user?.data) {
                            localStorage.setItem("currentUser", JSON.stringify(user?.data));
                        }
                    })
                )
            }
        } catch(error) {
            setErrorMsg(generalApiError);
            showAlert('error', errorMsg);
        }
    }

    const renderRows = (label, key, placeholder, rowWidth, required=true) => {
        return (
            <Grid item xs={12} sm={rowWidth} paddingBottom={'1px'} key={key} gap={'24px'} >
                <StyledTextInput
                    required={required}
                    label={label}
                    inputType="text"
                    variant="standard"
                    value={inputFields[key]}
                    focused
                    placeholder={placeholder}
                    error={errors[key]}
                    helperText={errors[key] && getValidationErrorMessage(label)}
                    onChange={(event) => {
                        handleChange(event.target.value, key)
                    }}
                    stylevariant={"reservationAddressForm"}
                />
            </Grid>
        )
    }

    const renderDropdown = (label, key, options, rowWidth) => {
        return (
            <Grid item xs={12} sm={rowWidth} paddingBottom={'1px'} key={key} gap={'24px'} >
                <SelectDropdown
                    className="newAddressForm"
                    required
                    inputLabelSx={{ fontSize: "20px" }}
                    label={label}
                    placeholder={{id: "State", value: addressContent?.statePlaceholder}}
                    options={options}
                    value={inputFields[key] || "State"}
                    error={errors[key]}
                    helperText={errors[key] && getValidationErrorMessage(label)}
                    onChangeHandler={(value) => handleChange(value, key)}
                    sx={{ mb:"7px"}}
                />
            </Grid>
        )
    }

    return (
        <>
        {alertInfo.visible && <AlertMessage data-testid='alert' variant={"filled"} 
                    type={alertInfo.type}
                    message={alertInfo.message}
                    color={alertInfo.type === 'success' ? 'info' : undefined}
                    sx={{ top: 120}} ref={alertRef} />}
        
        <Container data-testid='addAddressContainer'>
            <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'}>
                <RequiredSymbol>* </RequiredSymbol>
                <Typography variant={"p1"} textAlign={'left'} fontFamily="Aeonik Regular">Required field</Typography>
            </Box>
            <Grid container rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="center">
                {renderRows(addressContent?.companyName, COMPANY_NAME, addressContent?.companyNamePlaceholder, rowWidth.LARGE, false)}
                {renderRows(addressContent?.fullName, FULL_NAME, addressContent?.fullNamePlaceholder, rowWidth.LARGE)}
                {renderRows(addressContent?.accountEmailAddress, EMAIL, addressContent?.emailPlaceholder, rowWidth.MEDIUM, false)}
                {renderRows(addressContent?.accountPhoneNumber, PHONE, addressContent?.phonePlaceholder, rowWidth.MEDIUM, false)}
                {renderRows(addressContent?.addressLine1, LINE_1, addressContent?.line1Placeholder, rowWidth.LARGE)}
                {renderRows(addressContent?.addressLine2, LINE_2, addressContent?.line2Placeholder, rowWidth.LARGE, false)}
                {renderRows(addressContent?.city, CITY, addressContent?.cityPlaceholder, rowWidth.MEDIUM)}
                {renderDropdown(addressContent?.state, STATE, allStates, rowWidth.XSMALL)}
                {renderRows(addressContent?.zipCode, ZIP_CODE, addressContent?.zipCodePlaceholder, rowWidth.XSMALL)}
            </Grid>
            <Grid sx={buttonsContainerSxStyles}>
                <Button id="submit" 
                    sx={{ marginTop: "32px", marginBottom: {xs:'46px', sm: "0"} }} 
                    onClick={handleSubmit}
                    data-testid='submitBtn'
                    disabled={!requiredFieldsFilled || !allErrorsResolved }>
                    {addressContent?.useThisAddressLabel}
                </Button>
            </Grid>
        </Container>
        </>
    )
}

export const NewAddressFormModal = ({openModal, closeModal, handleNewAddresses}) => {
    return (
        <ModalComponent 
            title='New Address'
            openModal={openModal}
            closeModalHandler={closeModal} 
            modalContent={<NewAddressForm closeModal={closeModal} handleNewAddresses={handleNewAddresses} />}
            style={{
                '#titleContainer':{
                    marginBottom: '0px',
                    padding: '5px'
                },
            }}
            />
    )
}
