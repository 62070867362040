import { useEffect, useRef, useState } from "react";
import { LicenseInfo } from "./LicensingInfo";
import { AccountDetails } from "./AccountDetails";
import { AddressesDetails } from "./AddressesDetails";
import { ContactInfo } from "./ContactInfo";
import { GroupAffiliations } from "./GroupAffiliations";
import { CommunicationPreferences } from "./CommunicationPreferences";
import { Accordion, Button, Checkbox, Spinner, AlertMessage } from "../";
import { INLINES } from '@contentful/rich-text-types';
import AccountIcon from "../../assets/images/MyProfile/profileIcon.svg";
import GroupIcon from "../../assets/images/MyProfile/Group_Affiliations_Icon.svg";
import AddressesIcon from "../../assets/images/MyProfile/Addresses_Icon.svg";
import CreditCardIcon from '../../assets/images/MyProfile/CreditCardIcon.svg'
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useUserInfoContext } from "../../contexts";
import { updateUserApiAuth } from "../../utils/commerceUsersApi";
import { fetchUserApi } from "../../features/commerce/commerceThunkApi";
import { useDispatch, useSelector } from 'react-redux';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Grid, Typography, useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MessageBanner from "../MessageBanner/MessageBanner";
import PaymentMethodsSection from "./PaymentMethodsSection";

const ProfileWrapper = styled('div')(({ theme: { breakpoints } }) => `
    height: 800px;
    display: flex;
    ${[breakpoints.down('sm')]} {
        flex-direction: column;
        height: auto;
    }
`);

const NavigationPanel = styled('div')(({ theme: { palette: { primary }, breakpoints }}) => `
    background-color: ${primary.main};
    height: auto;
    min-height: 800px;
    max-width: 400px;
    flex: 0 0 auto;
    position: relative;
    ${[breakpoints.down('sm')]} {
        width: 100%;
        padding: 40px;
        min-height: 80px;
    }
`);

const NavigationPanelLink = styled('a')(({isActive, theme: { palette: { neutral } }}) => `
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: ${neutral.white};
    text-decoration: none;
    cursor: pointer;
    background-color:${ isActive ? '#225D84' : 'transparent'};
    padding:6px 60px;
`);

const NavIcon = styled('img')`
    margin-right: 10px;
`;

const AccountInfo = styled('div')`
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    scroll-behavior: smooth;
    &&::-webkit-scrollbar{
        display: none;
    }
`;

const StyledLink = styled(Typography)(({ theme: { palette: {secondary}, breakpoints } }) => `
  color: ${secondary.textLinks};
  ${[breakpoints.down('sm')]} {
    padding-top:20px;
  } 
`);

const EditButtons = styled('div')(({ theme: { breakpoints } }) => `
    display: flex;
    ${[breakpoints.down('sm')]} {
        flex-direction: column;
        width: 80%;
    }
`);

const GPOContainer = styled('div')(({ theme: { palette: { secondary, neutral} }}) => `
    background-color: ${secondary.main};
    border-radius: 16px;
    padding: 10px 26px;
    margin: 40px 0;
    &&.edit {
        box-shadow: 0px 3px 6px #22222229;
        &&.false {
            background-color: ${neutral.gray5};
        }
    }
`);

const DesclaimerText = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  font-size:14px !important;
  font-family:Aeonik Regular !important;
  color:${primary.main};
  ${[breakpoints.down('sm')]} {
    padding-left: 16px;
  } 
`);

const DisclaimerTextContainer= styled(Grid)(() => `
  padding-left:0px !important;
`);

const CheckboxContainer= styled(Grid)(() => `
  padding-top:10px !important;
`);

const ErrorText=styled('p')(({ theme: { palette: { status } }}) => `
  margin-top: 45px;
  margin-bottom: -35px;
  color:${status.error};
`);

const accordionCustomStyle = {
    borderRadius: "0px !important",
    boxShadow: "none",
    border: "1px solid grey !important",
    margin: "0px !important",
    backgroundColor: "primary.main",
    color:"white",
    width:"100%",
    "& .MuiTypography-h5": {
        color:"white",
        display:"flex",
        alignItems: 'center'
    },
    "& .Mui-expanded": {
        backgroundColor: "#225D84",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        marginRight:"40px"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight:"48px"
    },
    "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
        margin:"0px 0px 0px 40px",
    }
    
};

const ProfileComponent = ({ staticContent, staticJobRoles, staticJobTitles }) => {
    const dispatch = useDispatch();
    const isAddAddressEnabled = process.env.IS_ADD_ADDRESS_ENABLED;
    const isPaymentsEnabled = process.env.IS_PAYMENTS_ENABLED === 'true';
    const basePaths = ['addresses', 'affiliations', 'account'];
    const possiblePaths = isPaymentsEnabled ? [...basePaths, 'payment-methods'] : basePaths;
    const initialNavLink = possiblePaths.find(path => window.location.pathname.includes(path)) || 'account';
    const [errorMsg, setErrorMsg] = useState('');
    const [navigationLink, setNavigationLink] = useState(initialNavLink);
    const [loader, setLoader] = useState(false);
    const { userInfo, setUserInfo } = useUserInfoContext() || '';
    const [pageType, setPageType] = useState('readonly');
    const [gpoPageType, setGPOPageType] = useState('readonly');
    const [defaultAccount, setDefaultAccount] = useState(userInfo?.orgUnit?.defaultGPO);
    const [selectedAccount, setSelectedAccount] = useState(defaultAccount?.gpoName);
    const [jobTitle, setJobTitle] = useState(userInfo?.jobTitle);
    const [contactRole, setContactRole] = useState(userInfo?.position);
    const [consentChecked, setConsentChecked] = useState(userInfo?.optin);
    const accounts = userInfo?.orgUnit?.gpoDetails || [];
    const [isSaved, setIsSaved] = useState(false);
    const [isGPOSaved, setIsGPOSaved] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [gpoSaveButtonDisabled, setGpoSaveButtonDisabled] = useState(true);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory()
    const { commerceUser } = useSelector((store) => store?.commerce);
    const addresses = isAddAddressEnabled === 'true' ? 
        (commerceUser?.data?.orgUnits?.flatMap(orgUnit => orgUnit?.addresses) || [] ) 
        : (commerceUser?.data?.orgUnit?.addresses || []);

    const [isGpoChanged, setIsGpoChanged] = useState(false);
    const [isAknowledge, setIsAknowledge] = useState(true);
    const [showErrorText, setShowErrorText] = useState(false);
    const isGPOEnabled = process.env.IS_GPO_ENABLED;

    const alertRef = useRef(null)
    const successAlertRef = useRef(null)
    
    const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const setDefaultAddressApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "SET_DEFAULT_ADDRESS_API_ERROR");
    const setDefaultAddressApiError = setDefaultAddressApiErrorData && setDefaultAddressApiErrorData[0]?.fields?.message;
    const sapUserData = useSelector(store => store?.commerce?.sapUserData?.data);
    const isRegistrationAndOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
    const isEmployee = () => localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined";
    const isNewOrderingEnabled = process.env.IS_NEW_ORDER_EXPERIENCE_ENABLED === 'true';
    const canOrder2023Products = useSelector((state) => state.globalStates?.canOrder2023Products);

    const locale = useSelector((state) => state.globalStates.locale);
    useEffect(() => {
        const loadData = async () => {
            try {
                dispatch(fetchUserApi({ userId: userInfo?.uid }))
                .unwrap()
                .then((userData) => {
                    setUserInfo(userData?.data)
                    localStorage.setItem("currentUser", JSON.stringify(userData?.data));
                })
            } catch (error) {
                console.error(error)
            }
        }
        if (!localStorage.getItem("currentUser") || localStorage.getItem("currentUser") === "undefined")
            loadData();
    }, [userInfo, setUserInfo, dispatch])

    const resetUserInfo = () => {
        setJobTitle(userInfo?.jobTitle)
        setContactRole(userInfo?.position)
        setDefaultAccount(userInfo?.orgUnit?.defaultGPO)
        setSelectedAccount(userInfo?.orgUnit?.defaultGPO?.gpoName)
        setConsentChecked(userInfo?.optin)
    }

    const resetGPOInfo = () => {
        setDefaultAccount(userInfo?.orgUnit?.defaultGPO)
        setSelectedAccount(userInfo?.orgUnit?.defaultGPO?.gpoName)
    }

    
    const toggleEdit = () => {
        setPageType(prev => prev === 'readonly' ? 'edit' : 'readonly');
        setSaveButtonDisabled(true);
        setShowErrorText(false);
    }
    const toggleGPOEdit = () => {
        setGPOPageType(prev => prev === 'readonly' ? 'edit' : 'readonly');
        setGpoSaveButtonDisabled(true);
        setIsGpoChanged(false);
        setShowErrorText(false);
    }

    const discardChanges = () => {
        resetUserInfo();
        toggleEdit();
        setSaveButtonDisabled(true);
    }

    const gpoDiscardChanges = () => {
        resetGPOInfo();
        toggleGPOEdit();
        setGpoSaveButtonDisabled(true);
    }

    const saveChanges = async () => {
        const updatedUserInfo = {
            "jobTitle": (jobTitle === staticContent?.notFoundText ? "" : jobTitle),
            "position": (contactRole === staticContent?.notFoundText ? "" : contactRole),
            "optin": consentChecked
        };
        setLoader(true);
        try {
            const resp = await updateUserApiAuth(userInfo?.uid, updatedUserInfo);
            if (resp?.status === 200) {
                dispatch(fetchUserApi({ userId: userInfo?.uid }))
                .unwrap()
                .then((userData) => {
                    setUserInfo(userData?.data);
                    localStorage.setItem("currentUser", JSON.stringify(userData?.data));
                    setLoader(false);
                    setIsSaved(true);
                    setTimeout(() => setIsSaved(false), 6000);
                    toggleEdit();
                })
            }
        } catch (err) {
            setErrorMsg(generalApiError);
            setLoader(false);
            alertRef.current?.openAlert()
        } finally {
            setLoader(false);
        }
    }

    const gpoSaveChanges = async () => {
        const updatedUserInfo = {
            "orgUnit": {
                "defaultGPO": defaultAccount
            }
        };
        setLoader(true);
        try {
            if(isGpoChanged && !isAknowledge){
                setShowErrorText(true);
                setGpoSaveButtonDisabled(true);
                return;
            }
            const resp = await updateUserApiAuth(userInfo?.uid, updatedUserInfo);
            if (resp?.status === 200) {
                dispatch(fetchUserApi({ userId: userInfo?.uid }))
                .unwrap()
                .then((userData) => {
                    setUserInfo(userData?.data);
                    localStorage.setItem("currentUser", JSON.stringify(userData?.data));
                    setLoader(false);
                    setIsGPOSaved(true);
                    setTimeout(() => setIsGPOSaved(false), 6000);
                    toggleGPOEdit();
                })
            }
        } catch (err) {
            setErrorMsg(generalApiError);
            setLoader(false);
            alertRef.current?.openAlert()
        } finally {
            setLoader(false);
        }
    }

    const handleSelectAccount = (account) => {
        setDefaultAccount(account)
        setSelectedAccount(account?.gpoName)
        setIsGpoChanged(account?.gpoName !== userInfo?.orgUnit?.defaultGPO?.gpoName);
        setIsAknowledge(false);
        if(account?.gpoName === userInfo?.orgUnit?.defaultGPO?.gpoName){
            setShowErrorText(false);
            setGpoSaveButtonDisabled(true)
        }
    }

    const aknowledgeHandler = (e) => {
        setIsAknowledge(e.target.checked)
        if(e.target.checked)
            setGpoSaveButtonDisabled(false);
        else
            setGpoSaveButtonDisabled(true);
        setShowErrorText(false);
    }

    const handleConsent = () => {
        setConsentChecked(prev => !prev)
        setSaveButtonDisabled(false)
    }

    useEffect(()=>{
      if(isSaved || isGPOSaved) successAlertRef.current?.openAlert()
    },[isSaved, isGPOSaved])

    const options = {
        renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
            return <Link to={{ pathname: `/${locale}${node.data.uri}` }} target='_blank'>
                        {node.content[0].value}
                    </Link>
          }
        },
    };

    const renderText = (content)=> {
        if (typeof content === 'string') {
            return content;
        }
        else if (typeof content === 'object' && content.nodeType) {
            return documentToReactComponents(content);
        }
        return null
    }

    const onDefaultAddressApiError = () => {
        setErrorMsg(setDefaultAddressApiError)
        alertRef.current?.openAlert()
    }

    const MessageBannerForAffiliation = (
        !canOrder2023Products && <Box width={'100%'}>
            <MessageBanner 
                type="info"
                bannerContentComponent={
                    (
                        <Typography>
                        To find your 2024 Contract Affiliation, please select the contract affiliation button on the <Link to={`/${locale}/order-form`}>Quick Order Form.</Link>
                        </Typography>
                    )
                }
            />
        </Box>
    )

    const errorMsgHandler = (errorMsg) => {
        let message = errorMsg || generalApiError;
        setErrorMsg(message);
        alertRef.current?.openAlert(message);
    }

    const AccountSection = () => {
        return (<>
            {MessageBannerForAffiliation}
            <Box display='flex' width='80%' margin='60px auto 0 auto' flexDirection={{xs:'column', sm:'row'}} justifyContent='space-between'>
                <Box width={{xs:'100%', sm:'60%'}} pb={{xs:'24px', sm:'unset'}}>
                    <Typography variant={'h2'} color='primary.main' textAlign='left' lineHeight='35px' id="headline">{staticContent?.headline}</Typography>
                    <Typography variant={'p1'} component="div" color='neutral.main' textAlign='left' lineHeight={{xs:'21px', sm:'24px'}}  m={{xs:'16px 0', sm:'unset'}} id="headlineMessage">
                        {renderText(staticContent?.message)}
                        <Link to={`/${locale}/contact-us`}>
                            <StyledLink variant={'link'}>{staticContent?.contactUsLinkLabel}
                            </StyledLink>
                        </Link>
                    </Typography>
                </Box>
                {!isRegistrationAndOnboardingEnabled && 
                    <>
                        {pageType === 'readonly' ? <Button id="editBtn" buttonType="secondary" sx={ {height: "38px", width: "106px"} } onClick={toggleEdit}>{staticContent?.editLinkLabel}</Button> : 
                        <EditButtons>
                            <Button buttonType="secondary" id="disacrdBtn" sx={ {height: "38px", textAlign: "center"} } onClick={discardChanges}>DISCARD CHANGES</Button>
                            <Button buttonType="primary" id="saveBtn" disabled={saveButtonDisabled} sx={ {height: "38px", marginLeft: {xs:"0", sm:"20px"}, textAlign: "center", marginTop: {xs:"10px", sm:"unset"}} } onClick={saveChanges}>SAVE CHANGES</Button>
                        </EditButtons>}
                    </>
                }
            </Box>
            {(!isEmployee() && isRegistrationAndOnboardingEnabled) ? 
                <ContactInfo
                    staticContent={staticContent}
                    staticJobRoles={staticJobRoles}
                    staticJobTitles={staticJobTitles}
                    pageType={pageType}
                    contactName={sapUserData?.name}
                    contactEmail={sapUserData?.email}
                    jobTitle={sapUserData?.jobTitle}
                    contactRole={sapUserData?.position}
                /> :
                <ContactInfo
                    staticContent={staticContent}
                    staticJobRoles={staticJobRoles}
                    staticJobTitles={staticJobTitles}
                    pageType={pageType}
                    contactName={userInfo?.name}
                    contactEmail={userInfo?.email}
                    jobTitle={jobTitle}
                    setJobTitle={setJobTitle}
                    contactRole={contactRole}
                    setContactRole={setContactRole}
                    setSaveButtonDisabled={setSaveButtonDisabled}
                />
            }
            <LicenseInfo
                staticContent={staticContent}
                userInfo={(!isEmployee() && isRegistrationAndOnboardingEnabled) ? sapUserData : userInfo}
                pageType={pageType}
                />
            <AccountDetails
                staticContent={staticContent}
                userInfo={(!isEmployee() && isRegistrationAndOnboardingEnabled) ? sapUserData : userInfo}
                pageType={pageType}
            />
            {!isRegistrationAndOnboardingEnabled && 
                <CommunicationPreferences
                    staticContent={staticContent}
                    userInfo={(!isEmployee() && isRegistrationAndOnboardingEnabled) ? sapUserData : userInfo}
                    pageType={pageType}
                    checked={consentChecked}
                    onChange={handleConsent}
                />
            }
        </>)
    }

    const handlePageSelection = (URL) => {
        setNavigationLink(URL)
        if(URL === 'account') history.push(`/${locale}/my-profile`)
        else history.push(`/${locale}/my-profile/${URL}`)
    }
    const GpoSection = () => {
        return (<Box margin='60px auto 0 auto' width='80%' id="GroupAffiliations">
                    <Box display='flex' flexDirection={{xs:'column', sm:'row'}} justifyContent='space-between'>
                        <Box width={{xs:'100%', sm:'60%'}} pb={{xs:'24px', sm:'unset'}} id="GroupAffiliations">
                            <Typography variant={'h2'} color='primary.main' textAlign='left' lineHeight='35px' id="GpoAffiliationsTitle">{isNewOrderingEnabled ? '2023 Contract Affiliation' : staticContent?.gpoAffiliationsSectionLabel}</Typography>
                            <Typography variant={'p1'} component="div" color='neutral.main' textAlign='left' lineHeight={{xs:'21px', sm:'24px'}}  m={{xs:'16px 0', sm:'unset'}} id="GpoAffiliationsMsg">
                                {renderText(staticContent?.gpoAffiliationsSectionMessage)}
                                <Link to={`/${locale}/contact-us`} id="GpoAffiliationsMsgLink">
                                    <StyledLink variant={'link'}>{staticContent?.contactUsLinkLabel}
                                    </StyledLink>
                                </Link>
                            </Typography>
                            <br/>
                            {isNewOrderingEnabled && <Typography> 2023 contract affiliations are for 2023 product pricing only. <br/> For 2024 contract affiliations, please select on the <Link to={`/${locale}/order-form`}>Quick Order Form.</Link></Typography>}
                        </Box>
                        {isGPOEnabled === "true" && accounts?.length > 1 && <Box>
                            {gpoPageType === 'readonly' ? <Button id="editBtn" buttonType="secondary" sx={ {height: "38px", width: "106px"} } onClick={toggleGPOEdit}>{staticContent?.editLinkLabel}</Button> : 
                            <EditButtons>
                                <Button buttonType="secondary" id="disacrdBtn" sx={ {height: "38px", textAlign: "center"} } onClick={gpoDiscardChanges}>DISCARD CHANGES</Button>
                                <Button buttonType="primary" id="saveBtn" disabled={gpoSaveButtonDisabled} sx={ {height: "38px", marginLeft: {xs:"0", sm:"20px"}, textAlign: "center", marginTop: {xs:"10px", sm:"unset"}} } onClick={gpoSaveChanges}>SAVE CHANGES</Button>
                            </EditButtons>}
                        </Box>}
                    </Box>

                    <GPOContainer id="GpoAffiliationsContainer" className={`${gpoPageType} ${isGPOEnabled}`}>
                        {accounts?.map((account, index) => {
                            return <GroupAffiliations
                                id={`gpo_${index}`}
                                key={index}
                                staticContent={staticContent}
                                userInfo={account}
                                businessType={account?.businessType}
                                selected={selectedAccount === account?.gpoName}
                                border={index + 1 < accounts.length}
                                onChange={() => isGPOEnabled === 'true' && handleSelectAccount(account)}
                                pageType={gpoPageType}
                                isDefault={selectedAccount === account?.gpoName}
                                singleAccount={accounts?.length === 1}
                                isExpanded={selectedAccount === account?.gpoName}
                                isEnabled={isGPOEnabled}
                        />})}
                    </GPOContainer>
                    {
                        isGpoChanged && gpoPageType === "edit" && <Grid container spacing={2} sx={{marginTop:"10px"}}>
                            <Grid item xs={12} md={1}>
                                <WarningAmberIcon sx={{color:"red", marginTop:"4px", fontSize:"20px"}}/>
                            </Grid>
                            <DisclaimerTextContainer item xs={12} md={7} lg={9}>
                                <DesclaimerText variant="disclaimerParagraph" m={0} display="block" gutterBottom>
                                    {documentToReactComponents(staticContent?.gpoChangeDisclaimerMessage, options)}
                                </DesclaimerText>
                            </DisclaimerTextContainer>
                            <CheckboxContainer item xs={12} md={3} lg={2}>
                                <Checkbox
                                    onChange={(e) => aknowledgeHandler(e)}
                                    name="acknowledge"
                                    value={isAknowledge}
                                    label={<>{staticContent?.acknowledgeText}<Typography variant={'span'} pl={'2px'} sx={{ color: "primary.red" }}>*</Typography></>}
                                />
                            </CheckboxContainer>
                        </Grid>
                    }
            </Box>
        )
    }

    const AddressesSection = () => {
        return (<>
            {MessageBannerForAffiliation}
            <Box display='flex' width='80%' margin='60px auto 0 auto' flexDirection={{xs:'column', sm:'row'}} justifyContent='space-between'>
                <Box width={{xs:'100%', sm:'60%'}} pb={{xs:'24px', sm:'unset'}}>
                    <Typography variant={'h2'} color='primary.main' textAlign='left' lineHeight='35px' id="headline">{staticContent?.addressesMenuLabel}</Typography>
                    <Typography variant={'p1'} component="div" color='neutral.main' textAlign='left' lineHeight={{xs:'21px', sm:'24px'}}  m={{xs:'16px 0', sm:'unset'}} id="headlineMessage">
                        {renderText(staticContent?.addressHeadlineMessage)}
                        <Link to={`/${locale}/contact-us`}>
                        <StyledLink variant={'link'}>{staticContent?.contactUsLinkLabel}
                        </StyledLink>
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <AddressesDetails staticContent={staticContent} addresses={(!isEmployee() && isRegistrationAndOnboardingEnabled) ? sapUserData?.orgUnit?.addresses : addresses} pageType={pageType} setLoader={setLoader} onDefaultAddressApiError={onDefaultAddressApiError}/>
            </>
        )
    }

    return(
        <ProfileWrapper>
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
            <AlertMessage type={"info"} message={"Changes saved"} variant={"filled"} ref={successAlertRef}/>
            <NavigationPanel id="navigationPanel" data-testid='navigationPanel'>
                <Box sx={{padding: desktop ? "60px 60px 0px 60px" : "0"}}>
                    <Typography variant={'h6'} width='100%' color='neutral.white' textTransform='uppercase' id="navigationPanelMsg" data-testid='navigationPanelMsg'>
                        {staticContent?.menuWelcomeMessage}
                    </Typography>
                    <Typography
                        variant={'h3'}
                        color='neutral.white'
                        marginBottom={{xs:'0', sm:'40px'}}
                        sx={{ overflowWrap:'break-word' }}
                        id="navigationPanelUser"
                        data-testid='navigationPanelUser'
                    >
                        {isRegistrationAndOnboardingEnabled ? sapUserData?.name : `${userInfo?.firstName} ${userInfo?.lastName}`}
                    </Typography>
                </Box>
                
                <Box display={{xs:'none', sm:'flex'}} flexDirection='column' id="navigationLinkPanel" data-testid="navigationLinkPanel">
                    <NavigationPanelLink isActive={navigationLink==="account"} id="accountDetailsLink" data-testid="accountDetailsLink" onClick={() => handlePageSelection('account')}><NavIcon src={AccountIcon} alt="account details" height="30" width="30" />{staticContent?.accountDetailsMenuLabel}</NavigationPanelLink>
                    {accounts?.length > 0 && <NavigationPanelLink isActive={navigationLink==="affiliations"} id="groupAffiliationsLink" onClick={() => handlePageSelection('affiliations')}><NavIcon src={GroupIcon} alt="group affiliations" height="30" width="30" />{isNewOrderingEnabled ? "2023 Contract Affiliation" : staticContent?.gpoMenuLabel}</NavigationPanelLink>}
                    <NavigationPanelLink isActive={navigationLink==="addresses"} id="addressesLink" data-testid="addressesLink" onClick={() => handlePageSelection('addresses')}><NavIcon src={AddressesIcon} alt="addresses" height="30" width="30" />{staticContent?.addressesMenuLabel}</NavigationPanelLink>
                    {isPaymentsEnabled && 
                        <NavigationPanelLink isActive={navigationLink==="payment-methods"} id="paymentMethodsLink" data-testid="paymentMethodsLink" onClick={() => handlePageSelection('payment-methods')}><NavIcon src={CreditCardIcon} alt="payment methods" height="30" width="30" />Payment Methods</NavigationPanelLink>
                    }
                </Box>
            </NavigationPanel>
            <Spinner processing={loader}/>
            <AccountInfo>
                { mobile && <>
                        <Accordion
                            id="acocuntAccordion"
                            defaultExpanded={true}
                            accordionCustomStyle={accordionCustomStyle}
                            heading={ <><NavIcon src={AccountIcon} alt="account details" height="30" width="30" /> {staticContent?.accountDetailsMenuLabel} </>}
                            description={<AccountSection/>}
                        />
                        {accounts?.length > 0 && 
                            <Accordion
                                id="gpoAccordion"
                                accordionCustomStyle={accordionCustomStyle}
                                heading={<><NavIcon src={GroupIcon} alt="group affiliations" height="30" width="30" />{staticContent?.gpoMenuLabel}</>}
                                description={<GpoSection/>}
                            />
                        }
                        <Accordion
                            id="addressesAccordion"
                            accordionCustomStyle={accordionCustomStyle}
                            heading={<><NavIcon src={AddressesIcon} alt="addresses" height="30" width="30" />{staticContent?.addressesMenuLabel}</>}
                            description={<AddressesSection/>}
                        />
                        {isPaymentsEnabled &&
                            <Accordion
                                id="paymentMethodsAccordion"
                                accordionCustomStyle={accordionCustomStyle}
                                heading={<><NavIcon src={CreditCardIcon} alt="payment methods" height="30" width="30" />Payment Methods</>}
                                description={<PaymentMethodsSection setProcessing={setLoader} handleErrorMsg={errorMsgHandler} />}
                            />
                        }
                </>}
                {desktop && <>
                        {navigationLink === 'account' && <AccountSection/>}
                        {accounts?.length > 0 && navigationLink === 'affiliations' && <GpoSection/>}
                        {navigationLink === 'addresses' && <AddressesSection/>}
                        {navigationLink === 'payment-methods' && <PaymentMethodsSection setProcessing={setLoader} handleErrorMsg={errorMsgHandler} />}
                </>}                
            </AccountInfo>
        </ProfileWrapper>
    )
}

export default ProfileComponent;