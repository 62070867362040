import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { Divider, Typography } from '@mui/material';
import CheckIcon from '../assets/images/check.svg'
import CrossIcon from '../assets/images/crossIcon.svg'
import { Button } from '../components';
import { loadDatesComponent } from '../features/contentful/contentfulThunkApi';
import { reservationConfirmationPageConstants as staticConstants } from '../assets/constants/ReservationConfirmationPageConstants';
import { setIsConfirmationPageAccessible } from '../features/globalStates/globalStatesSlice';
import './ReservationConfirmationPage.css'

const ReservationConfirmationPage = () => {
  const isOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
  const history = useHistory();
  const locale = useSelector((state) => state.globalStates.locale);
  const { commerceUser, userData } = useSelector((store) => store?.commerce);
  const { datesComponent: datesContent } = useSelector((store) => store?.contentful);
  const isConfirmationPageAccessible = useSelector((state) => state.globalStates.isConfirmationPageAccessible)
  const { reservation } = useSelector((store) => store?.commerce);
  const dispatch = useDispatch();
  const isNewReservationUrlsEnabled = process.env.IS_NEW_RESERVATION_URLS_ENABLED === 'true'
  const { reservationId } = useParams();
  const reservationNum = reservation?.reservation?.reservationNumber
  const isCancellation = isNewReservationUrlsEnabled ? reservation?.reservation?.reservationStatus === 'CANCELLED' : !!reservationId && reservationId === reservationNum

  useEffect(() => {
    if (datesContent && Object.keys(datesContent).length === 0) {
      dispatch(loadDatesComponent())
    }
  }, [datesContent, dispatch])

  useEffect(() => {
    if(!isConfirmationPageAccessible) {
      history?.push(`/${locale}/`);
    } else {
      dispatch(setIsConfirmationPageAccessible(false));
    }
  }, [history]);

  const ReservationCanceledConfirmation = () => {
    return (
      <>
        <img src={CrossIcon} alt='cross icon' className='crossIcon' height='56' width='56'/>
        <div className='InfoMsg' data-testid='infoMsg'>
          <Typography variant='h3'>{staticConstants.cancelConfirmationMsg(reservationNum)}</Typography>
        </div>
      </>
    )
  }

  const ReservationBookedConfirmation = () => {
    return (
      <>
        <img src={CheckIcon} alt='check icon' className='CheckIcon' height='56' width='56'/>
        <div className='ConfirmationMsgSection'>
          <p className='ConfirmationMsg'>{staticConstants?.thankYouMsg}{isOnboardingEnabled? `${userData?.firstName} ${userData?.lastName}` : commerceUser?.data?.name}.</p>
          <p className='ConfirmationMsg'>{!!reservationId? 'Your reservation has been updated.' : staticConstants?.confirmationMsg}</p>
        </div>
        <div className='InfoMsg' data-testid='infoMsg'>
          <Typography fontFamily="Aeonik Regular">{staticConstants?.pricingInfoMessage}</Typography>
          <Typography fontFamily="Aeonik Regular">{staticConstants?.editInfoMessage}</Typography>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='ReservationConfirmationPage'>
        <div className='ContentSection'>
          {isCancellation ? <ReservationCanceledConfirmation /> : <ReservationBookedConfirmation />}
          <Divider id="divider" orientation="horizontal" variant="middle" sx={{ width:"100%", maxWidth:"920px"}} />
          {!isCancellation && ( //Not showing the timeline if it's a cancellation
              <div className='TimelineSection'>
                <Typography fontFamily="Aeonik Regular" fontWeight="700" fontSize="18px">{staticConstants?.timelineHeader}</Typography>
                <img src={datesContent?.timelineImage?.fields?.file?.url} alt='timeline image' id='timelineImg' data-testid='timelineImg'/>
              </div>
          )}
        </div>
        <div className='ButtonsGroup'>
          <Button
            buttonType='mds-secondary'
            data-testid='newReservationButton'
            sx={{ padding: "16px 24px", textTransform: "none"}}
            onClick={() => history.push(`/${locale}/reservation`)}
          >
            {staticConstants?.newReservationButtonLabel}
          </Button>
          <Button
            buttonType='mds-primary'
            data-testid='viewOrdersButton'
            sx={{ padding: "16px 24px", textTransform: "none" }}
            onClick={() => history.push(`/${locale}/orders`)}
          >
            {staticConstants?.viewOrdersButtonLabel}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ReservationConfirmationPage