import { GRID_CHECKBOX_SELECTION_COL_DEF } from '../../../../colDef';
import { buildWarning } from '../../../../utils/warning';
function sanitizeCellValue(value, delimiterCharacter) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');

    // Make sure value containing delimiter or line break won't be split into multiple rows
    if ([delimiterCharacter, '\n', '\r'].some(delimiter => formattedValue.includes(delimiter))) {
      return `"${formattedValue}"`;
    }
    return formattedValue;
  }
  return value;
}
export const serializeCellValue = (cellParams, options) => {
  const {
    delimiterCharacter,
    ignoreValueFormatter
  } = options;
  let value;
  if (ignoreValueFormatter) {
    var _cellParams$value2;
    const columnType = cellParams.colDef.type;
    if (columnType === 'number') {
      value = String(cellParams.value);
    } else if (columnType === 'date' || columnType === 'dateTime') {
      var _cellParams$value;
      value = (_cellParams$value = cellParams.value) == null ? void 0 : _cellParams$value.toISOString();
    } else if (typeof ((_cellParams$value2 = cellParams.value) == null ? void 0 : _cellParams$value2.toString) === 'function') {
      value = cellParams.value.toString();
    } else {
      value = cellParams.value;
    }
  } else {
    value = cellParams.formattedValue;
  }
  return sanitizeCellValue(value, delimiterCharacter);
};
const objectFormattedValueWarning = buildWarning(['MUI: When the value of a field is an object or a `renderCell` is provided, the CSV export might not display the value correctly.', 'You can provide a `valueFormatter` with a string representation to be used.']);
const serializeRow = ({
  id,
  columns,
  getCellParams,
  delimiterCharacter,
  ignoreValueFormatter
}) => columns.map(column => {
  const cellParams = getCellParams(id, column.field);
  if (process.env.NODE_ENV !== 'production') {
    if (String(cellParams.formattedValue) === '[object Object]') {
      objectFormattedValueWarning();
    }
  }
  return serializeCellValue(cellParams, {
    delimiterCharacter,
    ignoreValueFormatter
  });
});
export function buildCSV(options) {
  const {
    columns,
    rowIds,
    getCellParams,
    delimiterCharacter,
    includeHeaders,
    ignoreValueFormatter
  } = options;
  const CSVBody = rowIds.reduce((acc, id) => `${acc}${serializeRow({
    id,
    columns,
    getCellParams,
    delimiterCharacter,
    ignoreValueFormatter
  }).join(delimiterCharacter)}\r\n`, '').trim();
  if (!includeHeaders) {
    return CSVBody;
  }
  const CSVHead = `${columns.filter(column => column.field !== GRID_CHECKBOX_SELECTION_COL_DEF.field).map(column => sanitizeCellValue(column.headerName || column.field, delimiterCharacter)).join(delimiterCharacter)}\r\n`;
  return `${CSVHead}${CSVBody}`.trim();
}