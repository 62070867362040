import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {displayNDC, getAnalyticsProductDataAttributes, HandlerBarTemplateHandler} from "../utils/Helper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Box, Typography, Divider, styled, Chip, CircularProgress } from "@mui/material";
import { BackComponent, Button } from "../components";
import InfoIconDarkImage from "../assets/images/infoIconDark.svg";
import "../../src/slider.css";
import { Spinner } from "../components/Spinner";
import { AlertMessage } from "../components/AlertMessage";
import { ComboSelect } from "../components";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageGallery from 'react-image-gallery';
import { addToOrderCartApi, getOrderCartApi, getProductDetailsApi } from "../features/commerce/commerceThunkApi";
import { loadContractAllocationComponent, loadProductDetailContentComponent } from "../features/contentful/contentfulThunkApi";
import "react-image-gallery/styles/css/image-gallery.css";
import { OrderRelatedProductsCard } from "../components/OrderRelatedproductsCard";
import { StyledTooltip } from "../components/StyledTooltip";
import { useUserInfoContext } from "../contexts";
import { Accordion } from "../components/Accordion";
import { theme } from "../assets/theme";
import { AffiliationModal } from "../components/ModalComponent/AffiliationModal";
import NotOrderableAlert from "../components/NotOrderableAlert/notOrderableAlert";
import conversationIcon from '../assets/images/conversationIcon.svg';;
import infoIcon from '../assets/images/infoIcon2.svg';

const InfoIcon = styled('img')`
  margin-left: 5px;
  top: 4px;
  position: relative;
  width: 16px;
`;

const ConversationIcon = styled('img')`
  top: 4px;
  position: relative;
  width: 16px;
`;

const DonutGraph = styled(CircularProgress)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
  height: '200px',

  '&.MuiCircularProgress-determinate': {
    position: 'absolute',
    height: 'auto',
  },

  '&.MuiCircularProgress-determinate.primary': {
    color: theme.palette.primary.red,
  },

  '&.MuiCircularProgress-determinate.secondary': {
    color: theme.palette.primary.blueFaded,
  },
}));


const accordionCustomStyle = {
  borderRadius: "0px !important",
  boxShadow: "none",
  borderBottom: "1px solid #D1D1D1",
  borderTop: "none",
  margin: "0px !important",
  width: "100%",
};

const MessageContentStyles = {
    display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '18px',
		padding: '30px 16px',
		borderRadius: '0px 8px 8px 0px',
		borderLeft: '2px solid #BFE6F6',
		background: '#BFE6F6',
		height: '100px'
};

const ProductDisplayPage = () => {
  const windowPathname = window.location.pathname;
  const productId = windowPathname.split('/').pop();
  const history = useHistory();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.globalStates.locale);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const outOfStockErrorErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "OUT_OF_STOCK");
  const outOfStockError = outOfStockErrorErrorData && outOfStockErrorErrorData[0]?.fields?.message;
  const qtyExceedsErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "QUANTITY_EXCEEDS_INVENTORY");
  const qtyExceedsError = qtyExceedsErrorData && qtyExceedsErrorData[0]?.fields?.message;
  const qtyExceedsSkuOrderErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "QUANTITY_EXCEEDS_SKU_ORDER");
  const qtyExceedsSkuOrderError = qtyExceedsSkuOrderErrorData && qtyExceedsSkuOrderErrorData[0]?.fields?.message;
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const alertRef = useRef(null);
  const { userInfo } = useUserInfoContext();
  const { uid : userId } = userInfo;
  const [doseQty, setDoseQty] = useState({ label: '', value: 0 });
  const { commerceUser } = useSelector((store) => store?.commerce);
  const gpoName = commerceUser?.data?.orgUnit?.defaultGPO?.gpoName || null;
  const isSingleGPOAccount = commerceUser?.data?.orgUnit?.gpoDetails?.length <= 1
  const { productData: productDetails } = useSelector((store) => store?.commerce?.productDetails);
  const { relatedProduct } = useSelector((store) => store?.commerce?.productDetails);
  const { productDetailContentComponent: staticContent } = useSelector((store) => store?.contentful);
  const { contractAllocationComponentContent: contractAllocationStaticContent } = useSelector((store) => store?.contentful);
  const filteredImages = productDetails?.images?.filter(image => {
    return image?.format === "galleryProduct" && image?.imageType === "GALLERY";
  });
  const [stockLevel, setStockLevel] = useState(null);
  const customerInventory = productDetails?.customerInventory;
  const percentRemaining = ((customerInventory?.remainingAllocatedQuantity) / (customerInventory?.allocatedQuantity)) * 100;
  const formattedPercentRemaining = ( percentRemaining % 1 === 0 ? percentRemaining.toFixed(0) : percentRemaining.toFixed(1));
  const [dosesQtyError, setDosesQtyError] = useState('');
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [isAffiliationModalOpen, setIsAffiliationModalOpen] = useState(false);
  const isSelectAffiliationEnabled = process.env.IS_SELECT_AFFILIATION_ENABLED === 'true';
  const isSiteOutageBannerEnabled = process.env.IS_SITE_OUTAGE_BANNER_ENABLED === 'true';
  const canOrder2023Products = useSelector(store => store?.globalStates?.canOrder2023Products);

  const images = filteredImages?.map((image, i) => {
    return ({
      original: image?.url,
      thumbnail: image?.url,
      originalAlt: `Original Product ${i+1}`,
      thumbnailAlt: `Thumbnail Product ${i+1}`
    })
  });

  const alert = (msg, type) => {
    setMessage(msg);
    setType(type);
  }

  const backButtonAction = () => {
    history.push(`/${locale}/products`);
  }
  const backButtonHref = `/${locale}/products`;

  useEffect(() => {
    setProcessing(true);
    alert('', 'info')
    alertRef.current?.closeAlert('');
    setDoseQty({ label: '', value: 0 })
    dispatch(getProductDetailsApi({ productId: productId }))
    .unwrap()
    .then((data) => {
      setStockLevel(data?.productData?.stock?.stockLevel);
      if ( (data?.productData?.stock?.stockLevel === 0 && data?.productData?.customerInventory?.allocatedQuantity === 0) || (data?.productData?.stock?.stockLevel === 0 && !data?.productData?.customerInventory) ) {
        alert(outOfStockError, 'error');
        alertRef.current?.openAlert(outOfStockError);
      }
      setProcessing(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    })
    .catch((error) => {
      alert(generalApiError, 'error');
      alertRef.current?.openAlert(error);
      setProcessing(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    })
  }, [productId, dispatch, generalApiError, outOfStockError])

  useEffect(() => {
    if(staticContent && Object.keys(staticContent).length === 0) {
      dispatch(loadProductDetailContentComponent());
    }
    if(contractAllocationStaticContent && Object.keys(contractAllocationStaticContent).length === 0 && customerInventory) {
      dispatch(loadContractAllocationComponent());
    }
  },[dispatch, staticContent, customerInventory, contractAllocationStaticContent]);

  const getDosesOptions = (dosesPerCarton) => {
    let minDoses = { "label": dosesPerCarton?.toString(), "value": dosesPerCarton };
    let doses = staticContent?.quantityIncrements?.quantityIncrements?.map(item => {
      return (
        {
          "label": item.toString(),
          "value": item,
        }
      )
    });
    doses?.unshift(minDoses);
    return doses;
  };

  const selectBlurHandler = (event, dosesPerCarton) => {
    let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
    setDoseQty(dropDownValue.toString())
  }
  const selectKeyUpHandler = (event, dosesPerCarton) => {
    if (event.key === "Enter") {
      let dropDownValue = (Math.ceil(parseInt(event.target.value) / parseInt(dosesPerCarton))) * parseInt(dosesPerCarton);
      setDoseQty(dropDownValue.toString());
    }
  }

  const handleAddToCart = async (e) => {
    const qty = doseQty / productDetails?.dosesPerCarton;
    if(typeof qty !== 'number' || isNaN(qty) || qty === 0){
      setDosesQtyError('true');
      return false;
    }
    setLoading(true);
    setDosesQtyError('');
    let addToCartBody = {
      product: {
        code: productDetails?.code,
      },
      quantity: qty
    }
    dispatch(addToOrderCartApi({ userId, cartData: addToCartBody })).unwrap()
      .then((addToCartData) => {
        setDoseQty({ label: '', value: 0 });
        if (addToCartData?.statusCode === 'lowStock') {
          alert(qtyExceedsError, 'error');
          alertRef.current?.openAlert(qtyExceedsError);
        } else if (addToCartData?.statusCode === 'maxOrderQuantityExceeded') {
          alert(qtyExceedsSkuOrderError, 'error');
          alertRef.current?.openAlert(qtyExceedsSkuOrderError);
        } else {
          alert(staticContent?.quantityAddedInfoMessage, 'info')
          alertRef.current?.openAlert('');
        }
        dispatch(getOrderCartApi({ userId })).unwrap()
          .then((data) => {
            setLoading(false);
          })
          .catch((error) => {
            alert(generalApiError, 'error');
            alertRef.current?.openAlert(error);
            setLoading(false);
          })
        })
      .catch((error) => {
        if (error?.errors[0].type === "InsufficientStockError") {
          alert(outOfStockError, 'error')
          setStockLevel(0)
        } else 
          alert(generalApiError, 'error');
        
        alertRef.current?.openAlert();
        setDoseQty({ label: '', value: 0 });
        setLoading(false);
      })
  }

  const disclaimerMessage = HandlerBarTemplateHandler({minDoses: productDetails?.dosesPerCarton}, staticContent?.disclaimerMessage?.content[0]) || null;
  const dosesRemainingMessage = HandlerBarTemplateHandler({dosesRemaining: customerInventory?.remainingAllocatedQuantity * productDetails?.dosesPerCarton}, contractAllocationStaticContent?.dosesRemainingMessage) || null;

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return <Link to={`/${locale}/my-profile`}>
                    {node.content[0].value}
                  </Link>;
        }
    },
  };

  const authorizedUseOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant="p1" mb="20px" component="p">{children}</Typography>,
    },
  };

  const factSheetOptions = {
    renderNode: {
      'hyperlink': (node, children) => {
        if (node.nodeType === 'hyperlink') {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
        }
      }
    }
  }

  return (
    <>
      <AlertMessage variant={"filled"} type={type} message={message} sx={{ top: 120 }} ref={alertRef} />
      <Spinner processing={processing} />
      {isSiteOutageBannerEnabled && canOrder2023Products &&
        <Box alignContent={'flex-start'} height={'50'}>
          <Box sx={MessageContentStyles} marginBottom={'20px'}>
          <img src={infoIcon} alt="info_icon"width="36" height="36"/>
          <Typography fontFamily="Aeonik Regular" fontSize="24px" fontWeight="700" data-testid='headerText'>
            To view contract pricing, select  your group affiliation in <Link to={`/${locale}/my-profile`}>your profile</Link>
          </Typography>
          </Box>
        </Box>
		  }
      { productDetails && <Box width={{ xs: '100%', md: 'auto'}} my={{xs: 0, md: 5}} mx={{xs: 0, md: 4, lg: 10}} display={"flex"} flexDirection={"column"} alignItems={{xs: 'center', md: 'flex-start'}} sx={{ overflowX: 'hidden' }}>
        <Box display={'flex'} width={{ xs: "100%", md: "auto" }}>
          { !mobile && <BackComponent onClickHandler={backButtonAction} data-link-href={backButtonHref} sx={{ position: "inherit", margin: "20px 0 60px" }} />}
          { customerInventory && <Box display={"flex"} alignItems={"center"} width={{ xs: "100%", md: "25%" }} height={{xs: "100px", sm: "180px", md: "100px"}} pl={{ xs: 0, md: 2 }} pt={{xs: 0, md: 0}} pb={{xs: 0, md: 0}} minWidth={"200px"} maxWidth={{ xs: "none", md: "400px" }} color={"white"} position={{xs: "inherit", md: "fixed"}} right={0} zIndex={1} sx={{ backgroundColor: "#1e2846", borderRadius: { xs: "inherit", md: "16px 0px 0px 16px"} }}>
            <Box display={"flex"} width={{ xs: "35%", md: "inherit"}} alignItems={"center"} justifyContent={"center"}>
              <DonutGraph 
                size={"20%"}
                value={100}
                thickness={4}
                variant="determinate"
                className="secondary"
              />
              <DonutGraph 
                size={"20%"}
                value={formattedPercentRemaining}
                thickness={4}
                variant="determinate"
                className="primary"
              />
              <Typography variant={'p2'} color="neutral.white">{`${formattedPercentRemaining}%`}</Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} ml={{ xs: 0, md: 2 }}>
              <Typography variant={'h5'} fontSize={'14px'}>{dosesRemainingMessage}</Typography>
              <Typography variant={'p2'} fontSize={'10px'} color='neutral.gray2'>{contractAllocationStaticContent?.disclaimerMessage}</Typography>
            </Box>
          </Box>}
        </Box>

        <Box {...getAnalyticsProductDataAttributes(productDetails)} display={'flex'} width={'auto'} flexDirection={{ xs: "column", md: "row" }} alignItems={{xs:"center", md:"start"}} m={{ xs: 4, md: 0 }} gap={{md: 2 }}>
          <ImageGallery items={images || []} showThumbnails={ mobile ? false : true } width={'100%'} thumbnailPosition="left" showPlayButton={false} showFullscreenButton={false} showNav={false} disableKeyDown={true} />
          <Box display={'flex'} flexDirection={'column'}>
            <Box mb={3} mt={{xs: 3, md: 0}} display={'flex'} alignItems={'center'}>
              <Chip label={productDetails?.ageGroup} sx={{ bgcolor: 'secondary.graphical', color: 'neutral.white', borderRadius: '4px', maxHeight: '25px', textTransform: 'uppercase' }} />
              <Typography variant={'p1'} textAlign="center" ml={3}>{`NDC# ${displayNDC(productDetails?.ndc)}`}</Typography>
            </Box>
            <Typography variant={'h2'}>{productDetails?.name}</Typography>
            <Box display={'flex'} mb={2} flexDirection={{xs: 'column', md: 'row'}} alignItems={{xs: 'flex-start', md: 'center'}}>
              <Typography variant={'p1'}>{productDetails?.description}</Typography>
              { !mobile && <Divider orientation="vertical" variant="middle" sx={{ height: '15px', margin: '0 15px'}} /> }
              <Typography variant={'p1'}>{productDetails?.doseVolume}{" "}{productDetails?.doseVolumeUOM}</Typography>
            </Box>

             <Box my={'12px'}>
                <Typography variant={ gpoName ? 'p1' : 'h3' } color='primary.main' sx={{textDecoration: gpoName ? 'line-through' : 'none'}} id={'defaultPrice'}>{productDetails?.defaultDosePrice?.formattedValue}</Typography>
                { gpoName && <Typography variant={'h3'} color='primary.main' lineHeight='1.2 !important' id={'price'}>{productDetails?.dosePrice?.formattedValue}</Typography>}
             </Box>

            <Box my={1}>
              {gpoName && <Typography variant={'p1'}>
                <b style={{fontWeight: "bold"}}>{staticContent?.gpoAffiliationsTooltipMessage}</b>
                {` ${gpoName}`}
                <StyledTooltip
                    variant="dark"
                    id="tooltip"
                    placement="right"
                    {...(mobile || tablet ? {open: isTooltipOpened, onClose: () => setIsTooltipOpened(false)} : {})}
                    onClick={mobile || tablet ? () => setIsTooltipOpened(prev => !prev) : undefined}
                    title={
                      <React.Fragment>
                        {documentToReactComponents(staticContent?.gpoAffiliationHelpMessage, options)}
                      </React.Fragment>
                    }
                >
                  <InfoIcon src={InfoIconDarkImage}/>
                </StyledTooltip>
              </Typography>}
            </Box>
            {isSelectAffiliationEnabled &&
                <Box mb={4}>
                  <ConversationIcon src={conversationIcon}/>
                  <Typography variant={'p1'}
                              color='secondary.textLinks'>{` ${staticContent?.incorrectPricingMessage} `}</Typography>
                  <Typography
                      variant="p1"
                      color='secondary.textLinks'
                      onClick={() => setIsAffiliationModalOpen(true)}
                      style={{cursor: 'pointer', textDecoration: 'underline'}}>
                    View options
                  </Typography>
                </Box>}

            <AffiliationModal
                isAffiliationModalOpen={isAffiliationModalOpen}
                setIsAffiliationModalOpen={setIsAffiliationModalOpen}
                staticContent={staticContent}
                isSingleGPOAccount={isSingleGPOAccount}
            />

            { !canOrder2023Products && <NotOrderableAlert sourcePage={'ProductDisplay'} /> }

            <Box display={'flex'} alignItems={{xs: 'end', sm: 'center'}} gap={2}>
              {!((stockLevel === 0 && customerInventory?.allocatedQuantity === 0) || (stockLevel === 0 && !customerInventory)) &&
                  <ComboSelect
                      variant={"square"}
                      inputProps={{className: "selectDoses"}}
                      label={"DOSES"}
                      sx={{
                        position: 'relative',
                        top: '3px',
                        "@media screen and (max-width: 500px)": {
                          width: 130,
                          height: 48,
                        }
                      }}
                      options={getDosesOptions(productDetails?.dosesPerCarton) || []}
                      defaultValue={doseQty}
                      error={dosesQtyError}
                      onChangeHandler={() => null}
                      onBlurHandler={(event) => selectBlurHandler(event, productDetails?.dosesPerCarton)}
                      onKeyUpHandler={(event) => selectKeyUpHandler(event, productDetails?.dosesPerCarton)}
                  />}
              <Button className='addToCart'
                      data-testid="primaryBtn" sx={{marginBottom: {xs: '3px', md: '0'}}}
                      loading={loading}
                      buttonType="primary"
                      disabled={!canOrder2023Products || (stockLevel === 0 && customerInventory?.allocatedQuantity === 0) || (stockLevel === 0 && !customerInventory)}
                      onClick={handleAddToCart}>
                {(stockLevel === 0 && customerInventory?.allocatedQuantity === 0) || (stockLevel === 0 && !customerInventory) ? "Out of Stock" : "Add to Cart"}
              </Button>
            </Box>
            <Typography variant={'p2'} color={'neutral.gray1'}>{disclaimerMessage}</Typography>
            <Typography variant={'p2'}>{documentToReactComponents(staticContent?.customerCareMessage)}</Typography>
          </Box>
        </Box>

        {relatedProduct?.references?.length > 0 &&
            <Box display={'grid'} px={{xs: '32px', md: '138px'}} justifyContent={'center'} mt={'40px'} width={'100%'}>
              <Typography variant={'h4'} width={"100%"}>{staticContent?.relatedProductsText}</Typography>
              <Box display={{xs: 'grid', md: 'flex'}} gap={{xs: 3, md: 1}} my={'20px'} justifyContent={'center'}
                   sx={{width: "100%"}}>
                {relatedProduct?.references?.map((product, index) => (
                    <Link to={`/product/${product.target.code}`} key={`orderRelatedProductsCard-${index}`}
                          width={'100%'}>
                      <OrderRelatedProductsCard key={`productNumber ${index}`} item={product}/>
                    </Link>
                ))}
              </Box>
            </Box>}
        

        <Box display={'grid'} mx={{xs:'32px', lg:'138px'}} justifyContent={'center'} my={'40px'}>
          <Box>
             <Divider id="divder" orientation="horizontal" variant="middle" sx={{ width:"100%"}} />
          </Box>

          <Box display={{xs:'grid', md:'flex'}} gap={{xs:3, md:1}} justifyContent={'center'}>
            <Accordion
                id={"AuthorizedUseAccordion"}
                heading={<Typography mx={{xs:'5px'}} variant={'h4'}>{staticContent?.authorizedUseSectionLabel}</Typography>}
                description={
                  <Box component="div">
                    {documentToReactComponents(staticContent?.authorizedUseMessage, authorizedUseOptions)}
                  </Box>
                }
                accordionCustomStyle={accordionCustomStyle}
              />
          </Box>
          <Box display={'flex'} gap={{xs:3, lg:1}} justifyContent={'center'}>
            <Accordion
                id={"FactSheetAccordion"}
                data-testid="factSheetLabel"
                heading={<Typography mx={{xs:'5px'}} variant={'h4'}>{staticContent?.factSheetSectionLabel}</Typography>}
                description={
                  <Box component="div">
                    <Typography variant="p1" mb="20px" component="p">{documentToReactComponents(staticContent?.factSheetMessage, factSheetOptions)}</Typography>
                  </Box>
                }
                accordionCustomStyle={accordionCustomStyle}
              />
          </Box>
        </Box>
      </Box>}
    </>
  )
}

export default ProductDisplayPage
