import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { Button } from "..";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from '@contentful/rich-text-types';
import { recoverPasswordApi } from "../../utils/commerceOktaApi";
import { AlertMessage } from "../AlertMessage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRef } from "react";
import { loadRecoveryLinkStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Box, Typography } from "@mui/material";
import { resetPasswordApi } from "../../utils/IdentityService/EmailVerificationApi";

const ButtonContainer = styled('div')(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 50px auto 40% auto;
  ${[breakpoints.down('sm')]} {
    width: 60%;
    margin-top: 130px;
    margin-bottom: 150px;
  }
`);

export const RecoveryLink = () => {
  const { recoveryLinkContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = recoveryLinkContent;
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const alertRef = useRef(null)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const emailAddress = useSelector(state => state.globalStates.emailAddress);
  const isRegistrationEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(recoveryLinkContent).length === 0) {
      dispatch(loadRecoveryLinkStaticContent());
    }
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha)
      return;

    const token = await executeRecaptcha('resend_recovery_link');
    return token
  }, [executeRecaptcha]);

  const recoveryLinkHandler = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if (localStorage.getItem("recoverPassEmail")) {
      try {
        const recaptchaToken = await handleReCaptchaVerify()
        const reqObj = {
          email: localStorage.getItem("recoverPassEmail"),
          recaptchaToken
        }
        const res = await recoverPasswordApi(reqObj);
        if (res?.status === 200) {
          setProcessing(false);
        }
      } catch (err) {
        setErrorMsg(generalApiError);
        setProcessing(false);
        alertRef.current?.openAlert()
      }
    }
  }

  const handleRecoveryLink = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if (localStorage.getItem("recoverPassEmail")) {
      try {
        const res = await resetPasswordApi({emailAddress: emailAddress});
        if (res?.status === 200) {
          setProcessing(false);
        }
      } catch (err) {
        setErrorMsg(generalApiError);
        setProcessing(false);
        alertRef.current?.openAlert();
      }
    }
  }

  const handleResendLink = !isRegistrationEnabled ? recoveryLinkHandler : handleRecoveryLink

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    }
  };

  return (
    <div>
      <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
      <Box component={'img'} margin={{xs:'50px auto 20px auto',sm:'20px auto'}} display='flex' id="recoveryLinkImg" src={staticContent?.icon?.fields?.file?.url} width="90px" height="90px"></Box>
      <Typography variant={'h1'} textAlign='center' color='primary.main' id="headline" data-testid='title'>{staticContent?.headline}</Typography>
      <Typography variant={'p1'} textAlign='center' mt='20px' id="emailSentMessage" data-testid='message'>{documentToReactComponents(staticContent?.message, options)}</Typography>
      <ButtonContainer>
        <Button
          id="resendLinkButton"
          buttonType="secondary"
          onClick={handleResendLink}
          loading={processing}
          data-testid='resendBtn'
        >RESEND LINK
        </Button>
      </ButtonContainer>
    </div>
  )
};