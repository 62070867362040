import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTracking } from '@modernatx/tracking';
import { activateOktaUserApi } from "../../utils/commerceOktaApi";
import { updateUserApiNoAuth } from "../../utils/commerceUsersApi";
import { AlertMessage, Spinner } from "../../components";

const UserActivation = ({ token }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [activationMsg, setActivationMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { globalErrorMessage, globalSuccessMessage } = useSelector(store => store?.globalMessages);
  const successAlertRef = useRef(null);
  const alertRef = useRef(null);
  const { trackCustom } = useTracking();

  useEffect(() => {
    const message = getMessageByCode(globalErrorMessage, "OKTA_USER_NOT_ONBOARDED");
    if (message) {
      setErrorMsg(message);
      alertRef.current?.openAlert();
      localStorage.removeItem("invalidUser");
    }
    setErrorMsg('');
  }, [globalErrorMessage]);

  useEffect(() => {
    const activateUser = async () => {
      if (!token || globalSuccessMessage.length === 0) return;

      setIsLoading(true);

      try {
        const res = await activateOktaUserApi(token);
        if (res?.status === 200) {
          const data = { "active": true };
          const resp = await updateUserApiNoAuth(token, data); // activate user in commerce
          if (resp?.status === 200) {
            const message = getMessageByCode(globalSuccessMessage, "ACCOUNT_ACTIVATED");
            setActivationMsg(message);
            trackCustom("Activated New User", { "OktaID": token, "data": data });
            successAlertRef.current?.openAlert();
          }
        }
      } catch (err) {
        const message = getMessageByCode(globalErrorMessage, "ACCOUNT_ALREADY_ACTIVATED");
        setErrorMsg(message || 'An error occurred during activation.');
        alertRef.current?.openAlert();
      } finally {
        setIsLoading(false);
      }
    };

    activateUser();
  }, [token, globalSuccessMessage, globalErrorMessage, trackCustom]);

  const getMessageByCode = (messages, code) => {
    if (Array.isArray(messages)) {
      const messageDataArray = messages?.filter(data => data?.fields?.code === code);
      return messageDataArray.length > 0 ? messageDataArray[0]?.fields?.message : null;
    }
    return null;
  };

  return (
    <>
        <Spinner processing={isLoading} />
        { errorMsg &&<AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef} /> }
        <AlertMessage variant={"filled"} type={"success"} color={"info"} message={activationMsg} ref={successAlertRef} />
    </>
  );
};

export default UserActivation;
