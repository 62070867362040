import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createCartApi, getCartsApi } from "../utils/ReservationApis";
import { styled } from '@mui/material/styles';
import { ProgressBar, CartComponent, ProductsComponent, PreBookConfirmation, Spinner, AlertMessage } from "../components";
import { useUserInfoContext } from "../contexts";
import { useRef } from "react";
import { useSelector } from "react-redux";

const ProgressBarContainer = styled('div')``;

const Container = styled('div')`
`;
const CreateReservation = () => {
  const history = useHistory();
  const [progressValue, setProgressValue] = useState(33);
  const [loader, setLoader] = useState(true);
  const [cart, setCart] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartId, setCartId] = useState("");
  const { userInfo } = useUserInfoContext();
  const { uid : userId } = userInfo;
  const [errorMsg, setErrorMsg] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [totalDoses, setTotalDoses] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const locale = useSelector((state) => state.globalStates.locale);
  const alertRef = useRef(null)
  

  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const resExistErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "EXISTING_RESERVATION");
 
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;

  const createCart = async (userId) => {
    try {
      const { carts } = await getCartsApi(userId)
      const openReservations = carts?.filter((cart) => cart?.status === "PreBook")
      if(openReservations?.length > 0){
        localStorage.setItem("error", resExistErrorData && resExistErrorData[0]?.fields?.message);
        history.push(`/${locale}/manage-reservations`);
      }else{
        const res = await createCartApi(userId);
        const { data } = res;
        setLoader(false);
        setCartId(data?.code);
        setCartDetails(data);
        let invalidPreBookingError = localStorage.getItem("INVALID_PREBOOKING_ID") || "";
        setErrorMsg(invalidPreBookingError);
        if (invalidPreBookingError) {
          alertRef.current?.openAlert()
        }
        localStorage.removeItem("INVALID_PREBOOKING_ID")
      }
    } catch (error) {
      setLoader(false);
      setErrorMsg(generalApiError);
      alertRef.current?.openAlert()
    }
  };


  useEffect(() => {
    window.scrollTo(0,0);
    createCart(userId);
  }, [userId]);

  useEffect(() => {
    if(localStorage.getItem("isRouteChanged") || progressValue === 66 || progressValue === 100){
      var vpv = new CustomEvent("virtualPageView",{}); 
      document.dispatchEvent(vpv);
      localStorage.removeItem("isRouteChanged");
    }
  }, [progressValue])

  return (
    <>
      <Spinner processing={loader}/>
      {!loader && <Container>
        <ProgressBarContainer data-testid="reservationContainer">
          <ProgressBar progressValue={progressValue} />
        </ProgressBarContainer>
        {errorMsg && <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{top:120}} ref={alertRef}/>}
        {progressValue && progressValue === 33 && <ProductsComponent setLoader={setLoader} setErrorMsg={setErrorMsg} cartDetails={cartDetails} updateProgressValue={setProgressValue} cartId={cartId} userId={userId} />}
        {progressValue && progressValue === 66 && <CartComponent setLoader={setLoader} cart={cart} updateProgressValue={setProgressValue} cartId={cartId} userId={userId} setProductsData={setProductsData} setTotalPrice={setTotalPrice} setTotalDoses={setTotalDoses} />}
        {progressValue && progressValue === 100 && <PreBookConfirmation confirmationNumber={cartId} productsData={productsData} totalDoses={totalDoses} totalPrice={totalPrice} />}
      </Container>
      }
    </>
    
  );
};

export default CreateReservation;
