import { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useTheme, Box } from "@mui/material";
import CardComponent from "../CardComponent";
import { useModal } from "../../hooks/useModal";
import { OrderProducts } from "../OrderProducts";

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED === 'true';

const imageStyles = {
  objectFit: "contain",
  width: "auto",
  maxHeight: "50px",
  flexShrink: 0,
};

export const HomepageAfterBannerCard = () => {
  const { authState } = useOktaAuth() || "";
  const isAuthenticated = authState?.isAuthenticated;
  const locale = useSelector((state) => state.globalStates.locale);
  const { homePage: homePageContent } = useSelector(
    (store) => store?.contentful
  );
  const history = useHistory();
  const { handleOpenModal } = useModal();
  const theme = useTheme();

  const navigateOrLogin = (path) => {
    if (isAuthenticated) {
      history.push(`/${locale}/${path}`);
    } else {
      handleOpenModal(`/${locale}/${path}`);
    }
  };

  const buttonActions = useMemo(() => {
    return isReservationsEnabled
      ? [
          {
            label: "Order now",
            onClick: () => navigateOrLogin("products"),
          },
        ]
      : null;
  }, [isAuthenticated, locale, isReservationsEnabled]);

  const cardTitle = isReservationsEnabled
    ? homePageContent?.leftCardTitle
    : homePageContent?.orderingLeftCardTitle;
  const imageURL = isReservationsEnabled
    ? homePageContent?.orderingCardImage?.fields?.file?.url
    : null;

  return (
    <CardComponent
      title={cardTitle}
      description={homePageContent?.orderingLeftCardDescription}
      imageUrl={imageURL}
      imageStyle={imageStyles}
      actions={buttonActions}
      factSheetContents={homePageContent?.factSheetLinks}
      cardContentSx={(isReservationsEnabled ? {} : {
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "flex-start", 
        alignItems: "flex-start", 
        textAlign: "flex-start", 
        [theme.breakpoints.down("sm")]: {
          height: "100%",
          paddingBottom: "20px",
          flexDirection: "column",
          marginLeft: '0px', 
          marginRight: '0px',
        },
      })}
      sx={{
        width: "100%",
        display: "flex",
        padding: "24px 32px 32px 32px",
        [theme.breakpoints.down("sm")]: {
          height: "100%",
          paddingBottom: "20px",
          flexDirection: "column",
          marginLeft: '0px', 
          marginRight: '0px',
        },
        [theme.breakpoints.down("md")]: {
          overflow: "scroll"
        }
      }}
    >
      <Box sx={{ alignSelf: "flex-start", marginLeft: "auto"}}>
        <OrderProducts isHomePage={true} />
      </Box>
    </CardComponent>
  );
};
