import { CONTRACT_TYPES } from "../../constants";

export const generateInternalCartLineItemId = () => {
    return Math.random().toString(36).substr(2, 9);
}
export const initializeEmptyCartLineItems = (orderableProducts, cart, shipToAddresses, defaultBillToAddress) => {
    return orderableProducts?.length && orderableProducts?.map(product => {
        const internalCartLineItemId = generateInternalCartLineItemId();

        let shippingAddress = cart?.length && cart?.cartLineItems[0]?.shipToAddress;
        if (shippingAddress === null || shippingAddress?.addressId === undefined) {
            shippingAddress = shipToAddresses[0];
        }
        return {
            internalCartLineItemId: internalCartLineItemId,
            cartId: cart?.cartId,
            shipToAddress: {addressId: String(shippingAddress?.id)},
            shipToOrganizationId: String(shippingAddress?.organizationId),
            productId: product?.code,
            quantity: 0,
            purchaseOrderNumber: "",
            healthcareLicense:{},
            priceSnapshotId:"",
            billToAddress: {addressId: String(defaultBillToAddress?.id)},
            billToOrganizationId: String(defaultBillToAddress?.organizationId),
        }
    });
}

export const initializeExistingMResviaCartLineItems = (cart) => {
    return cart?.cartLineItems?.map(cartLineItem => {
        return {
            internalCartLineItemId: cartLineItem?.cartLineItemId,
            cartLineItemId: cartLineItem?.cartLineItemId,
            shipToAddress: {addressId: String(cartLineItem?.shipToAddress?.addressId)},
            shipToOrganizationId: String(cartLineItem?.shipToOrganizationId),
            productId: cartLineItem.productId,
            quantity: cartLineItem.quantity,
            purchaseOrderNumber: cartLineItem?.purchaseOrderNumber || "",
            healthcareLicense:cartLineItem?.healthcareLicense || {},
            priceSnapshotId:cartLineItem?.priceSnapshotId || "",
            billToAddress: {addressId: String(cartLineItem?.billToAddress?.addressId)},
            billToOrganizationId: String(cartLineItem?.billToOrganizationId),
        }
    });
}

const initializeExistingCartLineItems = (orderableProducts, cart, shipToAddresses, defaultBillToAddress) => {
    //initialize an object with unique shipping addressID as keys
    let initializedCartLineItemByAddress = {};
    let existingCartLineItemByAddress = {}

    cart?.cartLineItems.forEach(cartLineItem =>{
        let purchaseOrderNumber = "";
        let healthcareLicense = {};
        const shipToAddressId = cartLineItem?.shipToAddress?.addressId;
        if (!initializedCartLineItemByAddress[shipToAddressId]) {
            initializedCartLineItemByAddress[shipToAddressId] = [];
        }
        initializedCartLineItemByAddress[shipToAddressId].push(cartLineItem);
        if (!existingCartLineItemByAddress[shipToAddressId]) {
            existingCartLineItemByAddress[shipToAddressId] = [];
        }
        if (cartLineItem?.purchaseOrderNumber) {
            purchaseOrderNumber = cartLineItem?.purchaseOrderNumber;
        }

        if (cartLineItem?.healthcareLicense) {
            healthcareLicense = cartLineItem?.healthcareLicense;
        }
        existingCartLineItemByAddress[shipToAddressId] = {
            shipToOrganizationId: cartLineItem?.shipToOrganizationId,
            healthcareLicense: healthcareLicense,
            purchaseOrderNumber: purchaseOrderNumber,
        };
    })
    const finalizedCartLineItems = []

    //Loop through all shipToAddresID and initialize all orderable products, map the correct cartLineItem information on there
    Object.keys(initializedCartLineItemByAddress).forEach(shipToAddressId => {
        orderableProducts?.forEach(product => {
            const internalCartLineItemId = generateInternalCartLineItemId();

            const matchingCartLineItem = initializedCartLineItemByAddress[shipToAddressId].find(cartLineItem =>
                cartLineItem.productId === product?.code
            );

            const cartLineItem = {
                internalCartLineItemId: matchingCartLineItem?.cartLineItemId || internalCartLineItemId,
                ...(matchingCartLineItem && { cartLineItemId: matchingCartLineItem?.cartLineItemId }),
                shipToAddress: { addressId: String(matchingCartLineItem?.shipToAddress?.addressId || shipToAddressId) },
                shipToOrganizationId: String(existingCartLineItemByAddress?.[shipToAddressId]?.shipToOrganizationId || matchingCartLineItem?.shipToOrganizationId || null),
                productId: matchingCartLineItem?.productId || product?.code,
                quantity: matchingCartLineItem?.quantity || 0,
                purchaseOrderNumber: existingCartLineItemByAddress?.[shipToAddressId]?.purchaseOrderNumber || matchingCartLineItem?.purchaseOrderNumber || "",
                healthcareLicense: existingCartLineItemByAddress?.[shipToAddressId]?.healthcareLicense || matchingCartLineItem?.healthcareLicense || {},
                priceSnapshotId: matchingCartLineItem?.priceSnapshotId || "",
                billToAddress: { addressId: String(matchingCartLineItem?.billToAddress?.addressId || defaultBillToAddress?.id) },
                billToOrganizationId: String(matchingCartLineItem?.billToOrganizationId || defaultBillToAddress?.organizationId),
            }
            finalizedCartLineItems.push(cartLineItem)
        })
    });
    return finalizedCartLineItems;
}

export const getCartLineItems = (orderableProducts, cart, shipToAddresses, defaultBillToAddress) => {
    if (cart?.cartLineItems?.length) {
        return initializeExistingCartLineItems(orderableProducts, cart, shipToAddresses, defaultBillToAddress)
    }
    else{
        return initializeEmptyCartLineItems(orderableProducts, cart, shipToAddresses, defaultBillToAddress);
    }
};

export const getShipToOrganizationId = (shipToAddresses, shipToAddressId) => {
    let shipToAddress = shipToAddresses?.find(address => String(address.id) === String(shipToAddressId));
    return shipToAddress?.organizationId;
}

export const getPrices = (selectedContract, pricingData) => {
    const defaultListPrice = pricingData?.pricesByPriceReference?.find(price => price?.priceReferenceType === CONTRACT_TYPES.CUSTOMER_PRICE_LIST);
    const contractPrices = selectedContract && pricingData?.pricesByPriceReference?.find(price => price?.priceReferenceId === selectedContract?.id);;
    //Loop through default list price to save all list prices to productPricingMap, and add contract price if available
    let productPricingMap = defaultListPrice?.productPrices?.reduce((allPrices, productPrice) => {
        const contractPrice = contractPrices?.productPrices?.find(price => price?.productId === productPrice?.productId);
        allPrices[productPrice?.productId] = {
            listPrice: productPrice?.listPriceFormatted,
            contractPrice: contractPrice?.finalPriceFormatted
        };
        return allPrices;
    }, {});
    return productPricingMap;
};