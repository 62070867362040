import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Box, Typography, useTheme} from '@mui/material';
import { styled } from '@mui/material/styles';
import {ExternalRouterPrompt, ModalComponent, NewsContent} from '../components';
import { HomepageTopCard } from '../components/HomePageContent/topCard';
import { loadInterstitialComponentStaticContent, loadHomePage } from '../features/contentful/contentfulThunkApi';
import { HomepageAfterBannerCard } from "../components/HomePageContent/afterBannerCard";
import { MResviaCard } from "../components/HomePageContent/mResviaCard";
import { useOktaAuth } from "@okta/okta-react";
import { getUserLocation } from "../utils/AddressesApi";
import swissFlag from '../assets/images/switzerland-flag.svg';
import GlobalBanner from "../components/GlobalBanner/GlobalBanner";

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED;

export const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isReservations',
})(({ isReservations, theme }) => ({
    padding: isReservations ? '48px' : '64px 64px 0px 64px',
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '32px 16px',
    },
}));

const NewHomePage = () => {
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const [showSwissModal, setShowSwissModal] = useState(false);
    const theme = useTheme();
    const [showNavigationAlert, setShowNavigationAlert] = useState(false);
    const [externalLink, setExternalLink] = useState("");
    const [externalLinkModalType, setExternalLinkModalType] = useState("socialLink");
    const { interstitialComponentContent: staticContent } = useSelector((store) => store?.contentful);
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);
    const dispatch = useDispatch();
    const canOrder2023Products = useSelector((state) => state.globalStates?.canOrder2023Products);
    const isRegistrationAndOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';

    const isMResviaBannerEnabled = process.env.MRESVIA_BANNER_ENABLED === 'true';
    const closeNavigationAlert = () => {
        setShowNavigationAlert(false);
    };

    useEffect(() => {
        if (!isAuthenticated) {
            getUserLocation()
            .then((response) => {
                setShowSwissModal(response.data === "CH");
            });
        }
    }, [])
    
    useEffect(() => {
        if (!staticContent || Object.keys(staticContent).length === 0) {
            dispatch(loadInterstitialComponentStaticContent())
          }
        if (!homePageContent || Object.keys(homePageContent)?.length === 0) {
            dispatch(loadHomePage());
        }
    }, [staticContent, homePageContent, dispatch]);

    const SwissModal = () => {
        return (
            <Box
                 display={'flex'}
                 flexDirection={'row'}
                 alignItems={'center'}
                 justifyContent={'center'}
                 textAlign={'left'}
                 gap={'24px'}
                 sx={{
                     [theme.breakpoints.down('sm')]: {
                         flexDirection: 'row',
                         alignItems: 'center',
                     },
                 }}
             >
                <Box component={'img'} sx={{objectFit: 'cover'}} alt='swiss flag' height="64px" width="96px" src={swissFlag}/>
                <Box>
                     <Typography sx={{fontSize: "24px", fontWeight: 900}}>Visiting from Switzerland?</Typography>
                     <Typography sx={{fontSize: "24px"}}>Go to <a href={"https://modernadirect.ch"}>ModernaDirect.ch</a></Typography>
                </Box>
             </Box>
        )
     }

    return (
        <div>
            <GlobalBanner />
            <HomepageTopCard />
            { (isMResviaBannerEnabled) &&
                <Box padding='48px'>
                    <MResviaCard setShowNavigationAlert={setShowNavigationAlert}
                                 setExternalLink={setExternalLink}
                                 setExternalLinkModalType={setExternalLinkModalType} />
                </Box>
            }
            { (!isAuthenticated && showSwissModal) &&
                <ModalComponent
                    openModal={showSwissModal}
                    closeModalHandler={() => setShowSwissModal(false)}
                    modalContent={<SwissModal/>}
                    style={{
                        width: '549px',
                        height: '228px',
                        padding: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                            width: '80%',
                            maxWidth: '100vw',
                            padding: '16px',
                            margin: '80px 0px 0px 0px'
                        },
                    }}
                />
            }
            {
                externalLinkModalType === "extrenalLink" && showNavigationAlert && !localStorage.getItem('dontRemindMeExternal') && <ExternalRouterPrompt
                isOpen={showNavigationAlert}
                closeModalHandler={() => closeNavigationAlert("extrenalLink")}
                externalLink={externalLink}
                onOpenLink={setShowNavigationAlert}
                type={"extrenalLink"}
                heading={staticContent?.externalLink?.header}
                headingStyle={{
                    fontFamily: "Aeonik Bold",
                    fontSize: "30px",
                    '@media screen and (max-width: 500px)': {
                        fontSize: '24px',
                        paddingBottom: "24px"
                    }
                }}
                content={staticContent?.externalLink?.subHeader}
                checkBoxLabel={staticContent?.externalLink?.remindMeText}
                buttonLabel={staticContent?.externalLink?.buttonLabel}
                />
            }
            <NewsContent setShowNavigationAlert={setShowNavigationAlert} setExternalLink={setExternalLink} setExternalLinkModalType={setExternalLinkModalType} />
        </div>
    );
};

export default NewHomePage;