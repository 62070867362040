import { Box } from "@mui/material";
import moderna_logo from "../../assets/logos/moderna_logo.svg";

const LogoHeader = () => {
    return (
            <Box 
                padding='24px 0px'
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                data-testid='logoHeader'
                >
                    <img
                        id="ModernaLogo"
                        src={moderna_logo}
                        alt="Moderna Logo"
                        width="158px"
                    />
                
            </Box>
    )
}

export default LogoHeader;
