import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {Box, styled, Typography} from '@mui/material';
import ReservationSummaryCard from '../components/Reservation/ReservationSummaryCard';
import ReservationSummaryTable from '../components/Reservation/ReservationSummaryTable';
import CancelledReservationSummaryCard from '../components/Reservation/CancelledReservationSummaryCard';
import { AlertMessage, Button } from '../components';
import { Spinner } from '../components';
import crossIconRed from '../assets/images/crossIconRed.svg'
import { getReservationById } from '../utils/Reservation/ReservationApi';
import { useUserInfoContext } from "../contexts";
import { formattedPrice, changeDateFormat } from '../utils/Helper';
import * as PropTypes from "prop-types";
import { UNAVAILABLE } from '../constants';

const TypographyTitle = styled(Typography)(({ theme: { breakpoints } }) => `
    font-family: Aeonik Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-left: 10px;
`);

const ReservationSummaryContainer = styled(Box)(({ theme: { breakpoints } }) => `
	display: grid;
    grid-template-columns: 65% 35%; /* 70% width for the first column and 30% for the second */
    grid-gap: 24px; 
    padding: 32px;
    ${[breakpoints.down('sm')]} {
    grid-template-columns: 1fr; 
  }
`);

const ReservationCancelledBanner = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '18px',
    padding: '12px 16px',
    margin: '32px',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: '2px solid #E22929',
    background: '#FFF3F3',
};

TypographyTitle.propTypes = {children: PropTypes.node};
const ReservationSummaryPage = () => {
    const isRealTimePricingEnabled = process.env.IS_REAL_TIME_PRICING_ENABLED === 'true';
    const [displayReservationData, setDisplayReservationData] = useState([])
	const displayPONumberValue = useSelector(store => store?.commerce?.reservation?.reservation?.purchaseOrderNumber) || '';
    const { reservation } = useSelector((store) => store?.commerce);
    const { reservationProducts } = useSelector((store) => store?.commerce) || [];
    const locale = useSelector((state) => state.globalStates.locale);
    const userData = useSelector((store) => store?.commerce?.userData);
    const shipToAddresses = userData?.addresses?.filter(address => address.addressType === 'ship_to') || [];
    const { globalErrorMessage } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const [processing, setProcessing] = useState(false);
    
    const { userInfo } = useUserInfoContext() || '';
    const dispatch = useDispatch();
    const alertRef = useRef(null);
    const history = useHistory();
    const { reservationId } = useParams();
    const isCancelled = reservation?.reservation?.reservationStatus === 'CANCELLED';
    const isEditing = reservation?.reservation?.reservationStatus === 'PENDING' && !!reservationId;
    const showContractPrice = useSelector((state) => state.globalStates.showContractPriceForReservation);

    const handleAlert = () => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert()
    }
    const getReservationData = async (reservationId) => {
        let reservationEntriesDisplay = [];
        setProcessing(true)
        // if there is reservationId, then user navigated from order history page, read data from getReservationById api response
        // else user navigated from reservation page, read data from updateReservationApi response
        try {
            if (reservationId) {
                const data = await dispatch(getReservationById({ userId: userInfo?.uid, reservationId: reservationId })).unwrap();
                reservationEntriesDisplay = data?.reservationEntries?.map(createDisplayEntry).filter(entry => entry !== null) || [];
            } else {
                reservationEntriesDisplay = reservation?.reservationEntries?.map(createDisplayEntry).filter(entry => entry !== null) || [];
            }
            setDisplayReservationData(reservationEntriesDisplay);
        } catch (err) {
            handleAlert()
        } finally {
            setProcessing(false);
        }
    };

    /** Get names from ProductAPI. @type {{item.code : productName}} | {} */
    const productNameMap = reservationProducts?.length && reservationProducts?.reduce((all, item) => {
        return { ...all, [item.code]: `${item.description}, ${item.ageGroup}` }
    }, {}) || {};

    const createDisplayEntry = (reservationEntry) => {
        if (!reservationEntry) return null;

        const { productId, deliveryAddressId } = reservationEntry;
        const address = shipToAddresses?.find(({ id }) =>  String(id) ===  String(deliveryAddressId));


        return {
          id: reservationEntry.reservationEntryId,
          doses: parseInt(reservationEntry?.quantity, 10) || 0,
          productId,
          productName: productNameMap && productNameMap[productId],
          listPriceValue: reservationEntry.productPrice.listPriceFormatted,
          listPrice: formattedPrice(reservationEntry.productPrice.listPriceFormatted),
          contractPriceValue: reservationEntry.productPrice.finalPriceFormatted,
          contractPrice: isRealTimePricingEnabled && showContractPrice ? formattedPrice(reservationEntry.productPrice.finalPriceFormatted) : UNAVAILABLE,
          address,
        };
      };
    
    useEffect(() => {
        getReservationData(reservationId)
    }, [])
    
	/** Get unique addresses. */
    const uniqueAddressIds = new Set();
    displayReservationData?.forEach(entry => {
        if (entry.address && entry.address.id) {
            uniqueAddressIds.add(entry.address.id);
        }
    });
    const uniqueAddresses = Array.from(uniqueAddressIds);

    const reservationEntriesToDisplay = uniqueAddresses.map((reservationAddressId, i) => {
      const reservationEntriesForThisAddress = displayReservationData
	  		?.filter(({address}) => address?.id == reservationAddressId);
      return <ReservationSummaryTable
				key={reservationAddressId}
				entries={reservationEntriesForThisAddress}
				PONumberValue={displayPONumberValue}
                showContractPrice={showContractPrice}
              />
  	});

    const getTitleText = () => {
        if (isCancelled) return `Reservation #${reservation?.reservation?.reservationNumber}`;
        else if (isEditing)  return `Update reservation #${reservation?.reservation?.reservationNumber}`;
        else return 'Place your reservation';
    };

	return (
        <>
            { <AlertMessage variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>}
            <Spinner processing={processing} />
                {isCancelled &&
                    <Box sx={ReservationCancelledBanner}>
                        <img src={crossIconRed} alt="cross icon" width="32" height="32" />
                        <Box data-testid="cancelledBannerMessageContent" display="flex" flexDirection="column" alignItems="flex-start" gap="10px" flex="1 0 0">
                            <Typography fontFamily="Aeonik Regular" fontSize="16px" fontWeight="700">This reservation has been cancelled</Typography>
                            <Typography fontFamily="Aeonik Regular" textAlign="left">
                                {`Cancelled by ${userData?.firstName} ${userData.lastName} on ${changeDateFormat(reservation?.reservation?.lastModifiedDate)}.`}
                            </Typography>
                        </Box>
                    </Box>
                }
            <ReservationSummaryContainer>
                <Box style={{flex: 1, padding:'24px 32px 32px 2px', gap: '30px', overflowX: 'auto'}}>
                    <TypographyTitle>{getTitleText()}</TypographyTitle>
                    {reservationEntriesToDisplay}
                </Box>
                {isCancelled ? 
                    <>
                        <CancelledReservationSummaryCard 
                            totalDoses={reservation?.reservation?.reservationPricingInfo?.totalDoses}
                            listPrice={reservation?.reservation?.reservationPricingInfo?.totalListPrice}
                            totalPrice={reservation?.reservation?.reservationPricingInfo?.totalPrice}
                        />
                        <Box display="flex" justifyContent="center">
                            <Button 
                                buttonType='mds-secondary' 
                                sx={{textTransform: 'none', padding: '16px 24px'}}
                                onClick={() => {history.push(`/${locale}/orders`)}}
                            >
                                Go back
                            </Button>
                        </Box>
                    
                    </> :
                    <ReservationSummaryCard
                        totalDoses={reservation?.reservation?.reservationPricingInfo?.totalDoses}
                        listPrice={reservation?.reservation?.reservationPricingInfo?.totalListPrice}
                        totalPrice={reservation?.reservation?.reservationPricingInfo?.totalPrice}
                        editReservationID ={reservationId}
                        handleAlert={handleAlert}
                        showContractPrice={showContractPrice}
                    />
                }
            </ReservationSummaryContainer>
        </>
	)
}

export default ReservationSummaryPage