import { createSlice } from "@reduxjs/toolkit";
import {
    getBaseStoreDataApi,
    getProductsApi,
    getReservationProductsApi,
    getProductDetailsApi,
    fetchUserApi,
    fetchOrders,
    getOrderCartApi,
    addToOrderCartApi,
    getAddressesApi,
    placeOrderAPI,
    getOrderableProductsApi
} from "./commerceThunkApi";
import { createPricingApi, fetchEligibleContractsApi, getPricingApi } from "../../utils/PricingService/PricingApi";
import {
    createReservationApi,
    getReservationById,
    updateReservationApi,
    cancelReservation
} from "../../utils/Reservation/ReservationApi";
import { getSAPUserByEmail, getUserByEmail, getUserById } from "../../utils/UserService/UserApis";
import { createCartApi, getOrderByIdApi, updateCartApi } from "../../utils/OrderService/OrderApi";
import { getCheckoutCart } from "../../utils/OrderService/CartApi";

const initialState = {
    cutOffDate: {},
    baseStoreData: {},
    products: {},
    productDetails: {},
    commerceUser: {},
    orders: {},
    orderCartData: {},
    addToOrderCartData: {},
    addresses: {},
    placeOrderData: {},
    reservationData: { reservation: {}, reservationEntries: [] }, // To build request body to send to /confirm API
    displayReservationData: { reservationEntries: [], PONumberValue: '' }, // Temp 02/02/24: Only to display data in Rservation Summary Page for now
    reservationPrices: {},
    reservationProducts: {},
    reservation: {},
    orderableProducts: [],
    userData: {},
    priceContracts: [],
    pricingData: {},
    sapUserData: {},
    cart:{},
    cartData: {},
    checkoutCartData: {},
    currentOrderData:{},
    selectedPaymentMethod: {}
}

export const commerceSlice = createSlice({
    name: "commerce",
    initialState,
    reducers: {
        setOrderCartData: (state, { payload }) => {
            state.orderCartData = payload;
        },
        setReservationData: (state, { payload }) => {
            state.reservationData = {
                ...state.reservationData,
                ...payload
            };
        },
        setCartData: (state, { payload }) => {
            state.cartData = {
                ...state.cartData,
                ...payload
            };
        },
        setDisplayReservationData: (state, { payload }) => {
            state.displayReservationData = {
                ...state.displayReservationData,
                ...payload
            };
        },
        setUserData: (state, { payload }) => {
            state.userData = {
                ...state.userData,
                ...payload
            };
        },
        setCurrentOrderData: (state, { payload }) => {
            state.currentOrderData = {
                ...state.currentOrderData,
                ...payload
            };
        },
        setSelectedPaymentMethod: (state, { payload }) => {
            state.selectedPaymentMethod = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addToOrderCartApi.fulfilled, (state, { payload }) => {
            state.addToOrderCartData = payload;
        });

        builder.addCase(getOrderCartApi.fulfilled, (state, { payload }) => {
            state.orderCartData = payload;
        });

        builder.addCase(getProductsApi.fulfilled, (state, { payload }) => {
            state.products = payload;
        });

        builder.addCase(getReservationProductsApi.fulfilled, (state, { payload }) => {
            state.reservationProducts = payload;
        });

        builder.addCase(getOrderableProductsApi.fulfilled, (state, { payload }) => {
            state.orderableProducts = payload;
        });

        builder.addCase(getProductDetailsApi.fulfilled, (state, { payload }) => {
            state.productDetails = payload;
        });

        builder.addCase(getBaseStoreDataApi.fulfilled, (state, { payload }) => {
            state.baseStoreData = payload;
        });

        builder.addCase(fetchUserApi.fulfilled, (state, { payload }) => {
            state.commerceUser = payload;
        });

        builder.addCase(getUserByEmail.fulfilled, (state, { payload }) => {
            state.userData = payload;
        });

        builder.addCase(getSAPUserByEmail.fulfilled, (state, { payload }) => {
            state.sapUserData = payload;
        });

        builder.addCase(getUserById.fulfilled, (state, { payload }) => {
            state.userData = payload;
        });

        builder.addCase(createReservationApi.fulfilled, (state, { payload }) => {
            state.reservation = payload;
        });

        builder.addCase(fetchEligibleContractsApi.fulfilled, (state, { payload }) => {
            state.priceContracts = payload;
        });

        builder.addCase(updateReservationApi.fulfilled, (state, { payload }) => {
            state.reservation = payload;
        });

        builder.addCase(getReservationById.fulfilled, (state, { payload }) => {
            state.reservation = payload;
        });

        builder.addCase(cancelReservation.fulfilled, (state, { payload }) => {
            state.reservation = payload;
        });

        builder.addCase(createCartApi.fulfilled, (state, { payload }) => {
            state.cart = payload;
        });

        builder.addCase(updateCartApi.fulfilled, (state, { payload }) => {
            state.cart = payload?.data;
        });

        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
        });

        builder.addCase(getOrderByIdApi.fulfilled, (state, action) => {
            state.currentOrderData = action.payload;
        });

        builder.addCase(getAddressesApi.fulfilled, (state, action) => {
            state.addresses = action.payload;
        });

        builder.addCase(placeOrderAPI.fulfilled, (state, { payload }) => {
            state.placeOrderData = payload;
        });

        builder.addCase(setReservationData, (state, { payload }) => {
            state.reservationData = payload;
        });

        builder.addCase(createPricingApi.fulfilled, (state, { payload }) => {
            state.reservationPrices = payload;
        });

        builder.addCase(setDisplayReservationData, (state, { payload }) => {
            state.displayReservationData = payload;
        });

        builder.addCase(getPricingApi.fulfilled, (state, { payload }) => {
            state.pricingData = payload;
        });

        builder.addCase(getCheckoutCart.fulfilled, (state, { payload }) => {
            state.checkoutCartData = payload;
        });
    }
})

export const { setOrderCartData, setCartData, setReservationData, setDisplayReservationData, setCurrentOrderData, setSelectedPaymentMethod } = commerceSlice.actions

export default commerceSlice.reducer
