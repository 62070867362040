import { ModalComponent } from "./index";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {BLOCKS} from "@contentful/rich-text-types";
import {Box, Divider, Typography} from "@mui/material";
import { generateButtonLinkComponent } from "../../utils/commonUtils";

export const AffiliationModal = ({isAffiliationModalOpen, setIsAffiliationModalOpen, staticContent, isSingleGPOAccount} )=>{

    const modalOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Box mb={2}>
                    <Typography >{children}</Typography>
                </Box>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <Box mb={2}>
                    <Typography variant="h3">{children}</Typography>
                </Box>
            ),
        }
    };

    return(
        <ModalComponent
            openModal={isAffiliationModalOpen}
            closeModalHandler={() => setIsAffiliationModalOpen(false)}
            style={{ width: isSingleGPOAccount? '50%' : '45%', borderRadius: '12px', padding: '40px', textAlign: 'left'}}
            modalContent={
                isSingleGPOAccount ?
                    <Box>
                        {documentToReactComponents(staticContent?.pricingReportSingleAccountModalMessage, modalOptions)}
                        {generateButtonLinkComponent('contactUsBtn', 'contact-us', staticContent?.contactUsButtonLabel)}
                    </Box>
                    :
                    <Box>
                        {documentToReactComponents(staticContent?.pricingReportMultipleAccountModalMessageI, modalOptions)}
                        {generateButtonLinkComponent('goToAccountBtn', 'my-profile/affiliations', staticContent?.goToAccountButtonLabel)}
                        <Box my={5}>
                            <Divider orientation="horizontal" variant="middle" />
                        </Box>
                        {documentToReactComponents(staticContent?.pricingReportMultipleAccountModalMessageII, modalOptions)}
                        {generateButtonLinkComponent('contactUsBtn', 'contact-us', staticContent?.contactUsButtonLabel)}
                    </Box>
            }
        />
    )
}