import { PaymentErrorCodes } from "./errorCodes";

export const PaymentErrorMessages = {
    [PaymentErrorCodes.CREATE.RESOURCE_QUOTA_EXCEEDED]: "You have reached the 10 maximum payment methods. Please delete a payment method to add a new one.",
    [PaymentErrorCodes.CREATE.INVALID_REQUEST_PARAMETERS]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.CREATE.UNEXPECTED_ERROR]: "We have encountered technical difficulties. Please refresh the page to try again.",
    
    [PaymentErrorCodes.LIST.INVALID_REQUEST_PARAMETERS]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.LIST.UNEXPECTED_ERROR]: "We have encountered technical difficulties. Please refresh the page to try again.",
    
    [PaymentErrorCodes.UPDATE.INVALID_REQUEST_PARAMETERS]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.UPDATE.RESOURCE_NOT_FOUND]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.UPDATE.UNEXPECTED_ERROR]: "We have encountered technical difficulties. Please refresh the page to try again.",
    
    [PaymentErrorCodes.DESCRIBE.RESOURCE_NOT_FOUND]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.DESCRIBE.UNEXPECTED_ERROR]: "We have encountered technical difficulties. Please refresh the page to try again.",
    
    [PaymentErrorCodes.DELETE.RESOURCE_NOT_FOUND]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
    [PaymentErrorCodes.DELETE.INVALID_REQUEST_PARAMETERS]: "We have encountered technical difficulties. Please contact us at 1-866-MODERNA.",
};
