import * as PaymentErrors from './PaymentsService/errorMessages';
import * as OrderErrors from './OrderService/errorMessages';

const errorMessages = {
  ...PaymentErrors.PaymentErrorMessages,
  ...OrderErrors.OrderErrorMessages,
};

export const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || 'An error occurred, the issue may be temporary. Please try again.';
};
