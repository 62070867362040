import { Box, Typography } from "@mui/material";
import React from "react";
import Syringe70291 from "../../assets/images/HomePageOrderProducts/70291-productCard-syringe-12y.png";
import Vial70289 from "../../assets/images/HomePageOrderProducts/70289-productCard-vial-12y.png";
import Vial70588 from "../../assets/images/HomePageOrderProducts/70588-productCard-vial-6mo.png";

const productTextBoxtyles = {
  backgroundColor: "primary.blue",
  color: "neutral.white",
  padding: "4px",
  borderRadius: "4px",
};

export const OrderProductTileV2 = ({
  index,
  productCode,
  ageGroup,
  description,
  iconImage,
  yearGroup
}) => {
  const productNameMapper = {
    'Single Dose Vial': 'Single-dose Vial',
    'Single Dose Prefilled Syringe': 'Pre-filled Syringe',
  }

  const productAgeGroupMapper = {
    'Age 6mo-11yrs': 'Ages 6m-11y'
  }

  const productImages = {
    '70291': Syringe70291,
    '70289': Vial70289,
    '70588': Vial70588
  }

  return (
    <Box
      data-testid="HeadingTestProductTile"
      width={{ xs: '150px', sm: "170px", lg: "192px" }}
      height={{ xs: '190px', sm: "170px", lg: "205px" }}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        width={{ xs: "120px", sm: "122px", lg: "160px" }}
        height={{ xs: "120px", sm: "122px", lg: "160px" }}
        display={"flex"}
        justifyContent={"center"}
        id={`squareCard-${index}`}
        sx={{ overflow: "hidden" }}
      >
        <Box
          component={"img"}
          width={{ xs: "110px", sm: "115px", lg: "150px" }}
          height={{ xs: "110px", sm: "115px", lg: "150px" }}
          src={productImages[productCode] || iconImage}
          alt={description}
          style={{ objectFit: "contain" }}
          sx={{ overflow: "hidden" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "6px",
        }}
        gap={'10px'}
      >
        <Box color="primary.darkBlue" margin='auto'>
          <Typography variant={"p4"} fontSize={"14px"}>
            {productNameMapper[description]}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          gap={"6px"}
        >
          <Box sx={productTextBoxtyles}>
            <Typography variant={"p4"} textAlign={"center"} fontSize={{xs: '10px', md: '13px'}}>
              {yearGroup}
            </Typography>
          </Box>
          <Box sx={productTextBoxtyles}>
            <Typography variant={"p4"} textAlign={"center"} fontSize={{xs: '10px', md: '13px'}}>
              {productAgeGroupMapper[ageGroup] || ageGroup}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
