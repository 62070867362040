import { Box, Typography } from "@mui/material";
import React from "react";
import {getAnalyticsProductDataAttributes} from "../../utils/Helper";

export const OrderRelatedProductsCard = ({ item }) => {
  const filteredImages = item?.target?.images?.filter(image => {
    return image?.format === "Hover" && image?.imageType === "PLP";
  });
  return (
    <Box {...getAnalyticsProductDataAttributes(item?.target)} xs={12} sm={4} color={'primary.main'} display={{xs:'grid', sm:'flex'}} gap={1} borderRadius={'16px'} border={'1px solid'} borderColor={'neutral.gray6'} borderTop={'7px solid'} borderTopColor={'secondary.graphical'} sx={{'&:hover': {boxShadow: "0px 3px 16px #00000029"}}}>
      <Box xs={12} sm={5}>
        <Box display={'grid'} p={'20px 10px'} justifyContent={'center'}>
          <Box borderRadius={'50%'} width='120px' height='120px' boxShadow={"0px 1px 6px #00000029"}
            p={"12px 22px"}>
            <Box component={'img'} src={filteredImages[0].url} sx={{height: "100%", width: "100%"}} data-testid="productImage"></Box>
          </Box>
          <Typography variant={'p2'} textAlign={'center'} mt={'10px'} data-testid="ageGroup">{item?.target?.ageGroup}</Typography>
        </Box>
      </Box>
      <Box xs={12} sm={7}>
        <Box display={'grid'} p={{xs:'20px 40px', sm:'20px 10px'}} gap={1} height={'100%'}>
          <Typography variant={'h5'} data-testid="productName">{item?.target?.name}</Typography>
          <Typography variant={'p2'} component={"p"} data-testid="productSummary">{item?.target?.summary}</Typography>
          <Box display={'flex'} gap={1} mt={1}>
            <Typography variant={'h5'} data-testid="priceValue">{item?.target?.dosePrice?.formattedValue}</Typography>
            {item?.target?.discountedPrice &&
              <Typography data-testid="discountedPrice" variant={'p2'} sx={{textDecoration:'line-through', color:'neutral.gray1'}}>{item?.target?.defaultDosePrice?.formattedValue}</Typography>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
