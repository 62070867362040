import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { useState, useCallback } from "react"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { styled } from "@mui/material/styles";
import { validateFields } from "../../utils/Helper"
import { Button } from "../Button"
import { Checkbox } from "../Checkbox"
import { Radio } from "../Radio"
import { TextInput } from "../TextInput"
import useMediaQuery from '@mui/material/useMediaQuery';
import { contactUsEmailApi } from "../../utils/contactUsApi";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

const FlexGrow = styled(Box)(({ theme: { breakpoints } }) => `
    margin-top: 18px;
    width: 100%;
   ${[breakpoints.up('md')]} {
        width: 50%;
    }
`)

const RadioContainer = styled(Box)(({ theme: { breakpoints } }) => `
    display: flex;
    margin-right: 8px;
    flex-wrap: wrap;
    ${[breakpoints.down('sm')]} {
        flex-direction: column;
    }
`)

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
    width: 100%;
    margin-top: 25px !important;
    ${[breakpoints.up('md')]} {
        width: 80%;
    }
    ${[breakpoints.down('sm')]} {
        margin: 20px 0px !important;
    }
`)

const initialValues = {
    fullname: { value: "", error: false, required: true },
    email: { value: "", error: false, required: true },
    phone: { value: "", error: false, required: true },
    organization: { value: "", error: false, required: true },
    contactMethod: { value: "Email", error: false, required: true },
    consented: { value: false, error: false, required: true },
    supportCategory: { value: "Contract and Billing", error: false, required: true }
}

export const ContactUsForm = ({ staticContent }) => {
    const [values, setValues] = useState(initialValues)
    const [submitStatus, setSubmitStatus] = useState("not-submitted")
    const desktop = useMediaQuery('(min-width:500px)')
    const locale = useSelector((state) => state.globalStates.locale);

    const { executeRecaptcha } = useGoogleReCaptcha()
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages)

    const invalidEmailFormatData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_EMAIL_FORMAT");
    const invalidEmailFormatError = invalidEmailFormatData && invalidEmailFormatData[0]?.fields?.message;

    const invalidPhoneFormatData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_PHONE_FORMAT");
    const invalidPhoneFormatError = invalidPhoneFormatData && invalidPhoneFormatData[0]?.fields?.message;

    const missingNameData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_FULL_NAME");
    const missingNameError = missingNameData && missingNameData[0]?.fields?.message;

    const missingOrganizationData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "MISSING_ORGANIZATION");
    const missingOrganizationError = missingOrganizationData && missingOrganizationData[0]?.fields?.message;

    const handleInputChange = (e) => {
        if (e.target.type === "checkbox")
            setValues(oldValues => ({ ...oldValues, [e.target.name]: { value: !oldValues.consented.value, error: "", required: values[e.target.name].required, label: values[e.target.name].label } }))
        else
            setValues((oldValues) => ({ ...oldValues, [e.target.name]: { value: e.target.value.trimStart(), error: "", required: values[e.target.name].required, label: values[e.target.name].label } }))
    }

    const validateInput = () => {
        const objectKeys = Object.keys(values)
        const validatedResult = { ...values }
        let isValid = true
        objectKeys.forEach(key => {
            if (validatedResult[key].required && !validatedResult[key].value) {
                validatedResult[key].error = true
                isValid = false
            }
            else if (key === "email" && !validateFields("email", validatedResult[key].value)) {
                validatedResult[key].error = true
                isValid = false
            }
            else if (key === "phone" && !validateFields("phone", validatedResult[key].value)) {
                validatedResult[key].error = true
                isValid = false
            }
        })
        setValues(validatedResult)

        if (!isValid) {
            var fe = new CustomEvent("form_error_js", {
                detail: {
                    form_name: "not brand aligned|hcp|contact us form",
                    form_type: "contact us form|non-dynamic form"
                }
            });
            document.dispatchEvent(fe)
        }

        return isValid
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('contactus');
        return token
    }, [executeRecaptcha]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validateInput()) return
        setSubmitStatus("submitting")
        try {
            const recaptchaToken = await handleReCaptchaVerify()
            const reqBody = {
                contactMethod: values?.contactMethod?.value,
                organization: values?.organization?.value,
                consented: values?.consented?.value,
                supportCategory: values?.supportCategory?.value,
                email: values?.email?.value,
                fullname: values?.fullname?.value,
                phone: values?.phone?.value,
                recaptchaToken
            }
            await contactUsEmailApi(reqBody)
            setSubmitStatus("submitted")

            var fc = new CustomEvent("form_complete_js", {
                detail: {
                    form_name: "not brand aligned|hcp|contact us form",
                    form_type: "contact us form|non-dynamic form"
                }
            });
            document.dispatchEvent(fc)

        } catch (error) {
            console.log("error", error)
        }
        finally {
            setSubmitStatus((oldValue) => oldValue !== "submitted" ? "not-submitted" : oldValue)
        }
    }

    const handleReset = () => {
        setValues((_) => ({ ...initialValues }))
        setSubmitStatus("not-submitted")
    }

    return (
        <Box m='50px 0' p={{xs:'0 32px', sm:'0 94px'}} data-testid='contactusForm'>
            {submitStatus === "submitted" ?
                <Box textAlign={'center'}>
                    {desktop ?
                        <img src={staticContent?.formSubmitted?.icon?.fields?.file?.url} alt="" width="65px" height="65px" /> :
                        <img src={staticContent?.formSubmitted?.icon?.fields?.file?.url} alt="" width="116px" height="116px" />
                    }
                    <Typography variant={'h2'} color='primary.main' mt='28px'>{staticContent?.formSubmitted?.headline}</Typography>
                    <Typography variant={'p1'} color='primary.main' mt='22px'>{documentToReactComponents(staticContent?.formSubmitted?.message)}</Typography>
                    <Button sx={{ marginTop: "18px", '@media screen and (max-width: 500px)': { marginTop: '40px' } }} onClick={handleReset}>START NEW MESSAGE</Button>
                </Box>
                :
                <div data-form-name="not brand aligned|hcp|contact us form" data-form-type="contact us form|non-dynamic form">
                    <Typography variant={'h2'} color='primary.main' data-testid='contactusFormHeadline'>{staticContent?.form?.headline}</Typography>
                    <Typography variant={'p1'} color='primary.main' lineHeight='22px'mt='8px' data-testid='contactusFormMsg'>{documentToReactComponents(staticContent?.form?.message)}</Typography>
                    <Box display='flex' flexWrap='wrap' alignItems='center'>
                        <FlexGrow>
                            <StyledTextInput
                                data-testid='fullname'
                                required
                                focused
                                placeholder={staticContent?.form?.fullNamePlaceholder}
                                label={staticContent?.form?.fullNameLabel}
                                inputType="text"
                                varient="standard"
                                name="fullname"
                                onChange={handleInputChange}
                                error={values?.fullname?.error}
                                helperText={values?.fullname?.error ? missingNameError : ""}
                            />
                            <StyledTextInput
                                data-testid='email'
                                required
                                focused
                                placeholder={staticContent?.form?.emailPlaceholder}
                                label={staticContent?.form?.emailLabel}
                                inputType="text"
                                varient="standard"
                                name="email"
                                onChange={handleInputChange}
                                error={values?.email?.error}
                                helperText={values?.email?.error ? invalidEmailFormatError : ''}
                            />
                            <StyledTextInput
                                data-testid='phone'
                                required
                                focused
                                placeholder={staticContent?.form?.phonePlaceholder}
                                label={staticContent?.form?.phoneLabel}
                                inputType="text"
                                varient="standard"
                                name="phone"
                                onChange={handleInputChange}
                                error={values?.phone?.error}
                                helperText={values?.phone?.error ? invalidPhoneFormatError : ""}
                            />
                            <StyledTextInput
                                data-testid='organization'
                                required
                                focused
                                placeholder={staticContent?.form?.organizationPlaceholder}
                                label={staticContent?.form?.organizationLabel}
                                inputType="text"
                                varient="standard"
                                name="organization"
                                onChange={handleInputChange}
                                error={values?.organization?.error}
                                helperText={values?.organization?.error ? missingOrganizationError : ""}
                            />
                        </FlexGrow>
                        <FlexGrow>
                            <Typography className="question" variant={'h5'} mt='12px' color='neutral.main'>{staticContent?.form?.preferredMethodLabel}<Typography variant={'span'} ml={1} sx={{ color: "primary.red" }}>*</Typography></Typography>
                            {staticContent?.form?.preferredMethodList &&
                                <RadioContainer>
                                    <Radio
                                        className="answer"
                                        id="email"
                                        label={staticContent?.form?.preferredMethodList[0]}
                                        name="contactMethod"
                                        value="Email"
                                        checked={values?.contactMethod?.value === "Email"}
                                        onChange={handleInputChange}
                                    />
                                    <Radio
                                        className="answer"
                                        data-testid='radiophone'
                                        id="phone"
                                        label={staticContent?.form?.preferredMethodList[1]}
                                        name="contactMethod"
                                        value="Phone"
                                        checked={values?.contactMethod?.value === "Phone"}
                                        onChange={handleInputChange}
                                    />
                                </RadioContainer>
                            }
                            <div style={{ marginTop: "32px" }}>
                                <Typography className="question" variant={'h5'} mt='12px' color='neutral.main'>{staticContent?.form?.supportCategoryLabel}<Typography variant={'span'} ml={1} sx={{ color: "primary.red" }}>*</Typography></Typography>
                                {staticContent?.form?.supportCategoryList &&
                                    <RadioContainer>
                                        <Radio
                                            className="answer"
                                            data-testid='billing'
                                            id="contract_and_billing"
                                            label={staticContent?.form?.supportCategoryList[0]}
                                            name="supportCategory"
                                            value="Contract and Billing"
                                            checked={values?.supportCategory?.value === "Contract and Billing"}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            className="answer"
                                            data-testid='assistance'
                                            id="order_assitance"
                                            label={staticContent?.form?.supportCategoryList[1]}
                                            name="supportCategory"
                                            value="Order Assistance"
                                            checked={values?.supportCategory?.value === "Order Assistance"}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            className="answer"
                                            data-testid='inquiry'
                                            id="product_inquiry" 
                                            label={staticContent?.form?.supportCategoryList[2]}
                                            name="supportCategory"
                                            value="Product Inquiry"
                                            checked={values?.supportCategory?.value === "Product Inquiry"}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            className="answer"
                                            data-testid='accountinfo'
                                            id="account_info"
                                            label={staticContent?.form?.supportCategoryList[3]}
                                            name="supportCategory"
                                            value="Account Information"
                                            checked={values?.supportCategory?.value === "Account Information"}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            className="answer"
                                            data-testid='general'
                                            id="general_inquiry"
                                            label={staticContent?.form?.supportCategoryList[4]}
                                            name="supportCategory"
                                            value="General Inquiry"
                                            checked={values?.supportCategory?.value === "General Inquiry"}
                                            onChange={handleInputChange}
                                        />
                                        <Radio
                                            className="answer"
                                            data-testid='other'
                                            id="other"
                                            label={staticContent?.form?.supportCategoryList[5]}
                                            name="supportCategory"
                                            value="Other"
                                            checked={values?.supportCategory?.value === "Other"}
                                            onChange={handleInputChange}
                                        />
                                    </RadioContainer>
                                }
                            </div>
                            <Box display={{xs:'block', sm:'flex'}} flexWrap='wrap' justifyContent='center' alignItems='center' mt='30px' textAlign='center'>
                                <Checkbox
                                    data-testid='consented'
                                    id="contact_us_checkBox"
                                    onChange={handleInputChange}
                                    name="consented"
                                    label={staticContent?.form?.consentMessageLabel}
                                    error={values?.consented?.error && !values?.consented?.value}
                                />
                                <Box data-testid='privacy' mt='-2px' sx={{cursor:'pointer'}} position='relative' left={{xs:'30px', sm:'-11px'}} mb={{xs:'32px', sm:'0px'}}><a href={`/${locale}/privacy-policy`} rel="noreferrer" target="_blank">{staticContent?.form?.consentLink?.fields?.label}</a>.<Typography variant={'span'} pl={1} sx={{ color: "primary.red" }}>*</Typography></Box>
                                <Box mt='0'>
                                    <Button onClick={handleSubmit} disabled={submitStatus === "submitting"} data-testid='sendBtn'>SEND</Button>
                                </Box>
                            </Box>
                        </FlexGrow>
                    </Box>
                </div>
            }
        </Box>
    )
}