import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextInput, AlertMessage } from "..";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from '@contentful/rich-text-types';
import { recoverPasswordApi } from "../../utils/commerceOktaApi";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { validateFields } from "../../utils/Helper";
import { useRef } from "react";
import { loadResetPasswordStaticContent } from "../../features/contentful/contentfulThunkApi";
import { Typography, Box } from "@mui/material";
import { resetPasswordApi } from "../../utils/IdentityService/EmailVerificationApi";

const StyledTextInput = styled(TextInput)(({ theme: { breakpoints } }) => `
  height: 70px;
  width: 100%;
  margin-top: 10% !important;
  ${[breakpoints.down('sm')]} {
    margin-top: 20% !important;
  }
`);

const ButtonContainer = styled(Box)(({ theme: { breakpoints } }) => `
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin: 15% auto 70px auto;
  ${[breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    height: 90px;
    justify-content: space-between;
    width: 60%;
  }
`);

export const EmailFactor = ({ setComp }) => {

  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const history = useHistory();
  const { resetPasswordContent } = useSelector((store) => store?.contentful);
  const { staticContent = [] } = resetPasswordContent;
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const locale = useSelector((state) => state.globalStates.locale);
  const alertRef = useRef(null)

  const { executeRecaptcha } = useGoogleReCaptcha()
  
  const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
  const invalidEmailFormatErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "INVALID_EMAIL_FORMAT");
  const invalidEmailFormatError = invalidEmailFormatErrorData && invalidEmailFormatErrorData[0]?.fields?.message;

  const userNotFoundErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "USER_NOT_FOUND");
  const userNotFoundError = userNotFoundErrorData && userNotFoundErrorData[0]?.fields?.message;

  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const isRegistrationEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(resetPasswordContent).length === 0) {
      dispatch(loadResetPasswordStaticContent());
    }
  }, []);

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setInvalidEmail("");
  };

  const validate = () => {
    let isValid = true;
    let emailError;
    if (!email) {
      isValid = false;
      emailError = "Please enter your email Address.";
    }

    if (!validateFields("email", email)) {
      isValid = false;
      emailError = invalidEmailFormatError;
    }

    setInvalidEmail(emailError);

    return isValid;
  };

  const cancel = () => {
    history.push(`/${locale}/`);
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha)
      return;

    const token = await executeRecaptcha('request_reset_password');
    return token
  }, [executeRecaptcha]);

  const handleNext = async (e) => {
    e.preventDefault();
    if (validate()) {
      const recaptchaToken = await handleReCaptchaVerify()
      const reqObj = {
        email,
        recaptchaToken
      }
      setProcessing(true);
      try {
        const res = await recoverPasswordApi(reqObj);
        if (res?.status === 200) {
          setComp("recoveryLink");
          localStorage.setItem("recoverPassEmail", email)
          setProcessing(false);
        }
      } catch (err) {
        if(err?.response?.data?.errorCode === "E0000007"){
          setErrorMsg(userNotFoundError);
        }else{
          setErrorMsg(generalApiError);
        }
        alertRef.current.openAlert()
        setProcessing(false);
      }
    }
  }

  const handleNextButton = async (e) => {
    e.preventDefault();
    if (validate()) {
      setProcessing(true);
      try {
        const res = await resetPasswordApi({emailAddress: email});
        if (res?.status === 200) {
          setComp("recoveryLink");
          localStorage.setItem("recoverPassEmail", email)
          setProcessing(false);
        }
      } catch (err) {
          setErrorMsg(generalApiError);
        }
        alertRef.current.openAlert()
        setProcessing(false);
      }
  }

  return (
    <div data-form-name="not brand aligned|hcp|reset password form" data-form-type="account management form|non-dynamic form">
      <AlertMessage id="errorMessage" variant={"filled"} type={"error"} message={errorMsg} ref={alertRef}/>
      <Typography variant={'h1'} textAlign='center' color='primary.main' id="headline" data-testid='title'>{staticContent?.headline}</Typography>
      <Typography variant={'p1'} textAlign='center' mt='20px' id="requestResetMessage" data-testid='message'>{documentToReactComponents(staticContent?.message, options)}</Typography>
      <StyledTextInput
        required
        focused
        placeholder="Enter email"
        id="email"
        label="Email"
        inputType="email"
        variant="standard"
        value={email}
        sx={{ marginBottom: "20px" }}
        helperText={invalidEmail}
        error={!!invalidEmail}
        onChange={handleEmail}
        data-testid='input'
      />
      <ButtonContainer>
        <Button id="requestResetCancel" buttonType="secondary" onClick={cancel} data-testid='cancelBtn'>Cancel</Button>
        <Button id="requestResetNext" buttonType="primary" onClick={!isRegistrationEnabled ? handleNext : handleNextButton} loading={processing} disabled={processing} data-testid='nextBtn'>Next</Button>
      </ButtonContainer>
    </div>
  )
};
