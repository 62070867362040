import { createSelector as reselectCreateSelector } from 'reselect';
import { buildWarning } from './warning';
const cacheContainer = {
  cache: new WeakMap()
};
const missingInstanceIdWarning = buildWarning(['MUI: A selector was called without passing the instance ID, which may impact the performance of the grid.', 'To fix, call it with `apiRef`, e.g. `mySelector(apiRef)`, or pass the instance ID explicitly, e.g `mySelector(state, apiRef.current.instanceId)`.']);
export const createSelector = (...args) => {
  const selector = (...selectorArgs) => {
    var _cache$get, _cache$get3;
    const [stateOrApiRef, instanceId] = selectorArgs;
    const isApiRef = !!stateOrApiRef.current;
    const cacheKey = isApiRef ? stateOrApiRef.current.instanceId : instanceId != null ? instanceId : {
      id: 'default'
    };
    const state = isApiRef ? stateOrApiRef.current.state : stateOrApiRef;
    if (process.env.NODE_ENV !== 'production') {
      if (cacheKey.id === 'default') {
        missingInstanceIdWarning();
      }
    }
    const {
      cache
    } = cacheContainer;
    if (cache.get(cacheKey) && (_cache$get = cache.get(cacheKey)) != null && _cache$get.get(args)) {
      var _cache$get2;
      // We pass the cache key because the called selector might have as
      // dependency another selector created with this `createSelector`.
      return (_cache$get2 = cache.get(cacheKey)) == null ? void 0 : _cache$get2.get(args)(state, cacheKey);
    }
    const newSelector = reselectCreateSelector(...args);
    if (!cache.get(cacheKey)) {
      cache.set(cacheKey, new Map());
    }
    (_cache$get3 = cache.get(cacheKey)) == null ? void 0 : _cache$get3.set(args, newSelector);
    return newSelector(state, cacheKey);
  };

  // We use this property to detect if the selector was created with createSelector
  // or it's only a simple function the receives the state and returns part of it.
  selector.acceptsApiRef = true;
  return selector;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const unstable_resetCreateSelectorCache = () => {
  cacheContainer.cache = new WeakMap();
};