import { Box } from "@mui/material";
import { TypographyP1 } from "../../assets/fonts/typographyTheme";
import { PAYMENT_OPTIONS } from "../../constants";
import { Link } from "react-router-dom";

const isPaymentsEnabled = process.env.IS_PAYMENTS_ENABLED === 'true';

export const OrderNotes = ({ paymentOption, summaryData }) => {
    const hasPromptPay = summaryData?.promptPayEligible;
    const hasFullPromptPayInfo = hasPromptPay && summaryData?.promptPayTerms?.timePeriod;
    const selectedContract = summaryData?.contractInfo;

    const getPromptPayText = (hasFullPromptPayInfo, selectedContract) => {
        if(hasFullPromptPayInfo) {
            if (selectedContract?.contractId!=null) {
                return `**Your contract terms entitle you to a ${summaryData?.promptPayTerms?.promptPayDiscount}% discount if your payment
                 is received within ${summaryData?.promptPayTerms?.timePeriod} days of your invoice date.`
            } else {
                return `**Your order is eligible for a ${summaryData?.promptPayTerms?.promptPayDiscount}% discount if your payment 
                is received within ${summaryData?.promptPayTerms?.timePeriod} days of your invoice date.`
            }
        } else {
            return `**This order may be eligible for a pricing discount if payment is received within your contract payment terms. 
                See your specific payment terms for details.`
        }
    }

    const renderPromptPayText = () => {
        if(hasPromptPay){
            return getPromptPayText(hasFullPromptPayInfo, selectedContract);
        }
    }
        return(
            <Box minWidth={"350px"} maxWidth={"486px"} mt="24px">
                <TypographyP1>
                    *If your order is taxable, you will be billed for the sales and local tax as required by law.
                </TypographyP1>
                <TypographyP1>
                    {renderPromptPayText()}
                </TypographyP1>
                {
                    !hasFullPromptPayInfo &&
                    <TypographyP1 mt="7px">
                        Your payment terms (including due date) will be specified on your invoice.
                        If you are eligible for a prompt pay discount through a buying group affiliation and do not see it applied at checkout,
                        please ensure you have selected the correct contract affiliation or contact your buying group to confirm your contract terms and eligibility.
                    </TypographyP1>
                }
                { 
                    <TypographyP1 mt="7px">
                        Orders are processed immediately and cannot be modified or cancelled after being placed. 
                        We are currently experiencing high order demand and shipping times may be longer than usual. 
                        When your order ships you will receive an email with your tracking information.
                    </TypographyP1>
                }

                {PAYMENT_OPTIONS?.PAY_LATER === paymentOption && isPaymentsEnabled &&
                    <TypographyP1 mt="7px">
                        For bank wire transfers, see <Link to={`/invoicepay`} target="_blank">payment instructions</Link>.
                    </TypographyP1>
                }
                <TypographyP1 mt="7px">
                    Orders with multiple addresses will be separated into multiple shipments and invoiced individually.
                </TypographyP1>
                <TypographyP1 mt="7px">
                    Your <Link to="/my-profile/addresses" target="_blank" style={{color: "#0379B2"}}> Corporate Address </Link>will be listed as the Sold To address on your invoice.
                </TypographyP1>
            </Box>
        )
}