"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const is_production_domain_1 = require("./is-production-domain");
const SECOND_LEVEL_DOMAINS = new Set(['com.sg', 'org.tw', 'com.hk', 'com.tw', 'com.au']);
const FALLBACK_PROXY = 'https://metrics.modernatx.com';
const DEV_PROXY = 'https://dev.metrics.modernatx.com/';
const PROXIES = {
    'disease.education': 'https://metrics.disease.education'
};
function getProxyUrl() {
    var _a;
    const domain = getDomainFromLocation();
    if ((0, is_production_domain_1.isProductionDomain)()) {
        return PROXIES[domain] || FALLBACK_PROXY;
    }
    if (isLocalhost(domain)) {
        return (_a = process.env.LOCAL_PROXY) !== null && _a !== void 0 ? _a : DEV_PROXY;
    }
    return DEV_PROXY;
}
exports.default = getProxyUrl;
function getDomainFromLocation() {
    const url = new URL(window.location.href);
    const hostname = url.hostname;
    if (isLocalhost(hostname)) {
        return hostname;
    }
    // Split the hostname into parts
    const parts = hostname.split('.');
    // Determine the domain based on the parts and second-level domains
    let domain;
    if (parts.length > 2) {
        // Check if the domain ends with a known second-level domain
        const potentialSLD = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
        if (SECOND_LEVEL_DOMAINS.has(potentialSLD)) {
            domain = parts.slice(-3).join('.');
        }
        else {
            domain = parts.slice(-2).join('.');
        }
    }
    else {
        domain = hostname;
    }
    return domain;
}
function isLocalhost(hostname) {
    return (hostname === 'localhost' ||
        hostname.startsWith('127.') ||
        hostname === '[::1]' ||
        /^[0-9]+(?:\.[0-9]+){3}$/.test(hostname) || // IPv4 address
        /(?:[a-f0-9]*:){7}[a-f0-9]+/.test(hostname) // IPv6 address
    );
}
