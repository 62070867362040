import { useState } from 'react';
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Typography } from '@mui/material';
import { Button } from '../Button';
import { PAYMENT_METHOD_TYPES } from '../../constants';
import { describePaymentMethodApi, updatePaymentMethodApi } from '../../utils/PaymentsService/PaymentMethodApis';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { setSelectedPaymentMethod } from '../../features/commerce/commerceSlice';
import { TypographyH1, TypographyP1 } from '../../assets/fonts/typographyTheme';

const PaymentMethodForm = ({ setShowPaymentForm, paymentMethodId, handleErrorMsg, setPaymentMethodAdded }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [paymentMethodType, setPaymentMethodType] = useState('');

    const ACH_MANDATE_TEXT = `By clicking “Continue”, you authorize Moderna US, Inc to debit the bank account specified above for any amount owed for charges arising from your use of Moderna US, Inc's \
                          services and/or purchase of products from Moderna US, Inc, pursuant to Moderna US, Inc's website and terms, until this authorization is revoked.`;
    
    const CREDIT_CARD_MANDATE_TEXT = `By providing your card information, you allow Moderna US, Inc to charge your card for future payments in accordance with their terms.`;              

    const paymentElementOptions = {
        paymentMethodOrder: [PAYMENT_METHOD_TYPES.ACH],
        terms: {
            card: 'never',
            usBankAccount: 'never'
        }
    }

    const handlePaymentMethodTypeChange = (event) => {
        setPaymentMethodType(event.value.type);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
    
        if (!stripe || !elements) {
          return null;
        }

        await stripe.confirmSetup({
            elements,
            confirmParams: {},
            redirect: 'if_required'
        }).then(async ({ setupIntent, error }) => {
            if (error) {
                setProcessing(false);
                setErrorMessage(error.message);
            }
            else if (setupIntent) {
                try {
                    const updatePaymentMethodRequest = {
                        idempotencyToken: uuidv4(),
                        paymentMethodLifecycleDetails: {
                            externalPaymentMethodId: setupIntent?.payment_method
                        }
                    }
                    await updatePaymentMethodApi(updatePaymentMethodRequest, paymentMethodId);
                    const newPaymentMethod = await describePaymentMethodApi(paymentMethodId);
                    if (newPaymentMethod?.status === 200) {
                        let newPaymentMethodDetails = newPaymentMethod?.data?.paymentMethodDetails;
                        dispatch(setSelectedPaymentMethod(newPaymentMethodDetails));
                        setPaymentMethodAdded(true);
                    }
                } catch (error) {
                    const errorMessage = getErrorMessage(error?.response?.data?.errorCode);
                    handleErrorMsg(errorMessage);
                } finally {
                    setProcessing(false);
                    setShowPaymentForm(false);
                }
            } else {
                setProcessing(false);
                setErrorMessage("Something went wrong, please refresh the page and try again.")
            }
        })
    };

    return (
        <Box gap={4} display={'flex'} flexDirection={'column'}>
            <Box> 
                <TypographyH1>Add a payment method </TypographyH1>
                { paymentMethodType === PAYMENT_METHOD_TYPES.CARD && <TypographyP1>We only accept Visa, Mastercard, Amex, and Discover.</TypographyP1> }
            </Box>
            <form>
                <PaymentElement onChange={handlePaymentMethodTypeChange} options={paymentElementOptions} />
                {errorMessage && <Typography color={'#E22929'}>{errorMessage}</Typography>}
                <Box paddingTop={'16px'}>
                    <Typography color={'#6d6e78'} fontSize={'12px'} textAlign={'justify'} paddingBottom={'16px'}>{paymentMethodType === PAYMENT_METHOD_TYPES.ACH ? ACH_MANDATE_TEXT : CREDIT_CARD_MANDATE_TEXT}</Typography>
                    <Button 
                        buttonType='mds-primary' 
                        sx={{textTransform: 'none'}} 
                        disabled={!stripe} 
                        onClick={handleSubmit} 
                        loading={processing && !errorMessage}
                    >
                        Continue
                    </Button>
                </Box>
            </form>
        </Box>
    );
}

export default PaymentMethodForm;
