import React, { useEffect } from "react"
import { styled } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { loadCategoryHeroBannerComponentStaticContent } from "../../features/contentful/contentfulThunkApi";

const BannerContainer = styled('div')(
    ({backgroudnImageUrl,mobileBackgroundImageUrl,theme: { breakpoints, palette: { secondary } } }) => `
    background-color: ${secondary.main};
    background-image: url(${backgroudnImageUrl});
    background-size: cover;
    height: 480px;
    ${[breakpoints.down('lg')]} {
        height: auto;
    }
    ${[breakpoints.down('sm')]} {
        background-image: url(${mobileBackgroundImageUrl});
        height: 840px;
    }
    `
);
const bannerContainerSxStyle = {
    flexDirection: { xs: 'column', sm: 'row' },
    padding: { xs: '80px 0px 0px 36px', sm: '30px 20px 30px 32px', lg: '93px 20px 10px 130px' },
    alignItems: { xs: 'unset', sm: 'center' },
    justifyContent: { xs: 'space-between', sm: 'space-between' }
}

export const OrderProductsBanner = () => {  
    const dispatch = useDispatch()
    const { orderProductBannerComponentContent } = useSelector((store) => store?.contentful)
    useEffect(() => {
        if (orderProductBannerComponentContent && Object.keys(orderProductBannerComponentContent).length === 0) {
            dispatch(loadCategoryHeroBannerComponentStaticContent())
        }     
    }, [orderProductBannerComponentContent]);

    const backgroudnImageUrl=orderProductBannerComponentContent?.backgroundImage?.fields?.file?.url;
    const mobileBackgroundImageUrl=orderProductBannerComponentContent?.mobileBackgroundImage?.fields?.file?.url;
    const detailPageLink = orderProductBannerComponentContent?.callToActionLinkWithParams?.fields?.pageReference?.fields?.slug;
    const productId= orderProductBannerComponentContent?.callToActionLinkWithParams?.fields?.parameter?.id;
    const buttonLink= detailPageLink + '/'+ productId;
    return (
        <BannerContainer data-testid="OrderProductsBannerTest" backgroudnImageUrl={backgroudnImageUrl} mobileBackgroundImageUrl={mobileBackgroundImageUrl}>
            <Box sx={bannerContainerSxStyle}>
                <Typography variant='h1' m={{ md: "8px 390px 0px 0px", xs: "14px 56px 0px 0px", sm: "8px 57px 0px 0px", lg: "8px 700px 0px 0px" }} color={'neutral.black'} data-testid="HeadlineTest">{orderProductBannerComponentContent?.headline}
                </Typography>
                <Typography variant='h2' m={{ xs: "8px 50px 34px 0px", sm: "25px 50px 40px 0px", md: "25px 50px 20px 0px", lg: "25px 50px 40px 0px" }} color={'neutral.black'}>
                    {documentToReactComponents(orderProductBannerComponentContent?.message)}
                </Typography>
            </Box>
        </BannerContainer>
    )
}
