import React, { useState, useEffect } from "react"
import { styled, useTheme} from '@mui/material/styles';
// import { Button, Checkbox } from "../components";
// import { CustomMenu as AgeFilter } from "../components/CustomMenu";
// import SearchIcon from "../assets/images/search.svg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getCartsApi } from "../utils/ReservationApis";
import { ManageReservationBanner } from "../components/ManageReservationBanner";
import { AlertMessage, Spinner } from "../components";
import { useUserInfoContext } from "../contexts";
import { Link } from "react-router-dom";
import { changeDateFormat } from "../utils/Helper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRef } from "react";
import { loadManageReservationListStaticContent } from "../features/contentful/contentfulThunkApi";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { getBaseStoreDataApi } from "../features/commerce/commerceThunkApi";
import { Typography } from "@mui/material";

const Main = styled(Box)(({ theme: { breakpoints } }) => `
    max-width: 1050px;
    padding: 45px 0;
    margin: 0 auto;
    ${[breakpoints.down('sm')]} {
        width:85%;   
    }
`);

const ReservationContainer = styled(Box)(({ theme: { breakpoints } }) => `
    margin-top: 25px;
    min-height: 180px;
    ${[breakpoints.down('sm')]} {
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
          }
    }
`);

const ReservationTitle = styled('h2')(({ theme: { palette: { primary } }}) => `
    font-size: 18px;
    font-Family: 'Aeonik bold';
    color: ${primary.main};
`);

const Table = styled('table')(({ theme: { breakpoints } }) => `
    width: 100%;
    margin-top: 12px;
    ${[breakpoints.down('sm')]} {
        width:750px;
    }
`);

const Thead = styled('thead')(({ theme: { palette: { primary } }}) => `
    border-bottom: 1px solid ${primary.main};
`);

const tableHeader = [
    "CONFIRMATION N0.",
    "PRICE",
    "DATE PLACED",
    "MODIFIED"
]

const NoReservationText = styled('td')(({ theme: { palette: { primary } }}) => `
    color: ${primary.main};
    font-size: 16px;
    text-align: center;
    padding: 12px;
    opacity: 0.6;
`);
const NoReservationTextMobile = styled(Box)(({ theme: { palette: { primary } }}) => `
    color: ${primary.main};
    font-size: 16px;
    text-align: center;
    padding: 12px;
    opacity: 0.6;
    margin-top: -70px;
    margin-bottom: 50px;
`);

const Th = styled('th')(({ theme: { palette: { primary } }}) => `
    width: ${100 / tableHeader.length}%;
    padding: 8px 0;
    text-align: start;
    font-size: 12px;
    font-family:'Aeonik bold';
    color: ${primary.main};
`);

const Td = styled('td')(({ theme: { palette: { primary } }}) => `
    color: ${primary.main};
    padding: 8px 0;
`);
const Fixed = styled(Box)(({ theme: { palette: { neutral }, breakpoints }}) => `
    font-family:'Aeonik bold';
    ${[breakpoints.down('sm')]} {
        position: absolute;
        eft: auto;
        z-index: 10000px;
        background-color: ${neutral.white};
        width:150px;
    }   
`);

const DisclaimerContainer = styled(Box)(({ theme: { palette: { primary } }}) => `
    max-width: 700px;
    width: 100%;
    color: ${primary.red};
    font-size: 12px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    line-height: 16px;
`);

const alertCustomStyle = {
    top: "20px !important",
    marginBottom: "-20px !important",
    position: "relative !important",
    left: "0 !important"
};

const ManageReservation = () => {
    const { manageReservationListContent: staticContent } = useSelector(store => store?.contentful);
    const { baseStoreData } = useSelector((store) => store?.commerce);
    // console.log(baseStoreData, ":baseStoreData");
    const [reservation, setReservation] = useState({});
    const [openReservationCode, setOpenReservationCode] = useState('');
    const { userInfo } = useUserInfoContext() || '';
    const [errorMsg, setErrorMsg] = useState(localStorage.getItem("error"));
    const [processing, setProcessing] = useState(false);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const locale = useSelector((state) => state.globalStates.locale);

    const alertRef = useRef(null)

    const { globalErrorMessage } = useSelector(store=>store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);


    const dispatch = useDispatch();
    useEffect(() => {
        if (Object.keys(baseStoreData).length === 0) {
          dispatch(getBaseStoreDataApi({ baseSiteId: locale === 'en-DE' ? 'modernaDirect-DE' : 'modernaDirect-US' }));
        }
    }, [baseStoreData]);

    localStorage.removeItem("error");
    setTimeout(() => {
        setErrorMsg("");
    }, 5000);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (Object.keys(staticContent).length === 0)
            dispatch(loadManageReservationListStaticContent({ title: 'Manage Reservations List Component - ' + localeISOCode }
            ))
        if (localStorage.getItem("error")) {
            alertRef.current?.openAlert()
            localStorage.removeItem("error")
        }
    }, []);

    useEffect(() => {
        const getReservations = async () => {
            setProcessing(true)
            try {
                const userId = userInfo?.uid
                const { carts } = await getCartsApi(userId)
                const openReservations = carts?.filter((cart) => cart?.status === "PreBook")
                const canceledReservation = carts?.filter(cart => cart?.status === "CANCELLED")
                const reservations = {
                    openReservations,
                    canceledReservation,
                    reservationAvailable: openReservations?.length > 0 ?? false
                }
                openReservations.length && setOpenReservationCode(openReservations[0]?.code);
                setReservation(reservations)
                setProcessing(false)
            } catch (error) {
                setErrorMsg(generalApiError);
                setProcessing(false)
                alertRef.current?.openAlert()
            }
        }
        getReservations()
    }, [userInfo, generalApiError])

    return (
        <>
            <Spinner processing={processing} />
            <ManageReservationBanner />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={alertCustomStyle} ref={alertRef} />
            <Main>
                {/* <FilterContainer>
                    <FilterByText>FILTER BY</FilterByText>
                    <AgeFilter label="Age">
                    <MenuOptionChildren>
                            {options.map((option) =>
                                <div>
                                    <Checkbox
                                        label={option?.label}
                                        value={option?.value}
                                        onChange={(ele) => { console.log("selected ", ele) }}
                                    />
                                </div>
                            )}
                        </MenuOptionChildren>
                    </AgeFilter>
                    <AgeFilter label="Date">
                        <MenuOptionChildren>
                            {options.map((option) =>
                                <div>
                                    <Checkbox
                                        label={option?.label}
                                        value={option?.value}
                                        onChange={(ele) => { console.log("selected ", ele) }}
                                    />
                                </div>
                            )}
                        </MenuOptionChildren>
                    </AgeFilter>
                    <ResetText>Reset</ResetText>
                    <SearchContainer>
                        <SearchImg src={SearchIcon} alt="search icon"/>
                    </SearchContainer>
                </FilterContainer> */}
                <ReservationTitle style={{ position: "inherit" }} data-testid='title'>{staticContent?.openReservationsLabel} {reservation?.openReservations?.length > 0 && `(${reservation?.openReservations?.length})`}</ReservationTitle>
                <ReservationContainer>
                    <Table>
                        <Thead data-testid='tablehead'>
                            <tr>
                                <Th><Fixed>{staticContent?.confirmationNumberColumnLabel}</Fixed></Th>
                                { baseStoreData && !baseStoreData?.hidePrice && <Th>{staticContent?.priceColumnLabel}</Th> }
                                <Th>{staticContent?.datePlacedColumnLabel}</Th>
                                <Th>{staticContent?.dateModifiedColumnLabel}</Th>
                            </tr>
                        </Thead>
                        <tbody data-testid='tablebody'>
                            {reservation?.openReservations?.length > 0 ? reservation?.openReservations?.map((reservation) => (
                                <tr key={reservation?.code}>
                                    <Td><Fixed><Link to={`/${locale}/view-reservations/${reservation?.code}`}><Typography variant={'p1'} sx={{color:'secondary.textLinks'}}>{reservation?.code}</Typography></Link></Fixed></Td>
                                    { baseStoreData && !baseStoreData?.hidePrice && <Td>{reservation?.totalPrice?.formattedValue}</Td> }
                                    <Td>{changeDateFormat(reservation?.creationDate)}</Td>
                                    <Td>{changeDateFormat(reservation?.modifiedDate)}</Td>
                                </tr>
                            )) :
                                desktop && <tr><NoReservationText colSpan={4}>{staticContent?.noReservationsMessage}</NoReservationText></tr>
                            }
                        </tbody>
                    </Table>
                </ReservationContainer>
                {mobile && reservation?.openReservations?.length === 0 && <NoReservationTextMobile>{staticContent?.noReservationsMessage}</NoReservationTextMobile>}
                {reservation?.canceledReservation?.length > 0 &&
                    <div>
                        <ReservationTitle>{staticContent?.canceledReservationsLabel} ({reservation?.canceledReservation?.length})</ReservationTitle>
                        <ReservationContainer>
                            <Table>
                                <Thead>
                                    <tr>
                                        <Th><Fixed>{staticContent?.confirmationNumberColumnLabel}</Fixed></Th>
                                        { baseStoreData && !baseStoreData?.hidePrice && <Th>{staticContent?.priceColumnLabel}</Th> }
                                        <Th>{staticContent?.datePlacedColumnLabel}</Th>
                                        <Th>{staticContent?.dateModifiedColumnLabel}</Th>
                                    </tr>
                                </Thead>
                                <tbody>
                                    {reservation?.canceledReservation?.map((reservation) => (
                                        <tr key={reservation?.code}>
                                            <Td><Fixed><Link to={`/${locale}/view-reservations/${reservation?.code}`}><Typography variant={'p1'} sx={{color:'secondary.textLinks'}}>{reservation?.code}</Typography></Link></Fixed></Td>
                                            { baseStoreData && !baseStoreData?.hidePrice && <Td>{reservation?.totalPrice?.formattedValue}</Td> }
                                            <Td>{changeDateFormat(reservation?.creationDate)}</Td>
                                            <Td>{changeDateFormat(reservation?.modifiedDate)}</Td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </ReservationContainer>
                    </div>}
                <DisclaimerContainer>
                    {documentToReactComponents(staticContent?.disclaimerMessage)}
                </DisclaimerContainer>
            </Main>
        </>
    )
}

export default ManageReservation
